import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-toastify/dist/ReactToastify.css";
import "react-phone-number-input/style.css";
import "react-image-crop/dist/ReactCrop.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
import "./index.css";
import { APP_ROUTES } from "./utils";
import { LoginModal, SignupModal } from "./components/Modals";
import EditAboutMe from "./components/Modals/Profile/EditAboutMe";
import EditOtherInfo from "./components/Modals/Profile/EditOtherInfo";
import EditPersonalDetails from "./components/Modals/Profile/EditPersonalDetails";
import { setupResponseInterceptor } from "./config/axios";
import EditPassword from "./components/Modals/Profile/EditPassword";
import { RootReducer } from "./interfaces/reducer";
import { THEME } from "./utils/constants";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import EditInterests from "./components/Modals/Profile/EditInterests";
import ForgotPassword from "./components/Auth/ForgotPassword/ForgotPassword";
import OtpModal from "./components/Auth/ForgotPassword/OtpModal";
import NewPasswordModal from "./components/Auth/ForgotPassword/NewPasswordModal";

const App = () => {
  const navigate = useNavigate();
  const [isLoaded, setIsLoaded] = useState(false);
  const { theme } = useSelector((state: RootReducer) => state.common);

  if (!isLoaded) {
    setIsLoaded(true);
    setupResponseInterceptor(navigate);
  }
  return (
    <div className={theme === THEME.DARK ? THEME.DARKCSS : ""}>
      <Routes>
        {APP_ROUTES.map((route, index) => {
          return (
            <Route key={index} path={route.path} element={route.element} />
          );
        })}
      </Routes>
      <LoginModal />
      <ForgotPassword />
      <OtpModal />
      <NewPasswordModal />
      <SignupModal />
      <EditPassword />
      <EditAboutMe />
      <EditOtherInfo />
      <EditPersonalDetails />
      <EditInterests />
      <ToastContainer />
    </div>
  );
};

export default App;
