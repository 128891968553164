import { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import Header from "../../components/Header";
import "./style.css";
import Footer from "../../components/Footer";
import InterestCard from "../../components/UI/Card/Interest";
import { MemberAction } from "../../redux/thunk";
import { AppDispatch } from "../../redux/store";
import { RootReducer } from "../../interfaces/reducer";
import Loader from "../../components/Loader/Spinner";
import { ViewedMePaginationPayload } from "../../interfaces/members";
import ReactPagination from "../../components/UI/Pagination/ReactPagination";

interface Props extends DispatchToProps {}

const Interests = (props: any) => {
  const [activePanel, setActivePanel] = useState("Viewed Me");
  const {
    viewedMembers,
    favouriteMeMembers,
    favouriteMembers,
    membersLoading,
  } = useSelector((state: RootReducer) => state.members);

  useEffect(() => {
    props.viewedMe({ page: 1 });
  }, []);

  return (
    <>
      <Header />
      <section className="main-sec search_page top-space">
        <div className="container mt-3">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 mx-auto d-block">
                <div className="listing-sec Interests_fav">
                  <div className="interesttabs d-flex ">
                    <ul className="tabs mb-0">
                      <li
                        onClick={() => {
                          setActivePanel("Viewed Me");
                          props.viewedMe(1);
                        }}
                        className={
                          activePanel === "Viewed Me" ? "active tab" : "tab"
                        }
                      >
                        <label htmlFor="tab1">Viewed Me</label>
                      </li>
                      <li
                        onClick={() => {
                          setActivePanel("Favourites");
                          props.favouritea(1);
                        }}
                        className={
                          activePanel === "Favourites" ? "active tab" : "tab"
                        }
                      >
                        <label htmlFor="tab2">Favourites</label>
                      </li>
                      <li
                        onClick={() => {
                          setActivePanel("Favourited Me");
                          props.favouriteMe(1);
                        }}
                        className={
                          activePanel === "Favourited Me" ? "active tab" : "tab"
                        }
                      >
                        <label htmlFor="tab3">Favourited Me</label>
                      </li>
                    </ul>
                  </div>
                  <hr />
                  <div className="page-content inner_interest">
                    <div className="tabbed">
                      <input
                        readOnly
                        type="radio"
                        id="tab1"
                        name="css-tabs"
                        checked
                      />
                      <input readOnly type="radio" id="tab2" name="css-tabs" />
                      <input readOnly type="radio" id="tab3" name="css-tabs" />

                      <div className="tab-content">
                        {membersLoading ? <Loader /> : null}
                        {!membersLoading && activePanel === "Viewed Me" ? (
                          <>
                            {viewedMembers?.data?.length! > 0 ? (
                              <>
                                {viewedMembers?.data?.map((member, index) => (
                                  <InterestCard
                                    key={index}
                                    data={member}
                                    addToFavourite={props.addToFavourite}
                                  />
                                ))}
                                {viewedMembers?.last_page! > 1 && (
                                  <ReactPagination
                                    totalPage={viewedMembers?.last_page}
                                    newOffset={(page: number) =>
                                      props.viewedMe(page)
                                    }
                                  />
                                )}
                              </>
                            ) : (
                              <p>No users found.</p>
                            )}
                          </>
                        ) : null}
                        {!membersLoading && activePanel === "Favourites" ? (
                          favouriteMembers?.data?.length! > 0 ? (
                            <>
                              {favouriteMembers?.data?.map((member, index) => (
                                <InterestCard
                                  key={index}
                                  data={member}
                                  addToFavourite={props.addToFavourite}
                                />
                              ))}
                              {favouriteMembers?.last_page! > 1 && (
                                <ReactPagination
                                  totalPage={favouriteMembers?.last_page}
                                  newOffset={(page: number) =>
                                    props.favouritea(page)
                                  }
                                />
                              )}
                            </>
                          ) : (
                            <p>No favorite users found.</p>
                          )
                        ) : null}
                        {!membersLoading && activePanel === "Favourited Me" ? (
                          favouriteMeMembers?.data?.length! > 0 ? (
                            <>
                              {favouriteMeMembers?.data?.map(
                                (member, index) => (
                                  <InterestCard
                                    key={index}
                                    data={member}
                                    addToFavourite={props.addToFavourite}
                                  />
                                )
                              )}
                              {favouriteMeMembers?.last_page! > 1 && (
                                <ReactPagination
                                  totalPage={favouriteMeMembers?.last_page}
                                  newOffset={(page: number) =>
                                    props.favouriteMe(page)
                                  }
                                />
                              )}
                            </>
                          ) : (
                            <p>No members have favorited you.</p>
                          )
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  viewedMe: (page: number) => dispatch(MemberAction.getViewedMembers(page)),
  favouritea: (page: number) =>
    dispatch(MemberAction.getFavouriteMembers(page)),
  favouriteMe: (page: number) =>
    dispatch(MemberAction.getFavouriteMeMembers(page)),
  addToFavourite: (userId: number) =>
    dispatch(MemberAction.addToFavourite(userId)),
});

interface DispatchToProps {
  viewedMe: (data: ViewedMePaginationPayload) => void;
  favouritea: () => void;
  favouriteMe: () => void;
  addToFavourite: (userId: number) => void;
}

export default connect(null, mapDispatchToProps)(Interests);
