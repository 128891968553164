// MultiRangeSlider.tsx
import React from 'react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

interface MultiRangeSliderProps {
  min: number;
  max: number;
  handleSlideChange:  (values: any) => void;
  defaultMin:number;
  defaultMax:number;
  step:number
}

const MultiRangeSlider: React.FC<MultiRangeSliderProps> = ({ min, max ,handleSlideChange,defaultMin,defaultMax,step}) => {

  return (
    <div>
      <Slider allowCross={false}  step={step} range min={min} max={max} defaultValue={[defaultMin, defaultMax]}  onChange={handleSlideChange} />
    </div>
  );
};

export default MultiRangeSlider;
