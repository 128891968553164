import { connect, useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ACTIONS } from "../../../redux/actions";
import { RootReducer } from "../../../interfaces/reducer";
import { neuDaddyLogo } from "../../../assets/images";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { SendOtpForgotPasswordPayload } from "../../../interfaces/auth";
import { AppDispatch } from "../../../redux/store";
import { AuthAction } from "../../../redux/thunk";
import { Constants } from "../../../utils/constants";
import styles from "./../../Input/style.module.css";
import { useRef, useState } from "react";
import Loader from "../../Loader/Spinner";
import ReCAPTCHA from "react-google-recaptcha";

interface Props extends MapDispatchToProps {}

const ForgotPassword = (props: Props) => {
  const { forgotPswdModal } = useSelector((state: RootReducer) => state.auth);
  const initVal = {
    phoneNumber: localStorage.getItem(Constants.forgotPasswordNumber) || "",
  };
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [recaptchaError, setRecaptchaError] = useState<boolean>(false);
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const handleClose = (resetForm: () => void) => {
    initVal.phoneNumber = "";
    localStorage.removeItem(Constants.forgotPasswordNumber);
    resetForm(); // Reset the form when the modal is closed
    dispatch({ type: ACTIONS.FORGOT_PSWD_MODAL, status: false });
  };

  const handleSubmit = async (
    values: { phoneNumber: string },
    { resetForm }: any
  ) => {
    const captchaValue = recaptchaRef.current?.getValue();
    if (!captchaValue) {
      setRecaptchaError(true);
      return;
    }
    setRecaptchaError(false);
    const data = {
      country_code: values.phoneNumber.slice(1, 3),
      phone_number: values.phoneNumber.slice(3, 20),
    };
    setLoader(true);
    const resp: any = await props.sendOtp(data);
    setLoader(false);
    if (resp?.data?.success) {
      localStorage.setItem(Constants.forgotPasswordNumber, values.phoneNumber);
      dispatch({ type: ACTIONS.OTP_MODAL, status: true });
      dispatch({ type: ACTIONS.FORGOT_PSWD_MODAL, status: false });
      resetForm(); // Reset the form after successful submission
    }
  };

  const formik = useFormik({
    initialValues: initVal,
    enableReinitialize: true,
    validationSchema: Yup.object({
      phoneNumber: Yup.string()
        .required("Phone number is required")
        .test("isValidPhoneNumber", "Invalid phone number", (value) =>
          value ? isValidPhoneNumber(value) : false
        ),
    }),
    onSubmit: handleSubmit,
  });

  return (
    <div className={`modal login-modal ${forgotPswdModal ? "d-block" : ""}`}>
      {loader && <Loader />}
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content Edit_your_info">
          <div className="modal-header">
            <div className="logo-modal">
              <img className="me-2" src={neuDaddyLogo} alt="" />
              <FontAwesomeIcon
                className="cursor-pointer close"
                size="lg"
                icon={faClose}
                onClick={() => handleClose(formik.resetForm)}
              />
            </div>
          </div>
          <div className="modal-body px-0 pb-0">
            <div className="form-sec">
              <form onSubmit={formik.handleSubmit}>
                <div className="mb-3">
                  <label className="mb-2">Enter Phone Number</label>
                  <PhoneInput
                    international
                    placeholder="Enter phone number"
                    value={formik.values.phoneNumber as any}
                    onChange={(value) =>
                      formik.setFieldValue("phoneNumber", value)
                    }
                    defaultCountry="IN"
                  />
                  {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                    <div className={styles.error}>
                      {formik.errors.phoneNumber}
                    </div>
                  ) : null}
                </div>
                <div className="col-md-12 mt-3 mb-4">
                  <div>
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey={process.env.REACT_APP_SITE_KEY_RECAPTCHA!}
                    />
                    {recaptchaError ? (
                      <div className={styles.error}>
                        {"Please complete the reCAPTCHA verification."}
                      </div>
                    ) : null}
                  </div>
                </div>
                <button type="submit" className="btn-verify">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  sendOtp: (payload: SendOtpForgotPasswordPayload) =>
    dispatch(AuthAction.sendOtpForgotPassword(payload)),
});

interface MapDispatchToProps {
  sendOtp: (payload: SendOtpForgotPasswordPayload) => void;
}

export default connect(null, mapDispatchToProps)(ForgotPassword);
