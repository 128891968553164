import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { neuDaddyLogo } from "../../../assets/images";
import { faClose } from "@fortawesome/free-solid-svg-icons";

interface UploadModalProps {
  onOptionSelect: (option: "public" | "private") => void;
  onClose: () => void;
}

const UploadModal: React.FC<UploadModalProps> = ({
  onOptionSelect,
  onClose,
}) => {
  const handleOptionSelect = (option: "public" | "private") => {
    onOptionSelect(option);
  };

  return (
    <div className={`modal login-modal d-block`}>
      <div className="modal-dialog modal-dialog-centered modal-md">
        <div className="modal-content">
          <div className="modal-header p-0 border-0">
            <div className="logo-modal">
              <img className="me-2" src={neuDaddyLogo} alt="" />
              <FontAwesomeIcon
                className="cursor-pointer close close-pos"
                size="lg"
                icon={faClose}
                onClick={onClose}
              />
            </div>
          </div>
          <div className="modal-body  px-0 pb-0">
            <div className="form-sec photo-text">
              <h2>Select Photo Privacy </h2>
            </div>
          </div>
          <div className="modal-footer border-0 text-center m-auto p-0">
            <button
              onClick={() => handleOptionSelect("public")}
              className="btn btn-punlic p-10"
            >
              Public
            </button>
            <button
              onClick={() => handleOptionSelect("private")}
              className="btn btn-primary"
            >
              Private
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadModal;
