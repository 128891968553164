import { combineReducers } from "redux";

import authReducer from "./auth";
import commonReducer from "./common";
import memberReducer from "./members";
import userReducer from "./user";
import messagesReducer from "./messages";

const rootReducer = combineReducers({
    auth: authReducer,
    common: commonReducer,
    members: memberReducer,
    user: userReducer,
    messages: messagesReducer
});

export default rootReducer;