import ROUTES from "../constants/routes";
import { AppRoutes } from "../interfaces/routes";
import Chatmessage from "../pages/Chatmessage";
import Contactus from "../pages/ContactUs";
import TermsAndCondition from "../pages/TermsAndCondition";
import Home from "../pages/Home";
import Interests from "../pages/Interests";
import Members from "../pages/Members";
import Messages from "../pages/Messages";
import PrivacyPolicy from "../pages/Privacypolicy";
import Profile from "../pages/Profile";
import Protected from "../pages/Protected";
import Setting from "../pages/setting";
import Subscription from "../pages/Subscription";
import SubscriptionList from "../pages/Subscription/SubscriptionList";
import AboutUsLogoutPage from "../pages/AboutUs/LogoutAboutUs";
import LoginAboutUs from "../pages/AboutUs/LoginAboutUs";

const APP_ROUTES: AppRoutes[] = [
  {
    path: ROUTES.HOME,
    element: <Home />,
  },
  {
    path: ROUTES.INTERESTS,
    element: <Protected childComponent={<Interests />} />,
  },
  {
    path: ROUTES.MESSAGES,
    element: <Protected childComponent={<Messages />} />,
  },
  {
    path: ROUTES.SPECIFICMESSAGES,
    element: <Protected childComponent={<Messages />} />,
  },
  {
    path: ROUTES.USER_PROFILE,
    element: <Protected childComponent={<Profile />} />,
  },
  {
    path: ROUTES.OTHER_USER_PROFILE,
    element: <Protected childComponent={<Profile />} />,
  },
  {
    path: ROUTES.MEMBERS,
    element: <Protected childComponent={<Members />} />,
  },
  {
    path: ROUTES.CHAT,
    element: <Protected childComponent={<Chatmessage />} />,
  },
  {
    path: ROUTES.PRIVACYPOLICY,
    element: <Protected childComponent={<PrivacyPolicy />} />,
  },
  {
    path: ROUTES.TERMSANDCONDITIONS,
    element: <Protected childComponent={<TermsAndCondition />} />,
  },
  {
    path: ROUTES.aboutUs,
    element: <Protected childComponent={<LoginAboutUs />} />,
  },
  {
    path: ROUTES.SUBSCRIPTION,
    element: <Protected childComponent={<Subscription />} />,
  },
  {
    path: ROUTES.SUBSCRIPTIONLIST,
    element: <Protected childComponent={<SubscriptionList />} />,
  },
  {
    path: ROUTES.SETTING,
    element: <Protected childComponent={<Setting />} />,
  },
  {
    path: ROUTES.ABOUTUS,
    element: <AboutUsLogoutPage />,
  },
  {
    path: ROUTES.CONTACTUS,
    element: <Contactus />,
  },
  {
    path: ROUTES.TERMCONDITIONS,
    element: <TermsAndCondition />,
  },
  {
    path: ROUTES.PRIVACYP,
    element: <PrivacyPolicy />,
  },
];

export default APP_ROUTES;
