import React from "react";
import { useSelector } from "react-redux";
import { RootReducer } from "../../../interfaces/reducer";
import { apiAxiosInstance } from "../../../config/axios";
import { toast } from "react-toastify";

interface BlockUserProps {
  closeModal: () => void;
  userId?: string;
  refech?: () => void;
  setShowModal?: any;
}

const BlockUser: React.FC<BlockUserProps> = ({
  closeModal,
  userId,
  refech,
  setShowModal,
}) => {
  const otherMemberProfile: any = useSelector(
    (state: RootReducer) => state.members.otherMemberProfile
  );

  const handleBlockUser = async (user_id: Number) => {
    try {
      const res = await apiAxiosInstance.post("/block-user", { user_id });
      if (refech) refech();
      toast.success("Block Successfully ", {
        position: toast.POSITION.TOP_RIGHT,
      });
      closeModal();
      if (setShowModal) setShowModal(false);
    } catch (error) {
      console.error("Error blocking user:", error);
    }
  };

  const handleUnblockUser = async (user_id: Number) => {
    try {
      const res = await apiAxiosInstance.post("unblock-user", { user_id });
      if (refech) refech();
      toast.success("Unblock Successfully ", {
        position: toast.POSITION.TOP_RIGHT,
      });
      closeModal();
      if (setShowModal) setShowModal(false);
    } catch (error) {
      console.error("Error blocking user:", error);
    }
  };

  return (
    <div
      className="modal edit-modal"
      tabIndex={-1}
      style={{ display: "block" }}
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header ">
            <h5 className="modal-title f-18">
              Are you sure you want to
              {otherMemberProfile?.is_blocked ? " UNBLOCK" : "BLOCK"} this
              member?
            </h5>
            <button
              type="button"
              className="btn-close"
              onClick={closeModal}
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <p className="p-bg-none">Action can not be reversed</p>
          </div>
          <div className="modal-footer justify-center">
            <div className="footer-btn">
              <button
                type="button"
                className="btn btn-primary mt-0"
                onClick={() => {
                  if (otherMemberProfile?.is_blocked) {
                    handleUnblockUser(otherMemberProfile?.id || userId);
                  } else {
                    handleBlockUser(otherMemberProfile?.id! || userId);
                  }
                }}
              >
                {otherMemberProfile?.is_blocked ? " UNBLOCK" : "BLOCK"}
              </button>
              <button
                type="button"
                className="btn btn-gry btn-primary mt-0"
                onClick={closeModal}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlockUser;
