import { useRef, useState, useEffect } from "react";
import PhoneInput, { parsePhoneNumber } from "react-phone-number-input";
import moment from "moment";
import { connect, useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import Input from "../../../Input";
import { SignUpPersonalDetailsSchema } from "../../../../schemas";
import { SignUpPersonalDetailsWithoutPasswordSchema } from "../../../../schemas";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InputStyles from "../../../Input/style.module.css";
import { AppDispatch } from "../../../../redux/store";
import { AuthAction } from "../../../../redux/thunk";
import { SendOtpPayload } from "../../../../interfaces/auth";
import { RootReducer } from "../../../../interfaces/reducer";
import ReCAPTCHA from "react-google-recaptcha";
import { ACTIONS } from "../../../../redux/actions";
import styles from "../../../Input/style.module.css";

interface Props extends MapDispatchToProps {}

const PersonalDetails = (props: Props) => {
  const date = new Date();
  const dispatch = useDispatch();
  const { email, mobileNumber, password, dob } = useSelector(
    (state: RootReducer) => state.auth.signupValues
  );
  const { googleSignUp } = useSelector((state: RootReducer) => state.auth);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [recaptchaError, setRecaptchaError] = useState<boolean>(false);
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  interface FormValues {
    email: string;
    password: string;
    retype_pass?: string;
    mobileNumber?: string;
    dob: string;
    countryFlag: string;
    recaptcha: string | null;
  }

  const signupFormik = useFormik<FormValues>({
    initialValues: {
      email: "",
      password: "",
      retype_pass: "",
      mobileNumber: "+91",
      dob: "",
      countryFlag: "IN",
      recaptcha: null,
    },
    validationSchema: googleSignUp
      ? SignUpPersonalDetailsWithoutPasswordSchema
      : SignUpPersonalDetailsSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      const signUpData = { ...values };
      delete signUpData?.retype_pass;
      const captchaValue = recaptchaRef.current?.getValue();
      if (!captchaValue) {
        setRecaptchaError(true);
        return;
      }
      const parsedMobileNumber = signUpData.mobileNumber
        ? parsePhoneNumber(signUpData.mobileNumber)
        : undefined;
      const payload = {
        ...signUpData,
        countryCode: parsedMobileNumber?.countryCallingCode as string,
      };
      props.sendOtp(payload);
    },
  });

  useEffect(() => {
    // Prefill the form with values from Redux store
    signupFormik.setValues({
      email: email || "",
      password: password || "",
      retype_pass: password || "",
      mobileNumber: mobileNumber || "+91",
      dob: dob ? moment(dob).format("YYYY-MM-DD") : "",
      countryFlag: "IN",
      recaptcha: null,
    });
  }, [email, password, mobileNumber, dob]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = () => {
    dispatch({ type: ACTIONS.SET_SIGNUP_MODAL, status: false });
    dispatch({ type: ACTIONS.SET_LOGIN_MODAL, status: true, resetForm: true });
  };

  return (
    <form onSubmit={signupFormik.handleSubmit}>
      <div className="row">
        <div className="Choose_Username about_useredit login-form">
          <div className="row">
            <div className="col-md-12">
              <div className="mb-3">
                <label>Email</label>
                <Input
                  readOnly={googleSignUp}
                  type="text"
                  name="email"
                  max=""
                  placeHolder="Enter your email address"
                  handleChange={(event) => {
                    const {
                      target: { value, name },
                    } = event;
                    signupFormik.setFieldValue(name, value);
                  }}
                  value={signupFormik.values.email}
                  error={
                    signupFormik.touched.email && signupFormik.errors.email
                      ? signupFormik.errors.email
                      : ""
                  }
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="mb-3 ph-input">
                <label>Mobile Number</label>
                <PhoneInput
                  placeholder="Enter phone number"
                  international
                  onChange={(value) =>
                    signupFormik.setFieldValue("mobileNumber", value)
                  }
                  defaultCountry="IN"
                  onCountryChange={(country) =>
                    signupFormik.setFieldValue("countryFlag", country)
                  }
                  className="phone-number"
                  value={signupFormik.values.mobileNumber as any}
                />
                {signupFormik.touched.mobileNumber &&
                signupFormik.errors.mobileNumber ? (
                  <div className={InputStyles.error}>
                    {signupFormik.errors.mobileNumber}
                  </div>
                ) : null}
              </div>
            </div>
            {!googleSignUp && (
              <>
                <div className="col-md-12">
                  <div className="mb-3">
                    <div className="d-flex justify-content-between">
                      <label>Password</label>
                    </div>
                    <div className="position-relative">
                      <Input
                        type={showPassword ? "text" : "password"}
                        name="password"
                        placeHolder="Enter your password"
                        handleChange={signupFormik.handleChange}
                        value={signupFormik.values.password}
                        error={
                          signupFormik.touched.password &&
                          signupFormik.errors.password
                            ? signupFormik.errors.password
                            : ""
                        }
                      />
                      <span
                        className="position-absolute top-50 translate-middle-y password-toggle-btn cursor-pointer"
                        style={{ right: "25px" }}
                        onClick={togglePasswordVisibility}
                      >
                        <FontAwesomeIcon
                          icon={!showPassword ? faEyeSlash : faEye}
                        />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="mb-3">
                    <div className="d-flex justify-content-between">
                      <label>Confirm Password</label>
                    </div>
                    <div className="position-relative">
                      <Input
                        type={showConfirmPassword ? "text" : "password"}
                        name="retype_pass"
                        placeHolder="Re-enter your password"
                        handleChange={signupFormik.handleChange}
                        value={signupFormik?.values?.retype_pass!}
                        error={
                          signupFormik.touched.retype_pass &&
                          signupFormik.errors.retype_pass
                            ? signupFormik.errors.retype_pass
                            : ""
                        }
                      />
                      <span
                        className="position-absolute top-50 translate-middle-y password-toggle-btn cursor-pointer"
                        style={{ right: "25px" }}
                        onClick={() =>
                          setShowConfirmPassword((prevState) => !prevState)
                        }
                      >
                        <FontAwesomeIcon
                          icon={!showConfirmPassword ? faEyeSlash : faEye}
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </>
            )}
            <div className="col-md-12">
              <div>
                <label>Date of birth</label>
                <Input
                  className="dobInput"
                  type="date"
                  name="dob"
                  placeHolder="Enter your birth date"
                  handleChange={signupFormik.handleChange}
                  value={signupFormik.values.dob}
                  error={
                    signupFormik.touched.dob && signupFormik.errors.dob
                      ? signupFormik.errors.dob
                      : ""
                  }
                  max={moment(date).subtract(18, "y").toJSON().split("T")[0]}
                />
              </div>
            </div>
            <div className="col-md-12 mt-3">
              <div>
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={process.env.REACT_APP_SITE_KEY_RECAPTCHA!}
                />
                {recaptchaError ? (
                  <div className={styles.error}>
                    {"Please complete the reCAPTCHA verification."}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="col-md-12">
              <div className="mb-3">
                <button type="submit">Continue</button>
              </div>
            </div>
            <div className="d-flex justify-content-center">
              <span onClick={handleLogin} className="cursor-pointer">
                Have An Account?
                <a href="#" className="sign-u">
                  Login
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  sendOtp: (payload: SendOtpPayload) => dispatch(AuthAction.sendOtp(payload)),
});

interface MapDispatchToProps {
  sendOtp: (payload: SendOtpPayload) => void;
}

export default connect(null, mapDispatchToProps)(PersonalDetails);
