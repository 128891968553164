import ROUTES from "../../../constants/routes";
import { useNavigate } from "react-router";
import { Fragment } from "react";
import { SendInterest } from "../../../assets/images";
import { SpecificInterest } from "../../../interfaces/interests";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { Constants } from "../../../utils/constants";
import Women from "./../../../assets/images/femaleProfile@3x.png";
import Men from "./../../../assets/images/maleProfile@3x.png";
import Common from "./../../../assets/images/dummyProfile.png";

interface InterestCardProps {
  data: SpecificInterest;
  addToFavourite: (id: number) => void;
  // otherMemberProfile:any,
}

const InterestCard = (props: InterestCardProps) => {
  const navigate = useNavigate();
  const handleAddToFavorites = (userId: number) => {
    props.addToFavourite(userId);
    const message =
      props.data.is_favourite === 1
        ? "Unfavourited Successfully."
        : "Favourited Successfully.";
    if (props.data.is_favourite === 1) {
      toast.error(message, { position: toast.POSITION.TOP_RIGHT });
    } else {
      toast.success(message, { position: toast.POSITION.TOP_RIGHT });
    }
  };

  // const handleViewProfile = (userId: number) => {
  //   // Navigate to the user's profile page
  //   alert("jfje")
  //   navigate(`${ROUTES.USER_PROFILE}/${userId}`);
  // handleViewProfile(props.data.id)}
  // };

  return (
    <Fragment>
      <div className="inner_content" key={props.data.id}>
        <span>
          <img
            className="profile_img"
            src={
              props.data?.is_profile_image_approved
                ? props.data.profile_image
                : props.data?.gender === 1
                ? Women
                : props.data?.gender === 2
                ? Men
                : Common
            }
            alt=""
            onClick={() => {
              navigate(`${ROUTES.USER_PROFILE}/${props.data.id}`);
            }}
          />

          <div className="deatslist_name">
            <h4
              className="cursor-pointer"
              onClick={() => {
                navigate(`${ROUTES.USER_PROFILE}/${props.data.id}`);
              }}
            >
              {props.data?.is_name_approved
                ? props.data?.user_name
                : Constants.newUser}
            </h4>
            {!!props?.data?.is_bio_approved && (
              <h5
                onClick={() => {
                  navigate(`${ROUTES.USER_PROFILE}/${props.data.id}`);
                }}
              >
                {props.data.headline}
              </h5>
            )}

            <p>{props.data.location}</p>

            <div className="inner_adventur">
              <span
                onClick={() => {
                  navigate(`/chat/${props.data.id}`);
                }}
                className="cursor-pointer"
              >
                <img src={SendInterest} alt="" />
                View Conversation
              </span>
              <span className="cursor-pointer">
                <button
                  className="btn p-2"
                  style={{
                    background: "none",
                    border: "none",
                    color: "#fff",
                    cursor: "pointer",
                  }}
                  onClick={() => handleAddToFavorites(Number(props.data.id))}
                >
                  {props.data?.is_favourite === 1 ? (
                    <FontAwesomeIcon
                      icon={faHeart}
                      style={{
                        marginLeft: "10px",
                        color: "red",
                        width: "17px",
                        height: "17px",
                      }}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faHeart}
                      style={{
                        marginLeft: "10px",
                        color: "white",
                        width: "30px",
                        height: "30px",
                      }}
                    />
                  )}
                </button>
                Favourite
              </span>
            </div>
          </div>
        </span>
        {/* <span id="minutes_three">Viewed me 3 minutes ago</span> */}
      </div>
      <hr />
    </Fragment>
  );
};

export default InterestCard;
