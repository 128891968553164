import React, { useEffect, useState } from "react";
// import { Logo } from '../../../assets/images';
import { Logo, neuDaddyLogo } from "../../../assets/images";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { RootReducer } from "../../../interfaces/reducer";
import { ACTIONS } from "../../../redux/actions";

const UserInfo = () => {
  const [otherUserProfile, setOtherUserProfile] = useState<any>();
  const dispatch = useDispatch();
  const otherMemberProfile = useSelector(
    (state: RootReducer) => state.members.otherMemberProfile
  );
  const { userInfoModal } = useSelector((state: RootReducer) => state.auth);
  useEffect(() => {
    setOtherUserProfile(otherMemberProfile);
  }, [otherMemberProfile]);

  const handleUserInfoModal = () => {
    dispatch({ type: ACTIONS.SET_USERINFO_MODAL, status: !userInfoModal });
  };

  return (
    <div className={`modal login-modal ${userInfoModal ? "d-block" : ""}`}>
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content Edit_your_info chat-modal">
          <div className="modal-header">
            <div className="logo-modal">
              {/* <a href="#"><FontAwesomeIcon icon={faChevronLeft} /></a> */}
              {/* <img src={Logo} alt="" /> */}
              <img className="me-2" src={neuDaddyLogo} alt="" />
              <FontAwesomeIcon
                className="cursor-pointer close"
                size="lg"
                icon={faClose}
                onClick={handleUserInfoModal}
              />
            </div>
          </div>

          <div className="modal-body px-0 pb-0">
            <div className="right_sidebarchat" id="style-2">
              <div className="top_innerimg">
                <img src={otherUserProfile?.profile_image} alt="" />
                <span>
                  <h6>Private (4)</h6>
                  <img src={"s"} alt="" />
                  <h6>Request to view</h6>
                </span>
              </div>

              <div className="info_side">
                <div className="pull-left">Background</div>
                <div className="pull-right">NOT VERIFIED</div>
              </div>
              <div className="Verifi_cations">
                <div className="pull-left">Verifications</div>
              </div>
              <br />
              <h2>{otherUserProfile?.first_name}</h2>

              <div className="info_details">
                <div className="info_side">
                  <div className="pull-left">Looking for</div>
                  <div className="pull-right">
                    {otherUserProfile?.filters?.looking_for?.map(
                      (lf: any) => `${lf?.name} `
                    )}
                  </div>
                </div>
                <div className="info_side">
                  <div className="pull-left">Body Type</div>
                  <div className="pull-right">
                    {otherUserProfile?.filters?.body_type?.name}
                  </div>
                </div>
                <div className="info_side">
                  <div className="pull-left">Height</div>
                  <div className="pull-right">
                    {otherUserProfile?.filters?.maximum_height} cm
                  </div>
                </div>
                <div className="info_side">
                  <div className="pull-left">Ethnicity</div>
                  <div className="pull-right">
                    {otherUserProfile?.filters?.ethnicity?.name}
                  </div>
                </div>
                <div className="info_side">
                  <div className="pull-left">Education</div>
                  <div className="pull-right">
                    {otherUserProfile?.filters?.education?.name}
                  </div>
                </div>
                {/* <div className="info_side">
                  <div className="pull-left">Occupation</div>
                  <div className="pull-right">
                    {otherUserProfile?.occupation?.name}
                  </div>
                </div> */}
                <div className="info_side">
                  <div className="pull-left">Relationship</div>
                  <div className="pull-right">
                    {otherUserProfile?.filters?.relationship_status?.name}
                  </div>
                </div>
                <div className="info_side">
                  <div className="pull-left">Smokes</div>
                  <div className="pull-right">
                    {otherUserProfile?.filters?.is_smoker?.name}
                  </div>
                </div>
                <div className="info_side">
                  <div className="pull-left">Drinks</div>
                  <div className="pull-right">
                    {otherUserProfile?.filters?.is_drinker?.name}
                  </div>
                </div>
                {/* <div className="info_side last_side">
                  <div className="pull-left">Children</div>
                  <div className="pull-right">
                    {otherUserProfile?.filters?.is_children?.name}
                  </div>
                </div> */}

                <h2>About Me</h2>
                <h5>{otherUserProfile?.bio}</h5>
                <h2>Tags</h2>
                <ul>
                  {otherUserProfile?.filters?.dating_tags?.map((tag: any) => (
                    <li>
                      <a href="#">{tag?.name}</a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserInfo;
