import * as Yup from "yup";

const ProfessionalDetailsSchema = Yup.object().shape({
  educationId: Yup.number().required("Required").min(1, "* Required"),
  relationshipId: Yup.number().required("Required").min(1, "* Required"),
  // childrenId: Yup.number().required('Required').min(1, '* Required'),
  smokerId: Yup.number().required("Required").min(1, "* Required"),
  drinkerId: Yup.number().required("Required").min(1, "* Required"),
  // occupationId: Yup.number().required('Required').min(1, '* Required'),
  // netWorth:Yup.number().required('Required').min(1, '* Required'),
  // annualIncome:Yup.number().required('Required').min(1, '* Required')
});

export default ProfessionalDetailsSchema;
