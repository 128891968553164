const ROUTES = {
  INTERESTS: "/interests",
  HOME: "/",
  MESSAGES: "/messages",
  SPECIFICMESSAGES: "/messages/:type",
  USER_PROFILE: "/profile",
  OTHER_USER_PROFILE: "/profile/:id",
  MEMBERS: "/members",
  CHAT: "/chat/:id",
  PRIVACYPOLICY: "/privacypolicy",
  PRIVACYP: "/privacy-policy",
  TERMSANDCONDITIONS: "/termsandconditions",
  aboutUs: "/aboutUs",
  TERMCONDITIONS: "/term-condition",
  SUBSCRIPTION: "/subscriptions",
  SUBSCRIPTIONLIST: "/subscriptions-list",
  SETTING: "/setting",
  ABOUTUS: "/about-us",
  CONTACTUS: "/contact-us",
};

export default ROUTES;
