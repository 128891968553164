import React from "react";
import { Modal, Button } from "react-bootstrap";

interface ConfirmationModalProps {
  show: boolean;
  onHide: () => void;
  onConfirm: () => void;
}

const VerifyEmail: React.FC<ConfirmationModalProps> = ({
  show,
  onHide,
  onConfirm,
}) => {
  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Email Not Verified</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Your email is not verified. Would you like to resend the verification
        email?
      </Modal.Body>
      <Modal.Footer>
        <Button  className="btn_c my-2 px-3" variant="secondary" onClick={onHide}>
          No
        </Button>
        <Button className="btn_c my-2" variant="primary" onClick={onConfirm}>
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default VerifyEmail;
