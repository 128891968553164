import { RefObject, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UploadPro } from "../../../../assets/images";
import { GalleryImages, RootReducer } from "../../../../interfaces/reducer";
import "../style.css";
import { apiAxiosInstance } from "../../../../config/axios";
import UploadModal from "../../Upload";
import ImageGallery from "../../../PrivateImage";
import { ACTIONS } from "../../../../redux/actions";
import {
  GENERAL_DETAILS_STEP,
  SIGNUP_STEPS,
} from "../../../../utils/constants";

const GalleryPhotos = () => {
  const dispatch = useDispatch();
  const { photos } = useSelector(
    (state: RootReducer) => state.auth.signupValues
  );
  const [showModal, setShowModal] = useState(false);
  const [privacySetting, setPrivacySetting] = useState<"private" | "public">(
    "public"
  );
  const inputFileRef = useRef<HTMLInputElement>();

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      let formData = new FormData();
      let imageURLS = [...(photos || [])];
      for (let i = 0; i <= files.length; i++) {
        const file = files[i];
        formData.append("image", file);
        if (!file) break;
        const {
          data: { data },
        } = await apiAxiosInstance.post(`/upload/image`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        const isPrivate = privacySetting === "private" ? 1 : 0;
        imageURLS.push({
          ...data[0],
          is_private: isPrivate,
        });
      }
      const payload = {
        photos: imageURLS,
      };
      try {
        dispatch({ type: ACTIONS.SET_SIGNUP_VALUES, values: payload });
        setShowModal(false);
        if (inputFileRef.current) {
          inputFileRef.current.value = "";
        }
      } catch (error) {
        console.error("Profile update failed:", error);
      }
    }
  };

  const handleOptionSelect = (option: "private" | "public") => {
    setShowModal(false);
    setPrivacySetting(option);
    if (inputFileRef.current) {
      inputFileRef.current.click();
    }
  };

  const removeImage = (photo: GalleryImages) => {
    const index = (photos || [])?.findIndex(
      (img: GalleryImages) => img?.orig_path === photo?.orig_path
    );
    photos?.splice(index, 1);
    dispatch({ type: ACTIONS.SET_SIGNUP_VALUES, values: photos });
  };

  const handleNext = () => {
    dispatch({
      type: ACTIONS.SET_SIGNUP_STEP,
      step: SIGNUP_STEPS.PREVIEW_CROPPED_PROFILE_PHOTO,
      generalStep: GENERAL_DETAILS_STEP.ADD_LOCATION,
    });
  };

  return (
    <div className="container">
      {(photos?.length === 0 || !photos) && <span>Gallery Images</span>}
      <div
        className="upload_profile mt-2"
        style={{ overflowX: "auto", whiteSpace: "nowrap" }}
      >
        <ImageGallery
          photos={photos!}
          isOwner={true}
          removeImage={removeImage}
        />
        <span
          className="add-photos cursor-pointer mt-4"
          onClick={() => {
            setShowModal(true);
          }}
          style={{
            display: "inline-block",
            verticalAlign: "top",
          }}
        >
          <img src={UploadPro} alt="" className="img-fluid" />
          <h6>Add more photos</h6>
          <input
            type="file"
            ref={inputFileRef as RefObject<HTMLInputElement>}
            className="d-none"
            accept="image/*"
            onChangeCapture={handleFileChange}
            multiple={true}
          />
        </span>
      </div>
      {showModal && (
        <UploadModal
          onOptionSelect={handleOptionSelect}
          onClose={() => setShowModal(false)}
        />
      )}
      <div className="left-rotare-btn show-btns mt-4">
        <button
          onClick={() => {
            handleNext();
          }}
        >
          {photos?.length === 0 || !photos ? "Skip" : "Next"}
        </button>
      </div>
    </div>
  );
};

export default GalleryPhotos;
