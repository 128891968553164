import { Modal } from "react-bootstrap";
import { useState } from "react";
import Loader from "../../components/Loader/Spinner";
import { useFormik } from "formik";
import * as Yup from "yup";
import Input from "../../components/Input";

const EditEmail = ({ props, onClose }: any) => {
  const [loader, setLoader] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Invalid Email").required("Required"),
    }),
    onSubmit: async (values) => {
      setLoader(true);
      await props.updateProfile(values);
      setLoader(false);
      onClose();
    },
  });

  return (
    <div className="m-2 modal_t">
      {loader && <Loader />}
      <Modal.Header closeButton>
        <Modal.Title>Edit Phone Number</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={formik.handleSubmit}>
          <div className="row">
            <div className="Choose_Username about_useredit login-form">
              <div className="row">
                <div className="col-md-12">
                  <div className="mb-3">
                    <div className="d-flex justify-content-between">
                      <label className="mb-2">Email</label>
                    </div>
                    <div className="position-relative">
                      <Input
                        type="text"
                        name="email"
                        placeHolder="Enter email"
                        handleChange={formik.handleChange}
                        value={formik.values.email}
                        error={
                          formik.touched.email && formik.errors.email
                            ? formik.errors.email
                            : ""
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="mb-3">
                    <button type="submit">Submit</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </Modal.Body>
    </div>
  );
};

export default EditEmail;
