/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import Header from "../../components/Header";
import "./style.css";
import MessageCard from "../../components/UI/Card/Messages";
import Footer from "../../components/Footer";
import { AppDispatch } from "../../redux/store";
import { UserAction } from "../../redux/thunk";
import { MessagesAction } from "../../redux/thunk";
import { RootReducer } from "../../interfaces/reducer";
import { MESSAGES } from "../../utils/constants";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../components/Loader/Spinner";
import ReactPagination from "../../components/UI/Pagination/ReactPagination";
import { MessageRequestPayload } from "../../interfaces/reducer";
import ROUTES from "../../constants/routes";
import ChatFilters from "../../components/Chat/ChatFilters";

interface Props extends DispatchToProps {}

const Messages = (props: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { profile } = useSelector((state: RootReducer) => state.user);
  const { allMessages, archiveMessages, filterMessages } = useSelector(
    (state: RootReducer) => state.messages
  );
  const [activepanel, setActivepanel] = useState(
    !location.pathname.split("/")[2]
      ? MESSAGES.INBOX
      : location.pathname.split("/")[2]
  );
  const [messages, setMessages] = useState<any>(null);
  const [Loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");

  useEffect(() => {
    props.getProfileDetails();
  }, []);

  useEffect(() => {
    setMessages(allMessages?.chatConversations);
  }, [allMessages]);

  useEffect(() => {
    setMessages(archiveMessages);
  }, [archiveMessages]);

  useEffect(() => {
    setMessages(filterMessages?.chatConversations);
  }, [filterMessages]);

  useEffect(() => {
    getMessages(activepanel);

    return () => setSearch("");
  }, [activepanel]);

  useEffect(() => {
    const timer = setTimeout(() => {
      getMessages(activepanel, 1, search);
    }, 500);
    return () => clearTimeout(timer);
  }, [search]);

  const getMessages = async (
    activepanel: string,
    offset?: number,
    search?: string
  ) => {
    setLoading(true);
    if (activepanel === MESSAGES.INBOX) {
      await props.getAllMessages({ page: offset || 1, search: search || "" });
    } else if (activepanel === MESSAGES.ARCHIVE) {
      await props.getArchivedMessages({
        page: offset || 1,
        search: search || "",
      });
    } else if (activepanel === MESSAGES.FILTER) {
      await props.getFilterMessages({
        page: offset || 1,
        search: search || "",
      });
    }
    setLoading(false);
  };

  return (
    <>
      <Header />
      <section className="main-sec search_page">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 mx-auto d-block">
              <div className="listing-sec Interests_fav message_tabs">
                <div className="interesttabs tab-mobs d-flex ">
                  <ul className="tabs mb-0">
                    <li
                      onClick={() => {
                        setActivepanel(MESSAGES.INBOX);
                        navigate(`${ROUTES.MESSAGES}/${MESSAGES.INBOX}`);
                      }}
                      className={`cursor-pointer tab ${
                        activepanel === MESSAGES.INBOX ? "active" : ""
                      }`}
                    >
                      <label htmlFor="tab1" className="cursor-pointer">
                        Inbox
                      </label>
                    </li>
                    <li
                      onClick={() => {
                        setActivepanel(MESSAGES.ARCHIVE);
                        navigate(`${ROUTES.MESSAGES}/${MESSAGES.ARCHIVE}`);
                      }}
                      className={`tab ${
                        activepanel === MESSAGES.ARCHIVE ? "active" : ""
                      }`}
                    >
                      <label htmlFor="tab3">Archive</label>
                    </li>
                    <li
                      onClick={() => {
                        setActivepanel(MESSAGES.FILTER);
                        navigate(`${ROUTES.MESSAGES}/${MESSAGES.FILTER}`);
                      }}
                      className={`tab ${
                        activepanel === MESSAGES.FILTER ? "active" : ""
                      }`}
                    >
                      <label htmlFor="tab3">Filter Out</label>
                    </li>
                  </ul>
                </div>
                <hr />
                <div className="page-content inner_interest">
                  <div className="tabbed row">
                    <input type="radio" id="tab1" name="css-tabs" checked />
                    <input type="radio" id="tab2" name="css-tabs" />
                    <input type="radio" id="tab3" name="css-tabs" />
                    <div
                      className={`${
                        activepanel === MESSAGES.FILTER
                          ? "col-md-8"
                          : "col-md-12"
                      }`}
                    >
                      <div className="tab-content">
                        {Loading && <Loader />}
                        {!Loading && (
                          <>
                            <div>
                              <input
                                onChange={(e) => {
                                  setSearch(e.target.value);
                                }}
                                type="search"
                                value={search}
                                className="mt-2 mb-3 form-control"
                                placeholder="Search by Name..."
                              />
                            </div>
                            {messages?.data?.map((message: any) => (
                              <MessageCard
                                message={message}
                                profile={profile}
                              />
                            ))}
                          </>
                        )}
                        {messages?.last_page! > 1 && (
                          <ReactPagination
                            totalPage={messages?.last_page}
                            newOffset={async (offset: number) => {
                              getMessages(activepanel, offset, search);
                            }}
                          />
                        )}
                        {!Loading && messages?.data?.length === 0 && (
                          <p className="member-loading">No Conversation</p>
                        )}
                      </div>
                    </div>
                    {activepanel === MESSAGES.FILTER && (
                      <div className="col-md-4">
                        <ChatFilters />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getProfileDetails: () => dispatch(UserAction.getProfileDetails()),
  getSentMessages: () => dispatch(MessagesAction.getSentMessage()),
  getAllMessages: (data: MessageRequestPayload) =>
    dispatch(MessagesAction.getAllMessages(data)),
  getArchivedMessages: (data: MessageRequestPayload) =>
    dispatch(MessagesAction.getArchivedMessage(data)),
  getFilterMessages: (data: MessageRequestPayload) =>
    dispatch(MessagesAction.getFilterMessages(data)),
});

interface DispatchToProps {
  getProfileDetails: () => void;
  getSentMessages: () => void;
  getArchivedMessages: (data: MessageRequestPayload) => void;
  getAllMessages: (data: MessageRequestPayload) => void;
  getFilterMessages: (data: MessageRequestPayload) => void;
}

export default connect(null, mapDispatchToProps)(Messages);
