import { useState } from "react";
import { connect } from "react-redux";
import "../style.css";
import InjectedCheckoutForm from "../../StripePayment";

const SubscriptionPlans = ({
  activePack,
  setShowBuyBtn,
  subscriptionBuy,
  setSubscriptionBuy,
}) => {
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);

  const extractTextFromHtml = (htmlString) => {
    const doc = new DOMParser().parseFromString(htmlString, "text/html");
    return doc.body.textContent || "";
  };

  const handleCheckSubscription = (data, idx) => {
    setSelectedIndex(idx);
    setSelectedItemId(data.id);
    setSelectedItem(data);
  };

  return (
    <section className="main-sec search_page pt-0">
      <div className="container">
        <div className="container-fluid">
          <div className="row interesttabs">
            {activePack.subscription &&
              activePack.subscription.map((item, index) => (
                <div className="col-lg-4 col-sm-6 d-flex">
                  <div
                    key={index}
                    className={`outer_subscribe ${
                      selectedIndex === index && selectedItemId === item.id
                        ? "selected"
                        : "subscDetailsHolder"
                    }`}
                  >
                    <p className="pHolder time-holder">
                      {item.duration === 12
                        ? "1 year"
                        : `${item.duration} month`}
                    </p>
                    <p className="pHolder price-place">
                      ₹ <span>{item.price}</span>
                    </p>
                    <p className="pHolder-main">
                      {extractTextFromHtml(item.description)}
                    </p>
                    <button
                      type="button"
                      className="subsViewDetailBtn btn"
                      data-bs-toggle="modal"
                      data-bs-target="#detailsModal"
                      onClick={() => {
                        setShowBuyBtn(true);
                        handleCheckSubscription(item, index);
                      }}
                    >
                      View Details
                    </button>
                  </div>
                </div>
              ))}
          </div>
        </div>

        {/* View Details Modal */}
        <div
          className="modal fade"
          id="detailsModal"
          tabIndex="-1"
          aria-labelledby="detailsModalLabel"
          aria-hidden="true"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="detailsModalLabel">
                  Subscription Details
                </h5>
                <button
                  onClick={() => {
                    setSelectedItemId(null);
                    setSelectedItem(null);
                  }}
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                {selectedItem && (
                  <>
                    <div className="plans_out">
                      <p className="plan_title time-holder">
                        {selectedItem.title}
                      </p>
                      <p className="plan_rupee price-place">
                        Price: ₹ <span>{selectedItem.price}</span> /-
                      </p>
                      <ul className="nav d-block">
                        {selectedItem.assignedfeatures.map((feature) => (
                          <div key={feature.id}>
                            {feature.features.map((subFeature) => (
                              <li key={subFeature.id}>{subFeature.feature}</li>
                            ))}
                          </div>
                        ))}
                      </ul>
                      <button
                        type="button"
                        className="subsViewDetailBtn btn"
                        data-bs-dismiss="modal"
                        data-bs-toggle="modal"
                        data-bs-target="#stripeModal"
                        onClick={() => setSubscriptionBuy(true)}
                      >
                        Buy Subscription
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* Stripe Payment Modal */}
        <div
          className="modal fade"
          id="stripeModal"
          tabIndex="-1"
          aria-labelledby="stripeModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="stripeModalLabel">
                  Payment
                </h1>
                <button
                  onClick={() => {
                    window.location.reload();
                  }}
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body p-4">
                {subscriptionBuy && (
                  <InjectedCheckoutForm
                    selectedItem={selectedItem}
                    activePack={activePack}
                    closeModal={() => setSubscriptionBuy(false)}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const mapDispatchToProps = () => ({});

export default connect(null, mapDispatchToProps)(SubscriptionPlans);
