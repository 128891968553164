/* eslint-disable react-hooks/exhaustive-deps */
import { connect, useSelector } from "react-redux";
import { useEffect } from "react";
import { CommonAction } from "../../redux/thunk";
import { AppDispatch } from "../../redux/store";
import { RootReducer } from "../../interfaces/reducer";

interface Props extends DispatchToProps {}

const AboutUs = (props: Props) => {
  const aboutUs: String = useSelector(
    (state: RootReducer) => state.common.aboutUs
  );

  useEffect(() => {
    props.getAboutUs();
  }, []);

  return (
    <div className="top-space">
      <section className="about-sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 mx-auto">
              <div className="bg-blur-b">
                <h1>ABOUT NeuDaddy</h1>
                {!!aboutUs && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: aboutUs as string,
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getAboutUs: () => dispatch(CommonAction.getAboutUs()),
});

interface DispatchToProps {
  getAboutUs: () => void;
}

export default connect(null, mapDispatchToProps)(AboutUs);
