import { faLock, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { GalleryImages } from "../../interfaces/reducer";

interface ImageGalleryProps {
  photos: any[];
  onRequest?: (photoId: number) => void;
  isOwner: boolean;
  removeImage?: (img: GalleryImages) => void;
  imageAccess?: boolean;
}

const ImageGallery: React.FC<ImageGalleryProps> = (props) => {
  const photoList = Array.isArray(props?.photos) ? props?.photos : [];

  return (
    <>
      {photoList?.length > 0 && (
        <p className="gallery-heading">Gallery Image</p>
      )}
      <div className="flex-wrap" style={{ display: "flex" }}>
        {photoList?.map((photo) => (
          <div
            className="right-side-size"
            key={photo.id}
            style={{ position: "relative", margin: "0px" }}
          >
            <img
              src={photo.orig_path_url}
              alt=""
              className="img-fluid lock-ic"
              style={{
                filter:
                  !!photo?.is_private && !props.isOwner && !props?.imageAccess
                    ? "blur(10px)"
                    : "none",
                borderRadius: "5px",
              }}
            />
            {!!photo?.is_private && props?.isOwner && (
              <FontAwesomeIcon
                icon={faLock}
                style={{
                  position: "absolute",
                  bottom: "10px",
                  right: "10px",
                  height: "15px",
                  width: "15px",
                  color: "#ff0000",
                  cursor: "pointer",
                  backgroundColor: "rgba(255, 255, 255, 0.7)",
                  borderRadius: "50%",
                  padding: "5px",
                }}
              />
            )}
            {!!photo?.is_private && !props.isOwner && !props?.imageAccess && (
              <div
                className="img-lockup"
                style={{
                  position: "absolute",
                  top: "0",
                  left: "0",
                  width: "100%",
                  height: "100%",
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "5px",
                }}
              >
                <FontAwesomeIcon
                  icon={faLock}
                  style={{ color: "#ffffff", marginRight: "5px" }}
                />
                <button
                  onClick={() => props?.onRequest!(photo.id)}
                  style={{
                    backgroundColor: "#007bff",
                    color: "#ffffff",
                    padding: "5px 10px",
                    border: "none",
                    cursor: "pointer",
                    borderRadius: "3px",
                  }}
                >
                  Request Access
                </button>
              </div>
            )}
            {props?.isOwner && props?.removeImage && (
              <FontAwesomeIcon
                icon={faTimes}
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  height: "20px",
                  width: "20px",
                  color: "#ff0000",
                  cursor: "pointer",
                  backgroundColor: "rgba(255, 255, 255, 0.7)",
                  borderRadius: "50%",
                  padding: "5px",
                }}
                onClick={() => props?.removeImage!(photo)}
              />
            )}
          </div>
        ))}
      </div>
    </>
  );
};

export default ImageGallery;
