/* eslint-disable react-hooks/exhaustive-deps */
import { useFormik } from "formik";
import { useState, useEffect } from "react";
import styles from "../../../../Input/style.module.css";
import "../../style.css";
import Input from "../../../../Input";
import { ACTIONS } from "../../../../../redux/actions";
import { connect, useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../../redux/store";
import { AuthAction } from "../../../../../redux/thunk";
import { RootReducer, SignupValues } from "../../../../../interfaces/reducer";
import headingBioSchema from "../../../../../schemas/aboutMeDetails";
import { useNavigate } from "react-router-dom";
import {
  ACCESS_TOKEN,
  GENERAL_DETAILS_STEP,
  SIGNUP_STEPS,
} from "../../../../../utils/constants";
import ROUTES from "../../../../../constants/routes";
import Loader from "../../../../Loader/Spinner";

interface Props extends DispatchToProps {}

const AboutMeDetails = (props: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [aboutMe, setAboutMe] = useState({ heading: "", bio: "" });
  const { signupValues } = useSelector((state: RootReducer) => state.auth);
  const { googleSignUp, googleSignupPayload } = useSelector(
    (state: RootReducer) => state.auth
  );

  const aboutMeFormik = useFormik({
    initialValues: { heading: "", bio: "" },
    onSubmit: (values) => {
      dispatch({ type: ACTIONS.SET_SIGNUP_VALUES, values });
      dispatch({
        type: ACTIONS.SET_SIGNUP_STEP,
        step: SIGNUP_STEPS.PREVIEW_CROPPED_PROFILE_PHOTO,
        generalStep: GENERAL_DETAILS_STEP.VERIFY_TERMS,
      });
    },
    validationSchema: headingBioSchema,
  });

  return (
    <div className="modal-body px-0 pb-0">
      <h6 className="looks-great">
        Add the finishing touches to your profile!
      </h6>
      <form className="lv-from">
        <div className="form-group">
          <label>Heading</label>
          <Input
            type="text"
            name="heading"
            value={aboutMe.heading}
            handleChange={(e) => {
              setAboutMe({ ...aboutMe, heading: e.target.value });
              aboutMeFormik.setFieldValue("heading", e.target.value);
            }}
            error={
              aboutMeFormik.touched.heading && aboutMeFormik.errors.heading
                ? aboutMeFormik.errors.heading
                : ""
            }
            placeHolder="Enter heading"
            className="w-100"
          />
        </div>
        <div className="form-group mt-3">
          <label>About Me</label>
          <textarea
            onChange={(e) => {
              setAboutMe({ ...aboutMe, bio: e.target.value });
              aboutMeFormik.setFieldValue("bio", e.target.value);
            }}
            value={aboutMe.bio}
            placeholder="Explain the sort of relationships / arrangements you’re interested in."
          ></textarea>
          {aboutMeFormik.touched.bio &&
          aboutMeFormik.errors.bio &&
          aboutMeFormik.errors.bio &&
          aboutMeFormik.errors.bio.length ? (
            <div className={styles.error}>{aboutMeFormik.errors.bio}</div>
          ) : null}
        </div>
      </form>
      {/* <div className="left-rotare-btn show-btns"> */}
        <button className="left-rotare-btn show-btns btn-public mx-auto"
          onClick={() => {
            aboutMeFormik.submitForm();
          }}
        >
          Continue
        </button>
      {/* </div> */}
      <button
        className="btn_back"
        onClick={() =>
          dispatch({
            type: ACTIONS.SET_SIGNUP_STEP,
            step: SIGNUP_STEPS.PREVIEW_CROPPED_PROFILE_PHOTO,
            generalStep: GENERAL_DETAILS_STEP.ADD_SEEKING_DETAILS,
          })
        }
      >
        <i className="fas fa-arrow-left"></i> back
      </button>
    </div>
  );
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  signup: (payload: SignupValues) => dispatch(AuthAction.signup(payload)),
  signupGoogle: (
    payload: SignupValues,
    googlePayload: any,
    navigate: (path: string) => void
  ) => dispatch(AuthAction.signupWithGoogle(payload, googlePayload, navigate)),
});

interface DispatchToProps {
  signup: (payload: SignupValues) => void;
  signupGoogle: (
    payload: SignupValues,
    googlePayload: any,
    navigate: (path: string) => void
  ) => void;
}

export default connect(null, mapDispatchToProps)(AboutMeDetails);
