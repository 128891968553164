import { connect, useDispatch, useSelector } from "react-redux";
import { RootReducer } from "../../../interfaces/reducer";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ACTIONS } from "../../../redux/actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { neuDaddyLogo } from "../../../assets/images";
import Input from "../../Input";
import { useState } from "react";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { Constants } from "../../../utils/constants";
import { AuthAction } from "../../../redux/thunk";
import { AppDispatch } from "../../../redux/store";
import { ForgotPasswordPayload } from "../../../interfaces/auth";
import { toast } from "react-toastify";
import Loader from "../../Loader/Spinner";

interface Props extends MapDispatchToProps {}

const NewPasswordModal = (props: Props) => {
  const dispatch = useDispatch();
  const { newPswdModal } = useSelector((state: RootReducer) => state.auth);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loader, setLoader] = useState(false);
  const number = localStorage.getItem(Constants.forgotPasswordNumber)!;

  const formik = useFormik({
    initialValues: {
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      newPassword: Yup.string()
        .required("New Password is required")
        .min(8, "Password must be at least 8 characters"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("newPassword")], "Password mismatch")
        .nullable()
        .required("Required"),
    }),
    onSubmit: async (values) => {
      const data = {
        password: values.newPassword,
        country_code: number.slice(1, 3),
        phone_number: number.slice(3, 20),
      };
      setLoader(true);
      const resp: any = await props.forgotPassword(data);
      setLoader(false);
      if (resp?.data?.success) {
        localStorage.removeItem(Constants.forgotPasswordNumber);
        dispatch({ type: ACTIONS.NEW_PSWD_MODAL, status: false });
        toast.success(resp?.data?.message);
      }
    },
  });

  return (
    <div className={`modal login-modal ${newPswdModal ? "d-block" : ""}`}>
      {loader && <Loader />}
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content Edit_your_info">
          <div className="modal-header">
            <div className="logo-modal">
              <img className="me-2" src={neuDaddyLogo} alt="" />
              <FontAwesomeIcon
                className="cursor-pointer close"
                size="lg"
                icon={faClose}
                onClick={() => {
                  localStorage.removeItem(Constants.forgotPasswordNumber);
                  dispatch({ type: ACTIONS.NEW_PSWD_MODAL, status: false });
                }}
              />
            </div>
          </div>
          <div className="modal-body px-0 pb-0">
            <div className="form-sec">
              <form onSubmit={formik.handleSubmit}>
                <div className="row">
                  <div className="Choose_Username about_useredit login-form">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="mb-3">
                          <div className="d-flex justify-content-between">
                            <label>Password</label>
                          </div>
                          <div className="position-relative">
                            <Input
                              type={showPassword ? "text" : "password"}
                              name="newPassword"
                              placeHolder="Enter your password"
                              handleChange={formik.handleChange}
                              value={formik.values.newPassword}
                              error={
                                formik.touched.newPassword &&
                                formik.errors.newPassword
                                  ? formik.errors.newPassword
                                  : ""
                              }
                            />
                            <span
                              className="position-absolute top-50 translate-middle-y password-toggle-btn cursor-pointer"
                              style={{ right: "25px" }}
                              onClick={() => setShowPassword((prev) => !prev)}
                            >
                              <FontAwesomeIcon
                                icon={!showPassword ? faEyeSlash : faEye}
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="mb-3">
                          <div className="d-flex justify-content-between">
                            <label>Confirm Password</label>
                          </div>
                          <div className="position-relative">
                            <Input
                              type={showConfirmPassword ? "text" : "password"}
                              name="confirmPassword"
                              placeHolder="Re-enter your password"
                              handleChange={formik.handleChange}
                              value={formik?.values?.confirmPassword!}
                              error={
                                formik.touched.confirmPassword &&
                                formik.errors.confirmPassword
                                  ? formik.errors.confirmPassword
                                  : ""
                              }
                            />
                            <span
                              className="position-absolute top-50 translate-middle-y password-toggle-btn cursor-pointer"
                              style={{ right: "25px" }}
                              onClick={() =>
                                setShowConfirmPassword(
                                  (prevState) => !prevState
                                )
                              }
                            >
                              <FontAwesomeIcon
                                icon={!showConfirmPassword ? faEyeSlash : faEye}
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="mb-3">
                          <button type="submit">Submit</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  forgotPassword: (payload: ForgotPasswordPayload) =>
    dispatch(AuthAction.forgotPassword(payload)),
});

interface MapDispatchToProps {
  forgotPassword: (payload: ForgotPasswordPayload) => void;
}

export default connect(null, mapDispatchToProps)(NewPasswordModal);
