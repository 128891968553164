import React, { useEffect } from "react";

import { AppDispatch } from "../../redux/store";
import { CommonAction } from "../../redux/thunk";
import { connect, useSelector } from "react-redux";
import { RootReducer } from "../../interfaces/reducer";
import Header from "../../components/Header";
import "./style.css";
import Footer from "../../components/Footer";
import UnAuthFooter from "../Home/footer";

interface Props extends DispatchToProps {}
const PrivacyPolicy = (props: Props) => {
  const privacyPolicy: String = useSelector(
    (state: RootReducer) => state.common.privacyPolicy
  );
  const { authenticated } = useSelector((state: RootReducer) => state.auth);

  useEffect(() => {
    props.getPrivacyPolicy();
  }, []);

  return (
    <div >
      <Header />
      <div className="top-space">
      <div className="privacypolicy ">
        <div className="container">
        {privacyPolicy != "" ? (
          <div dangerouslySetInnerHTML={{ __html: privacyPolicy as string }} />
        ) : (
          <></>
        )}
        </div>
        
      </div>
      </div>
     
      {authenticated ? <Footer /> : <UnAuthFooter />}
    </div>
  );
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getPrivacyPolicy: () => dispatch(CommonAction.getPrivacyPolicy()),
});

interface DispatchToProps {
  getPrivacyPolicy: () => void;
}

export default connect(null, mapDispatchToProps)(PrivacyPolicy);
