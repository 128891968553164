import { Button, Modal } from "react-bootstrap";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { useSelector } from "react-redux";
import { RootReducer } from "../../interfaces/reducer";
import { useState } from "react";
import Otp from "../../components/Modals/Signup/Steps/Otp";
import Loader from "../../components/Loader/Spinner";
import { toast } from "react-toastify";

const EditPhone = ({ props, onClose }: any) => {
  const { profile } = useSelector((state: RootReducer) => state.user);
  const [valid, setValid] = useState(true);
  const [mobile, setMobile] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [otp, setOtp] = useState(false);
  const [loader, setLoader] = useState(false);

  const handleEdit = async () => {
    if (
      countryCode.slice(1, 3) === profile?.country_code &&
      mobile === profile?.phone_number
    ) {
      toast.error("You're already using this phone number.");
      return;
    }
    setLoader(true);
    const values = {
      countryCode: countryCode || profile?.country_code,
      mobileNumber: mobile || profile?.phone_number,
      resend: false,
      user_id: profile?.id || null,
    };
    const resp = await props.sendOtp(values);
    setLoader(false);
    if (resp?.data?.success) {
      setOtp(true);
    }
  };

  const handlePhoneEdit = async () => {
    setLoader(true);
    const resp = await props.editPhone({ phone_number: mobile });
    setLoader(false);
    if (resp?.success) {
      onClose();
    }
  };

  return (
    <div className="m-2 modal_t">
      {loader && <Loader />}
      <Modal.Header closeButton>
        <Modal.Title>Edit Phone Number</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {otp ? (
          <Otp back={false} handlePhoneEdit={handlePhoneEdit} />
        ) : (
          <>
            <div className="mb-2 ph-input">
              <label>Mobile Number</label>
              <PhoneInput
                placeholder="Enter phone number"
                international
                onChange={(value) => {
                  if (value) {
                    if (isValidPhoneNumber(value)) {
                      setMobile(value.slice(3, 20)!);
                      setCountryCode(value.slice(0, 3));
                      setValid(true);
                    } else setValid(false);
                  }
                }}
                onCountryChange={(country) => setCountryCode(country!)}
                className="phone-number"
                defaultCountry="IN"
              />
            </div>
            {!valid && <p className="err-msg mb-1">Invalid Number</p>}
            <Button
              className="float-none mt-0"
              variant="primary"
              type="button"
              onClick={handleEdit}
              disabled={!valid}
            >
              Edit number
            </Button>
          </>
        )}
      </Modal.Body>
    </div>
  );
};

export default EditPhone;
