import { connect, useDispatch, useSelector } from "react-redux";
import Autocomplete from "react-google-autocomplete";
import { useFormik } from "formik";
import { useEffect } from "react";
import "./style.css";
import { RootReducer } from "../../../interfaces/reducer";
import { EDIT_PROFILE_MODAL, THEME } from "../../../utils/constants";
import { ACTIONS } from "../../../redux/actions";
import { AppDispatch } from "../../../redux/store";
import { UserAction } from "../../../redux/thunk";
import { UpdateProfilePayload } from "../../../interfaces/user";
import { ProfilePersonalDetailsSchema } from "../../../schemas";
import { LocationInfo } from "../../../assets/images";
import Input from "../../Input";

interface Props extends DispatchToProps {}

const EditPersonalDetails = (props: Props) => {
  const dispatch = useDispatch();

  const { editProfileModal, profile } = useSelector(
    (state: RootReducer) => state.user
  );
  const { theme } = useSelector((state: RootReducer) => state.common);

  const closeModal = () => {
    dispatch({ type: ACTIONS.SET_PROFILE_MODAL, profileModal: "" });
  };

  const editPersonalDetailsFormik = useFormik({
    initialValues: {
      displayName: "",
      location: "",
      heading: "",
      latitude: "",
      longitude: "",
    },
    onSubmit: (values) => {
      const payload = {
        user_name: values.displayName,
        location: values.location,
        latitude: values.latitude.toString(),
        longitude: values.longitude.toString(),
        heading: values.heading,
      };
      props.updateProfile(payload);
    },
    validationSchema: ProfilePersonalDetailsSchema,
  });

  useEffect(() => {
    if (profile && Object.keys(profile).length) {
      editPersonalDetailsFormik.setValues({
        displayName: profile.user_name as string,
        heading: profile.headline as string,
        location: profile.location as string,
        latitude: profile.latitude as string,
        longitude: profile.longitude as string,
      });
    }
  }, [profile]);

  return (
    <div
      className={`modal login-modal ${
        editProfileModal === EDIT_PROFILE_MODAL.EDIT_PERSONAL_DETAILS
          ? "d-block"
          : ""
      }`}
    >
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content Edit_your_info">
          <button
            type="button"
            className={`btn-close ${
              theme === THEME.DARK ? "btn-close-white" : ""
            } close`}
            onClick={closeModal}
          ></button>
          <div className="modal-header">
            <h1>Edit your info</h1>
          </div>

          <div className="modal-body px-0 pb-0">
            <div className="form-sec">
              <div className="row">
                <div className="Choose_Username">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label
                          htmlFor="validationServer02"
                          className="form-label w-100"
                        >
                          Display name
                        </label>
                        <Input
                          type="text"
                          name="displayName"
                          placeHolder="Display Name"
                          handleChange={editPersonalDetailsFormik.handleChange}
                          value={editPersonalDetailsFormik.values.displayName}
                          className="w-100"
                          error={
                            editPersonalDetailsFormik.touched.displayName &&
                            editPersonalDetailsFormik.errors.displayName
                              ? editPersonalDetailsFormik.errors.displayName
                              : ""
                          }
                        />
                      </div>
                    </div>

                    {/* <div className="col-md-4">
                                            <button className="text-white text-center">Suggest</button>
                                        </div> */}

                    <div className="col-md-12">
                      <div className="mb-3">
                        <label
                          htmlFor="validationServer02"
                          className="form-label"
                        >
                          Primary Location
                        </label>
                        <div className="valida_use">
                          <Autocomplete
                            apiKey={process.env.REACT_APP_GOOGLE_MAPS_KEY}
                            onPlaceSelected={(place) => {
                              const {
                                geometry: { location },
                                formatted_address,
                              } = place;
                              const latitude = location.lat();
                              const longitude = location.lng();

                              editPersonalDetailsFormik.setFieldValue(
                                "location",
                                formatted_address
                              );
                              editPersonalDetailsFormik.setFieldValue(
                                "latitude",
                                latitude
                              );
                              editPersonalDetailsFormik.setFieldValue(
                                "longitude",
                                longitude
                              );
                            }}
                            className="location-details"
                            defaultValue={
                              editPersonalDetailsFormik.values.location
                            }
                          />
                          <img
                            src={LocationInfo}
                            alt=""
                            className="img-fluid loca_icon"
                          />
                        </div>
                        {/* <div className="text-end text-danger">Change location</div> */}
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="mb-3">
                        <label
                          htmlFor="validationServer02"
                          className="form-label"
                        >
                          Heading
                        </label>
                        <div className="valida_use">
                          <Input
                            type="text"
                            name="heading"
                            placeHolder="Heading"
                            handleChange={
                              editPersonalDetailsFormik.handleChange
                            }
                            value={editPersonalDetailsFormik.values.heading}
                            className="w-100"
                            error={
                              editPersonalDetailsFormik.touched.heading &&
                              editPersonalDetailsFormik.errors.heading
                                ? editPersonalDetailsFormik.errors.heading
                                : ""
                            }
                          />
                          <img
                            src="assets/images/close_use.png"
                            alt=""
                            className="img-fluid loca_icon"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="cancel_save">
                        <button type="button" onClick={closeModal}>
                          Cancel
                        </button>
                        <button
                          type="button"
                          onClick={editPersonalDetailsFormik.submitForm}
                        >
                          Save Info
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  updateProfile: (payload: UpdateProfilePayload) =>
    dispatch(UserAction.updateProfileDetails(payload)),
});

interface DispatchToProps {
  updateProfile: (payload: UpdateProfilePayload) => void;
}

export default connect(null, mapDispatchToProps)(EditPersonalDetails);
