import React, { useState, useEffect } from "react";
import { apiAxiosInstance } from "../../config/axios";
import { UserProfile } from "../../assets/images";
import Women from "../../assets/images/femaleProfile@3x.png";
import Men from "../../assets/images/maleProfile@3x.png";
import Common from "../../assets/images/dummyProfile.png";
import { toast } from "react-toastify";
import Loader from "../Loader/Spinner";
import { Constants } from "../../utils/constants";
interface BlockedUser {
  id: string;
  to_user: {
    id: Number;
    user_name: string;
    email: string;
    profile_image: string;
    location: string;
    gender: number;
    is_profile_image_approved: number;
    is_name_approved: number;
  };
}
const SeeBlockedUser: React.FC = () => {
  const [blockedUsers, setBlockedUsers] = useState<BlockedUser[]>([]);
  const [loading, setLoading] = useState(true); // State to manage loading

  const fetchBlockedUsers = async () => {
    try {
      const res = await apiAxiosInstance.get("/blocked-users");
      setBlockedUsers(res.data.data); // Adjust according to the response structure
    } catch (error) {
      console.error("Error fetching blocked users:", error);
    } finally {
      setLoading(false); // Set loading to false once data is fetched
    }
  };

  const handleUnblockUser = async (user_id: Number) => {
    try {
      await apiAxiosInstance.post("/unblock-user", { user_id }); // Adjust endpoint if needed
      fetchBlockedUsers();
      toast.success("Unblock Successfully ", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      console.error("Error unblocking user:", error);
    }
  };

  useEffect(() => {
    fetchBlockedUsers();
  }, []);

  return (
    <div className="container">
      <h1 className="mb-4">Blocked Users</h1>
      {loading ? (
        <Loader />
      ) : blockedUsers.length > 0 ? (
        blockedUsers.map((data) => (
          <div className="card mb-3" key={data?.id}>
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h5 className="card-title mb-1">
                    {data?.to_user?.is_name_approved
                      ? data?.to_user?.user_name
                      : Constants.newUser}
                  </h5>
                  <p className="card-text">{data?.to_user?.location}</p>
                </div>
                <img
                  src={
                    data?.to_user?.is_profile_image_approved
                      ? data?.to_user?.profile_image
                      : data?.to_user?.gender === 1
                      ? Women
                      : data?.to_user?.gender === 2
                      ? Men
                      : Common
                  }
                  className="rounded-circle"
                  alt="Profile"
                  style={{ width: "50px", height: "50px" }}
                />
              </div>
              <button
                className="btn btn-danger mt-3"
                onClick={() => {
                  handleUnblockUser(data?.to_user?.id);
                }}
              >
                Unblock
              </button>
            </div>
          </div>
        ))
      ) : (
        <p>No blocked users found.</p>
      )}
    </div>
  );
};

export default SeeBlockedUser;
