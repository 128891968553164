/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import Header from "../../components/Header";
import "./style.css";
import { useLocation } from "react-router";
import { RootReducer } from "../../interfaces/reducer";
import { Charcamera, Messend } from "../../assets/images";
import { Constants, MESSAGES, SOCKET } from "../../utils/constants";
import { MemberAction, MessagesAction } from "../../redux/thunk";
import { AppDispatch } from "../../redux/store";
import { apiAxiosInstance } from "../../config/axios";
import { useNavigate } from "react-router-dom";
import ROUTES from "../../constants/routes";
import { ACTIONS } from "../../redux/actions";
import UserInfo from "../../components/Modals/UserInfo";
import Women from "../../assets/images/femaleProfile@3x.png";
import Men from "../../assets/images/maleProfile@3x.png";
import Common from "../../assets/images/dummyProfile.png";
import { toast } from "react-toastify";
import MessageLayout from "../../components/Chat/MessageLayout";
import ChatOptions from "../../components/Chat/ChatOptions";

interface Props extends DispatchToProps {}
const Chatmessage = (props: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const fileUploadRef = useRef<any>(null);
  const scrollToBottomRef = useRef<HTMLDivElement>(null);
  const fc = useRef<HTMLDivElement>(null);
  const elementRef = useRef(null);
  const { socket } = useSelector((state: RootReducer) => state.common);
  const { profile } = useSelector((state: RootReducer) => state.user);
  const { otherMessages } = useSelector((state: RootReducer) => state.messages);
  const otherMemberProfile = useSelector(
    (state: RootReducer) => state.members.otherMemberProfile
  );
  const [otherUserInfo, setOtherUserInfo] = useState<any>();
  const [otherUserProfile, setOtherUserProfile] = useState<any>();
  const [templateOptions, setTemplateOptions] =
    useState<{ message: string }[]>();
  const [myProfile, setMyProfile] = useState<any>({});
  const [message, setMessage] = useState("");
  const chatDiv = useRef<HTMLDivElement>(null);
  const [messages, setMessages] = useState<any>([]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [messageInbox, setMessageInbox] = useState(false);
  const [chatId, setChatId] = useState(0);

  const insertImage = () => {
    fileUploadRef.current.click();
  };

  async function fetchMoreItems() {
    props.getOtherMessages(Number(location.pathname.split("/")[2]), page);
  }

  function onIntersection(entries: any) {
    const firstEntry = entries[0];
    if (firstEntry.isIntersecting && hasMore) {
      fetchMoreItems();
    }
  }

  useEffect(() => {
    getTemplate();
    props.getOtherMemberProfile({
      user_id: Number(location.pathname.split("/")[2]),
    });
    return () => setMessages([]);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(onIntersection, {
      threshold: 0.1,
    });
    if (observer && elementRef.current) {
      observer.observe(elementRef.current);
    }
    if (messages?.length <= 20)
      scrollToBottomRef.current?.scrollIntoView({
        block: "end",
        inline: "nearest",
      });

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [messages]);

  useEffect(() => {
    setOtherUserProfile(otherMemberProfile);
    setMyProfile(profile);
    if (profile?.id) {
      dispatch({
        type: ACTIONS.INITIALIZE_SOCKET,
        senderId: Number(profile.id),
      });
    }
  }, [profile]);

  useEffect(() => {
    if (page === 1) {
      setMessages([]);
    }
  }, [page]);

  useEffect(() => {
    if (Object.keys(otherMessages).length === 0) {
      return;
    }
    if (Object.keys(otherMessages).length) {
      setOtherUserInfo(otherMessages.user);
      if (
        Object.keys(otherMessages).length > 0 &&
        otherMessages?.messages?.data?.length === 0
      ) {
        setHasMore(false);
      } else {
        const sortByDate = (arr: any) => {
          if (!Array.isArray(arr) || arr.length === 0) {
            return [];
          }
          const sorter = (a: any, b: any) => {
            return (
              new Date(b.created_at).getTime() -
              new Date(a.created_at).getTime()
            );
          };
          arr.sort(sorter);
          return arr;
        };
        setMessages((prevMessages: any) => [
          ...sortByDate(otherMessages?.messages?.data).reverse(),
          ...prevMessages,
        ]);
      }
      if (otherMessages?.chatConversation?.id)
        setChatId(otherMessages?.chatConversation?.id);
      dispatch({ type: ACTIONS.SET_OTHER_MESSAGES, messages: [] });
      const np = page + 1;
      setPage(np);
      if (chatDiv.current) chatDiv.current.scrollTop = 200;
      setTimeout(() => {
        if (fc.current) {
          fc.current.scrollIntoView({ block: "start" });
        }
      }, 400);
    }
  }, [otherMessages, messages, page]);

  useEffect(() => {
    setOtherUserProfile(otherMemberProfile);
  }, [otherMemberProfile]);

  useEffect(() => {
    if (socket?.on) {
      socket.on(SOCKET.SENDMESSAGE, (res: any) => {
        setMessages((prevMessages: any[]) => [...prevMessages, res]);
        scrollToBottomRef.current?.scrollIntoView({ behavior: "smooth" });
      });
    }
    return () => {
      if (socket?.off) {
        socket.off(SOCKET.SENDMESSAGE);
      }
    };
  }, [socket]);

  const getTemplate = async () => {
    const resp = await props.getTemplateMessages();
    setTemplateOptions(resp!);
  };
  const handleImageChange = async (event: any) => {
    const { files } = event.target;
    if (files) {
      let formData = new FormData();
      let imageURL = "";
      const file = files[0];
      formData.append("image", file);
      if (files) {
        const {
          data: { data },
        } = await apiAxiosInstance.post(`/upload/image`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        imageURL = data[0].orig_path_url;
        socket.emit(
          SOCKET.SENDMESSAGE,
          {
            user_id: Number(location.pathname.split("/")[2]),
            type: "image",
            is_request: true,
            message: `${message},${imageURL}`,
          },
          () => {
            setPage(1);
            setHasMore(true);
            setPage(1);
            setMessage("");
          }
        );
      }
    }
    event.target.value = null;
  };

  if (socket?.on) {
    socket.on(SOCKET.SENDMESSAGE, (res: any) => {
      setPage(1);
      setHasMore(true);
    });
  }

  const handleMessageSend = (template?: string) => {
    if (
      messages?.length > 0 &&
      !profile?.is_subscribed &&
      profile?.gender === 2
    ) {
      const ownMessages = messages?.filter(
        (message: any) => message?.sender_id === profile?.id
      );
      if (ownMessages?.length >= 3) {
        toast.error("Please buy subscription to view & send more messages");
        return;
      }
    }
    let msg = template ? template : message;
    socket.emit(
      SOCKET.SENDMESSAGE,
      {
        user_id: Number(location.pathname.split("/")[2]),
        type: SOCKET.TEXT,
        is_request: true,
        message: msg,
      },
      (res: any) => {
        setPage(1);
        setHasMore(true);
        setMessage("");
      }
    );
  };

  const _onPressGrantAccess = async (val: number) => {
    if (otherMemberProfile?.is_access && val === 1) {
      toast.success("Already permission granted");
      return;
    } else {
      const socketMessage = {
        sender_id: profile?.id,
        user_id: otherMemberProfile?.id,
        receiver_id: otherMemberProfile?.id,
        _id: profile?.id,
        text:
          val === 1
            ? `${profile?.user_name} granted your private photo request `
            : `${profile?.user_name} removed your private photo request `,
        message:
          val === 1
            ? `${profile?.user_name} granted your private photo request `
            : `${profile?.user_name} removed your private photo request `,
        type: "text",
        is_request: 1,
        createdAt: String(new Date()),
        user: {
          _id: profile?.id,
          name: profile?.user_name,
          avatar: profile?.profile_image,
        },
      };
      const apiData = {
        other_user_id: otherMemberProfile?.id,
        user_id: profile?.id,
        status: val,
      };
      const resp: any = await props.grantPhotoAccess(apiData);
      if (resp?.success) {
        socket.emit("sendMessage", socketMessage, () => {
          toast.success(resp?.message);
        });
      }
    }
  };

  return (
    <>
      <Header />
      <section className="main-sec chat_message top-space">
        <div className="container my-4">
          <button
            type="button"
            className="inbox-sidebar"
            onClick={() => setMessageInbox((prev) => !prev)}
          >
            Inbox
          </button>
          <div className="row m-0">
            <div className="offset-lg-1 col-lg-2 col-md-2 col-sm-12 p-0">
              <div
                className={`left_sidemenu ${messageInbox ? "inbox-slide" : ""}`}
              >
                <span
                  className="cross-sidebar"
                  onClick={() => setMessageInbox((prev) => !prev)}
                >
                  <i className="fas fa-times"></i>
                </span>
                <ul>
                  <li
                    onClick={() => {
                      navigate(`${ROUTES.MESSAGES}/${MESSAGES.INBOX}`);
                    }}
                    className="active"
                  >
                    Inbox
                  </li>
                  <li
                    onClick={() => {
                      navigate(`${ROUTES.MESSAGES}/${MESSAGES.ARCHIVE}`);
                    }}
                  >
                    Archive
                  </li>
                  <li
                    onClick={() => {
                      navigate(`${ROUTES.MESSAGES}/${MESSAGES.FILTER}`);
                    }}
                  >
                    Filter Out
                  </li>
                </ul>
              </div>
            </div>
            <div className="dark-chat mob-chat col-lg-8 col-md-8 col-sm-12 p-0">
              <div className="center_message">
                <span
                  className="cursor-pointer"
                  onClick={() => {
                    navigate(
                      `${ROUTES.USER_PROFILE}/${
                        location.pathname.split("/")[2]
                      }`
                    );
                  }}
                >
                  <div className="chat_img">
                    <img
                      src={
                        otherUserInfo?.is_profile_image_approved
                          ? otherUserInfo.profile_image
                          : otherUserInfo?.gender === 1
                          ? Women
                          : otherUserInfo?.gender === 2
                          ? Men
                          : Common
                      }
                      alt=""
                    />
                  </div>
                  <div className="chat_prfoilecenter">
                    <h2>
                      {otherUserInfo?.is_name_approved
                        ? otherUserInfo?.user_name
                        : Constants.newUser}
                    </h2>
                    <h5>
                      {otherUserInfo?.is_bio_approved
                        ? otherUserInfo?.headline
                        : ""}
                    </h5>
                  </div>
                </span>
                {!otherUserInfo?.you_are_blocked && (
                  <div className="flex-drop">
                    <div className="dropdown">
                      <button
                        type="button"
                        className="btn-dot dropdown-toggle"
                        data-bs-toggle="dropdown"
                      >
                        <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                      </button>
                      <ChatOptions
                        otherUser={otherUserInfo}
                        handleRemovePermissions={() => _onPressGrantAccess(2)}
                        chatId={chatId}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div ref={chatDiv} className="message_enter" id="style-2">
                {hasMore && (
                  <div ref={elementRef}>
                    {messages?.length === 0 ? "Send New Message" : "Loading"}
                  </div>
                )}
                {messages.length > 0 &&
                  messages?.map((message: any, i: any) => {
                    if (i === 0 && hasMore === false) {
                      return (
                        <div
                          ref={fc}
                          className={`messagebottom_center ${
                            message.sender_id !==
                            +location.pathname.split("/")[2]
                              ? "right_chat"
                              : ""
                          }`}
                        >
                          <MessageLayout
                            profile={profile}
                            otherUserProfile={otherUserProfile}
                            myProfile={myProfile}
                            message={message}
                            grantAccess={() => _onPressGrantAccess(1)}
                          />
                        </div>
                      );
                    } else if (
                      (messages.length % 20 !== 0 &&
                        i === messages.length % 20) ||
                      i === 20
                    ) {
                      return (
                        <div
                          ref={fc}
                          className={`messagebottom_center ${
                            message.sender_id !==
                            +location.pathname.split("/")[2]
                              ? "right_chat"
                              : ""
                          }`}
                        >
                          <MessageLayout
                            profile={profile}
                            otherUserProfile={otherUserProfile}
                            myProfile={myProfile}
                            message={message}
                            grantAccess={() => _onPressGrantAccess(1)}
                          />
                        </div>
                      );
                    } else {
                      return (
                        <div
                          className={`messagebottom_center ${
                            message.sender_id !==
                            +location.pathname.split("/")[2]
                              ? "right_chat"
                              : ""
                          }`}
                        >
                          <MessageLayout
                            profile={profile}
                            otherUserProfile={otherUserProfile}
                            myProfile={myProfile}
                            message={message}
                            grantAccess={() => _onPressGrantAccess(1)}
                          />
                        </div>
                      );
                    }
                  })}
                {<div ref={scrollToBottomRef}></div>}
              </div>
              {otherUserInfo?.is_blocked ? (
                "You have blocked this user"
              ) : otherUserInfo?.you_are_blocked ? (
                "You are blocked!"
              ) : !profile?.is_subscribed && profile?.gender === 2 ? (
                <div>
                  {templateOptions?.map((template) => (
                    <span
                      className="cursor-pointer"
                      key={template?.message}
                      onClick={() => handleMessageSend(template?.message)}
                    >
                      {template?.message}
                    </span>
                  ))}
                </div>
              ) : (
                <div className="chat_send">
                  <img
                    onClick={() => {
                      insertImage();
                    }}
                    src={Charcamera}
                    alt=""
                  />
                  <div className="search_chat w-100">
                    <input
                      value={message}
                      onChange={(e) => {
                        setMessage(e.target.value);
                      }}
                      type="search"
                      className="form-control"
                      placeholder="Message"
                    />
                    <div className="ic-send">
                      <img
                        onClick={() => handleMessageSend()}
                        src={Messend}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
            <UserInfo />
          </div>
        </div>
      </section>
      <input
        ref={fileUploadRef}
        type="file"
        className="d-none"
        accept="image/*"
        onChange={handleImageChange}
      />
    </>
  );
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getOtherMemberProfile: (payload: any) =>
    dispatch(MemberAction.getMemberProfile(payload)),
  getOtherMessages: (userId: Number, page: Number) =>
    dispatch(MessagesAction.getMessageOther(userId, page)),
  getSentMessages: () => dispatch(MessagesAction.getSentMessage()),
  getTemplateMessages: () => dispatch(MessagesAction.getTemplateMessages()),
  grantPhotoAccess: (payload: any) =>
    dispatch(MessagesAction.grantPhotoAccess(payload)),
});

interface DispatchToProps {
  getOtherMemberProfile: (payload: any) => void;
  getOtherMessages: (userId: Number, page: Number) => void;
  getSentMessages: () => void;
  getTemplateMessages: () => void;
  grantPhotoAccess: (payload: any) => void;
}

export default connect(null, mapDispatchToProps)(Chatmessage);
