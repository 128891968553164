import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";

import "bootstrap/dist/css/bootstrap.min.css";
import { apiAxiosInstance } from "../../../config/axios";
import { toast } from "react-toastify";
import BlockUser from "./BlockUser";
import ReportUser from "./ReportUser";

interface IOptionsMenuProps {
  otherMemberProfile: any;
  refech: () => void;
}

const OptionsMenu = ({ otherMemberProfile, refech }: IOptionsMenuProps) => {
  const [showMadal, setShowModal] = useState(false);
  const [showMadalReport, setShowModalReport] = useState(false);

  const handleBlock = () => {
    setShowModal(true);
  };
  const handleReport = () => {
    setShowModalReport(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };
  const closeModalReport = () => {
    setShowModalReport(false);
  };

  const handleHideUser = async (user_id: Number) => {
    try {
      const isUserHidden = otherMemberProfile?.is_hide;
      await apiAxiosInstance.post("/hide-user", { user_id });
      refech();

      const message =
        isUserHidden === 0 ? "hide Successfully." : "unhide Successfully.";
      toast.success(message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      console.error("Error blocking user:", error);
    }
  };

  return (
    <>
      <div className="dropdown">
        <a
          className="btn btn-secondary dropdown-toggle"
          href="#"
          role="button"
          id="dropdownMenuLink"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <FontAwesomeIcon icon={faEllipsisH} />
        </a>

        <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
          <li>
            <a className="dropdown-item" href="#" onClick={handleBlock}>
              {otherMemberProfile?.is_blocked ? "Unblock User" : "Block User"}
            </a>
          </li>
          <li>
            <a
              className="dropdown-item"
              href="#"
              onClick={() => handleHideUser(otherMemberProfile?.id!)}
            >
              {otherMemberProfile?.is_hide === 1 ? "Unhide User" : "Hide User"}
            </a>
          </li>
          <li>
            <a className="dropdown-item" href="#" onClick={handleReport}>
              Report User
            </a>
          </li>
        </ul>
      </div>
      {showMadal && (
        <BlockUser
          closeModal={closeModal}
          refech={refech}
          setShowModal={setShowModal}
        />
      )}
      {showMadalReport && (
        <ReportUser
          closeModalReport={closeModalReport}
          otherMemberProfile={otherMemberProfile}
          refech={refech}
        />
      )}
    </>
  );
};

export default OptionsMenu;
