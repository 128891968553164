const CurrentPlan = ({ subscriptionExpDetail }) => {
  return (
    <>
      <h5 className="current-plans">Current Plan</h5>
    <div className=" plan-w position-relative">
    <div class="ribbon ribbon-bottom-left"><span className="flip-both">Active</span></div>
    <div className="time-holder holder_inner d-block">
      <p className="plan_title title-head">{subscriptionExpDetail?.subsDetails.title}</p>
      <p  className="plan_rupee price-place2"> ₹ <span>{subscriptionExpDetail?.subsDetails.price}</span>/-</p>
      <p>
        For
        {+subscriptionExpDetail?.subsDetails?.duration === 12
          ? " 1 year"
          : " " + subscriptionExpDetail?.subsDetails?.duration + " month(s)"}
      </p>
      </div>


      <div className=" p-40 ">
      
      <div>
       
        <p  className="plan_rupee price-place">Days left : <span>{subscriptionExpDetail?.subscriptionDaysLeft}</span></p>
      </div>
     
      <p className="key_f">Key features</p>
      <ul className="nav d-block nav_subs">
        {subscriptionExpDetail?.subsDetails.assignedfeatures.map((feature) => (
          <div key={feature.id}>
            {feature.features.map((subFeature) => (
              <li key={subFeature.id}>{subFeature.feature}</li>
            ))}
          </div>
        ))}
      </ul>
      </div>
      </div>
    </>
  );
};

export default CurrentPlan;
