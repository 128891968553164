const homeSlickerSettings = {
	slidesToShow: 2,
	slidesToScroll: 1,
	autoplaySpeed: 2000,
	autoplay: true,
	arrows: true,
	speed: 1000,

	responsive: [
		{
			breakpoint: 768,
			settings: {
				arrows: false,
				slidesToShow: 1
			}
		},
		{
			breakpoint: 480,
			settings: {
				arrows: true,
				centerMode: false,
				slidesToShow: 1
			}
		}
	]
};

export {
	homeSlickerSettings
};
