import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { useState } from "react";
import "../../style.css";
import { RootReducer } from "../../../../../interfaces/reducer";
import { ACTIONS } from "../../../../../redux/actions";
import { ProfessionalDetailsSchema } from "../../../../../schemas";
import {
  GENERAL_DETAILS_STEP,
  SIGNUP_STEPS,
} from "../../../../../utils/constants";

const ProfessionalDetails = () => {
  const dispatch = useDispatch();
  const {
    education,
    relationshipStatus,
    childrenStatus,
    smokerStatus,
    drinkerStatus,
    netWorth,
    annualIncome,
    occupation,
  } = useSelector((state: RootReducer) => state.common);
  const {
    educationId,
    occupationId,
    relationshipId,
    annualIncome: annualIncomeId,
    netWorth: netWorthId,
    childrenId,
    smokerId,
    drinkerId,
  } = useSelector((state: RootReducer) => state.auth.signupValues);

  const [professtionalDetails, setProfessionalDetails] = useState({
    education: "",
    relationship: "",
    childrenStatus: "",
    smokerStatus: "",
    drinkerStatus: "",
    netWorth: "",
    annualIncome: "",
    occupation: "",
  });
  const [showErrors, setShowErrors] = useState(false);
  const [openAccordion, setOpenAccordion] = useState("collapseZero");

  const professionalDetailsFormik = useFormik({
    initialValues: {
      educationId: educationId || 0,
      relationshipId: relationshipId || 0,
      childrenId: childrenId || 0,
      smokerId: smokerId || 0,
      drinkerId: drinkerId || 0,
      netWorth: netWorthId || 0,
      annualIncome: annualIncomeId || 0,
      occupationId: occupationId || 0,
    },
    onSubmit: (values) => {
      dispatch({
        type: ACTIONS.SET_SIGNUP_VALUES,
        values,
      });
      dispatch({
        type: ACTIONS.SET_SIGNUP_STEP,
        step: SIGNUP_STEPS.PREVIEW_CROPPED_PROFILE_PHOTO,
        generalStep: GENERAL_DETAILS_STEP.ADD_SEEKING_DETAILS,
      });
    },
    validationSchema: ProfessionalDetailsSchema,
  });

  const handleAccordionClick = (accordionId: string) => {
    setOpenAccordion(openAccordion === accordionId ? "" : accordionId);
  };

  const handleSubmit = () => {
    setShowErrors(true);
    professionalDetailsFormik.submitForm();
  };

  return (
    <div className="modal-body px-0 pb-0">
      <h6 className="looks-great">Almost Done....</h6>
      <div className="accordian-page">
        <div className="accordion" id="accordionExample">
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingZero">
              <button
                className={`accordion-button ${
                  openAccordion === "collapseZero" ? "" : "collapsed"
                }`}
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseZero"
                aria-expanded={openAccordion === "collapseZero"}
                aria-controls="collapseZero"
                onClick={() => handleAccordionClick("collapseZero")}
              >
                Education
                {showErrors && professionalDetailsFormik.errors.educationId ? (
                  <span className="text-danger">
                    {professionalDetailsFormik.errors.educationId}
                  </span>
                ) : (
                  // <span>{professtionalDetails.education}</span>
                  <></>
                )}
              </button>
            </h2>
            <div
              id="collapseZero"
              className={`accordion-collapse collapse ${
                openAccordion === "collapseZero" ? "show" : ""
              }`}
              aria-labelledby="headingZero"
            >
              <div className="accordion-body">
                <ul>
                  {education?.map((item) => (
                    <li
                      onClick={() => {
                        professionalDetailsFormik.setFieldValue(
                          "educationId",
                          item.id
                        );
                        setProfessionalDetails({
                          ...professtionalDetails,
                          education: item.name,
                        });
                      }}
                      key={item.id}
                      className={
                        professionalDetailsFormik.values.educationId === item.id
                          ? "active"
                          : ""
                      }
                    >
                      {item.name}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          {/* <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne">
              <button
                className={`accordion-button ${
                  openAccordion === "collapseOne" ? "" : "collapsed"
                }`}
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded={openAccordion === "collapseOne"}
                aria-controls="collapseOne"
                onClick={() => handleAccordionClick("collapseOne")}
              >
                Occupation
                {showErrors && professionalDetailsFormik.errors.occupationId ? (
                  <span className="text-danger">
                    {professionalDetailsFormik.errors.occupationId}
                  </span>
                ) : (
                  // <span>{professtionalDetails.occupation}</span>
                  <></>
                )}
              </button>
            </h2>
            <div
              id="collapseOne"
              className={`accordion-collapse collapse ${
                openAccordion === "collapseOne" ? "show" : ""
              }`}
              aria-labelledby="headingOne"
            >
              <div className="accordion-body">
                <ul>
                  {occupation.map((item) => (
                    <li
                      onClick={() => {
                        professionalDetailsFormik.setFieldValue(
                          "occupationId",
                          item.id
                        );
                        setProfessionalDetails({
                          ...professtionalDetails,
                          occupation: item.name,
                        });
                      }}
                      key={item.id}
                      className={
                        professionalDetailsFormik.values.occupationId ===
                        item.id
                          ? "active"
                          : ""
                      }
                    >
                      {item.name}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div> */}
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingThree">
              <button
                className={`accordion-button ${
                  openAccordion === "collapseThree" ? "" : "collapsed"
                }`}
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree"
                aria-expanded={openAccordion === "collapseThree"}
                aria-controls="collapseThree"
                onClick={() => handleAccordionClick("collapseThree")}
              >
                Relationship
                {showErrors &&
                professionalDetailsFormik.errors.relationshipId ? (
                  <span className="text-danger">
                    {professionalDetailsFormik.errors.relationshipId}
                  </span>
                ) : (
                  // <span>{professtionalDetails.relationship}</span>
                  <></>
                )}
              </button>
            </h2>
            <div
              id="collapseThree"
              className={`accordion-collapse collapse ${
                openAccordion === "collapseThree" ? "show" : ""
              }`}
              aria-labelledby="headingThree"
            >
              <div className="accordion-body">
                <ul>
                  {relationshipStatus?.map((item) => (
                    <li
                      onClick={() => {
                        professionalDetailsFormik.setFieldValue(
                          "relationshipId",
                          item.id
                        );
                        setProfessionalDetails({
                          ...professtionalDetails,
                          relationship: item.name,
                        });
                      }}
                      key={item.id}
                      className={
                        professionalDetailsFormik.values.relationshipId ===
                        item.id
                          ? "active"
                          : ""
                      }
                    >
                      {item.name}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          {/* <div className="accordion-item">
            <h2 className="accordion-header" id="headingFour">
              <button
                className={`accordion-button ${
                  openAccordion === "collapseFour" ? "" : "collapsed"
                }`}
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFour"
                aria-expanded={openAccordion === "collapseFour"}
                aria-controls="collapseFour"
                onClick={() => handleAccordionClick("collapseFour")}
              >
                Children
                {showErrors && professionalDetailsFormik.errors.childrenId ? (
                  <span className="text-danger">
                    {professionalDetailsFormik.errors.childrenId}
                  </span>
                ) : (
                  // <span>{professtionalDetails.childrenStatus}</span>
                  <></>
                )}
              </button>
            </h2>
            <div
              id="collapseFour"
              className={`accordion-collapse collapse ${
                openAccordion === "collapseFour" ? "show" : ""
              }`}
              aria-labelledby="headingFour"
            >
              <div className="accordion-body">
                <ul>
                  {childrenStatus.map((item) => (
                    <li
                      onClick={() => {
                        professionalDetailsFormik.setFieldValue(
                          "childrenId",
                          item.id
                        );
                        setProfessionalDetails({
                          ...professtionalDetails,
                          childrenStatus: item.name,
                        });
                      }}
                      key={item.id}
                      className={
                        professionalDetailsFormik.values.childrenId === item.id
                          ? "active"
                          : ""
                      }
                    >
                      {item.name}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div> */}
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingFive">
              <button
                className={`accordion-button ${
                  openAccordion === "collapseFive" ? "" : "collapsed"
                }`}
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFive"
                aria-expanded={openAccordion === "collapseFive"}
                aria-controls="collapseFive"
                onClick={() => handleAccordionClick("collapseFive")}
              >
                Smoking
                {showErrors && professionalDetailsFormik.errors.smokerId ? (
                  <span className="text-danger">
                    {professionalDetailsFormik.errors.smokerId}
                  </span>
                ) : (
                  // <span>{professtionalDetails.smokerStatus}</span>
                  <></>
                )}
              </button>
            </h2>
            <div
              id="collapseFive"
              className={`accordion-collapse collapse ${
                openAccordion === "collapseFive" ? "show" : ""
              }`}
              aria-labelledby="headingFive"
            >
              <div className="accordion-body">
                <ul>
                  {smokerStatus?.map((item) => (
                    <li
                      onClick={() => {
                        professionalDetailsFormik.setFieldValue(
                          "smokerId",
                          item.id
                        );
                        setProfessionalDetails({
                          ...professtionalDetails,
                          smokerStatus: item.name,
                        });
                      }}
                      key={item.id}
                      className={
                        professionalDetailsFormik.values.smokerId === item.id
                          ? "active"
                          : ""
                      }
                    >
                      {item.name}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingSix">
              <button
                className={`accordion-button ${
                  openAccordion === "collapseSix" ? "" : "collapsed"
                }`}
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseSix"
                aria-expanded={openAccordion === "collapseSix"}
                aria-controls="collapseSix"
                onClick={() => handleAccordionClick("collapseSix")}
              >
                Drinking
                {showErrors && professionalDetailsFormik.errors.drinkerId ? (
                  <span className="text-danger">
                    {professionalDetailsFormik.errors.drinkerId}
                  </span>
                ) : (
                  // <span>{professtionalDetails.drinkerStatus}</span>
                  <></>
                )}
              </button>
            </h2>
            <div
              id="collapseSix"
              className={`accordion-collapse collapse ${
                openAccordion === "collapseSix" ? "show" : ""
              }`}
              aria-labelledby="headingSix"
            >
              <div className="accordion-body">
                <ul>
                  {drinkerStatus?.map((item) => (
                    <li
                      onClick={() => {
                        professionalDetailsFormik.setFieldValue(
                          "drinkerId",
                          item.id
                        );
                        setProfessionalDetails({
                          ...professtionalDetails,
                          drinkerStatus: item.name,
                        });
                      }}
                      key={item.id}
                      className={
                        professionalDetailsFormik.values.drinkerId === item.id
                          ? "active"
                          : ""
                      }
                    >
                      {item.name}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          {/* <div className="accordion-item">
            <h2 className="accordion-header" id="headingSeven">
              <button
                className={`accordion-button ${
                  openAccordion === "collapseSeven" ? "" : "collapsed"
                }`}
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseSeven"
                aria-expanded={openAccordion === "collapseSeven"}
                aria-controls="collapseSeven"
                onClick={() => handleAccordionClick("collapseSeven")}
              >
                NetWorth
                {showErrors && professionalDetailsFormik.errors.netWorth ? (
                  <span className="text-danger">
                    {professionalDetailsFormik.errors.netWorth}
                  </span>
                ) : (
                  // <span>{professtionalDetails.netWorth}</span>
                  <></>
                )}
              </button>
            </h2>
            <div
              id="collapseSeven"
              className={`accordion-collapse collapse ${
                openAccordion === "collapseSeven" ? "show" : ""
              }`}
              aria-labelledby="headingSeven"
            >
              <div className="accordion-body">
                <ul>
                  {netWorth.map((item) => (
                    <li
                      onClick={() => {
                        professionalDetailsFormik.setFieldValue(
                          "netWorth",
                          item.id
                        );
                        setProfessionalDetails({
                          ...professtionalDetails,
                          netWorth: item.name,
                        });
                      }}
                      key={item.id}
                      className={
                        professionalDetailsFormik.values.netWorth === item.id
                          ? "active"
                          : ""
                      }
                    >
                      {item.name}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div> */}
          {/* <div className="accordion-item">
            <h2 className="accordion-header" id="headingEight">
              <button
                className={`accordion-button ${
                  openAccordion === "collapseEight" ? "" : "collapsed"
                }`}
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseEight"
                aria-expanded={openAccordion === "collapseEight"}
                aria-controls="collapseEight"
                onClick={() => handleAccordionClick("collapseEight")}
              >
                Annual Income
                {showErrors && professionalDetailsFormik.errors.annualIncome ? (
                  <span className="text-danger">
                    {professionalDetailsFormik.errors.annualIncome}
                  </span>
                ) : (
                  // <span>{professtionalDetails.annualIncome}</span>
                  <></>
                )}
              </button>
            </h2>
            <div
              id="collapseEight"
              className={`accordion-collapse collapse ${
                openAccordion === "collapseEight" ? "show" : ""
              }`}
              aria-labelledby="headingEight"
            >
              <div className="accordion-body">
                <ul>
                  {annualIncome.map((item) => (
                    <li
                      onClick={() => {
                        professionalDetailsFormik.setFieldValue(
                          "annualIncome",
                          item.id
                        );
                        setProfessionalDetails({
                          ...professtionalDetails,
                          annualIncome: item.name,
                        });
                      }}
                      key={item.id}
                      className={
                        professionalDetailsFormik.values.annualIncome ===
                        item.id
                          ? "active"
                          : ""
                      }
                    >
                      {item.name}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      {/* <div className="left-rotare-btn show-btns"> */}
        <button className="left-rotare-btn show-btns btn-public mx-auto" type="button" onClick={handleSubmit}>
          Continue
        </button>
      {/* </div> */}
      <button
        className="btn_back"
        onClick={() =>
          dispatch({
            type: ACTIONS.SET_SIGNUP_STEP,
            step: SIGNUP_STEPS.PREVIEW_CROPPED_PROFILE_PHOTO,
            generalStep: GENERAL_DETAILS_STEP.ADD_BODY_DETAILS,
          })
        }
      >
        <i className="fas fa-arrow-left"></i> back
      </button>
    </div>
  );
};

export default ProfessionalDetails;
