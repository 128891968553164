import React, { useState, useEffect } from "react";
import { apiAxiosInstance } from "../../config/axios";
import Women from "../../assets/images/femaleProfile@3x.png";
import Men from "../../assets/images/maleProfile@3x.png";
import Common from "../../assets/images/dummyProfile.png";
import { toast } from "react-toastify";
import Loader from "../Loader/Spinner";
import { Members } from "../../interfaces/reducer";
import { Constants } from "../../utils/constants";

const SeeHiddenUsers: React.FC = () => {
  const [hiddenUsers, setHiddenUsers] = useState<Members[]>([]);
  const [loading, setLoading] = useState(true); // State to manage loading
  const fetchHiddenUsers = async () => {
    try {
      const res = await apiAxiosInstance.get("/get-hide-users");
      setHiddenUsers(res.data.data.data); // Adjust according to the response structure
    } catch (error) {
      console.error("Error fetching hidden users:", error);
    } finally {
      setLoading(false); // Set loading to false once data is fetched
    }
  };

  const handleUnhideUser = async (user_id: Number) => {
    try {
      await apiAxiosInstance.post("/hide-user", { user_id }); // Adjust endpoint if needed
      fetchHiddenUsers();
      toast.success("Unhide Successfully ", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      console.error("Error unhiding user:", error);
    }
  };

  useEffect(() => {
    fetchHiddenUsers();
  }, []);

  return (
    <div className="container">
      <h1 className="mb-4">Hidden Users</h1>
      {loading ? (
        <Loader />
      ) : hiddenUsers?.length > 0 ? (
        hiddenUsers?.map((data) => (
          <div key={data?.id} className="card mb-3">
            {/* {props.data?.is_name_approved
                ? props.data?.user_name
                : Constants.newUser}
                
                */}
            <div className="flex-row">
              <div className="l-150">
                <div className="img-users">
                  <img
                    src={
                      data?.is_profile_image_approved
                        ? data?.profile_image
                        : data?.gender === 1
                        ? Women
                        : data?.gender === 2
                        ? Men
                        : Common
                    }
                    alt={data?.user_name}
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="r-auto">
                <div className="card-body">
                  <h5 className="card-title">
                    {data?.is_name_approved
                      ? data?.user_name
                      : Constants.newUser}
                  </h5>
                  <p className="card-text">{data?.location}</p>
                  <button
                    onClick={() => handleUnhideUser(data?.id!)}
                    className="btn btn-primary"
                  >
                    Unhide
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <p>No hidden users found.</p>
      )}
    </div>
  );
};

export default SeeHiddenUsers;
