import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'react-toastify/dist/ReactToastify.css';
import 'react-phone-number-input/style.css';
import 'react-image-crop/dist/ReactCrop.css';
import "react-double-range-slider/dist/cjs/index.css";

import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { Provider } from "react-redux";
import { ToastContainer } from 'react-toastify';
import { BrowserRouter,Route,Routes } from 'react-router-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { APP_ROUTES } from './utils';
import { store } from './redux/store';
import App from './App';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter(APP_ROUTES);

root.render(
  <Provider store={store}>
    <BrowserRouter >
     <App/>
    </BrowserRouter>
  </Provider>
);

reportWebVitals();
