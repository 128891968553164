import { toast } from "react-toastify";

import { apiAxiosInstance } from "../../config/axios";

export const getSubscriptions = async (
  successCb: (data: any) => void,
  errorCb: (arg0: any) => void
) => {
  try {
    const {
      data: { data },
    } = await apiAxiosInstance.get(`/subscription`);
    if (data) {
      successCb(data);
    } else {
      errorCb("Oops! get some error.");
      toast("Oops! get some error.");
    }
    // dispatch({ type: ACTIONS.GET_SUBSCRIPTION_DATA, data });
  } catch (err: any) {
    errorCb(err);
    if (err.response && err.response.data) {
      toast.error(err.response.data.message);
    }
  }
};

export const getSubscriptionExpirationDetail = () => async () => {
  try {
    const { data } = await apiAxiosInstance.get(
      `/get-user-subscription-details`
    );
    return data;
  } catch (err: any) {
    if (err.response && err.response.data) {
      toast.error(err.response.data.message);
    }
  }
};

export const getSubscriptionsById = async (
  id: number,
  successCb: (data: any) => void,
  errorCb: (arg0: any) => void
) => {
  try {
    const { data } = await apiAxiosInstance.get(`/subscription/${id}`);
    if (data?.data) {
      successCb(data);
    } else {
      toast("Oops! get some error.");
    }
    // dispatch({ type: ACTIONS.GET_SUBSCRIPTION_DATA, data });
  } catch (err: any) {
    // errorCb(err)
    if (err.response && err.response.data) {
      toast.error(err.response.data.message);
      errorCb(err);
    }
  }
};

export const buySubscription = async (values: any) => {
  try {
    const data = await apiAxiosInstance.post(`/payment-intenet/create`, values);
    if (data?.data) {
      return data?.data;
    } else {
      toast("Oops! get some error.");
    }
  } catch (err: any) {
    if (err.response && err.response.data) {
      toast.error(err.response.data.message);
    }
  }
};

export const confirmSubscription = async (values: any) => {
  try {
    const data = await apiAxiosInstance.post(`/payment-confirmation`, values);
    if (data?.data) {
      return data?.data;
    }
  } catch (err: any) {
    if (err.response && err.response.data) {
      toast.error(err.response.data.message);
    }
  }
};
