import { connect, useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { useEffect } from 'react';

import './style.css';
import { RootReducer } from '../../../interfaces/reducer';
import { EDIT_PROFILE_MODAL, THEME } from '../../../utils/constants';
import { ACTIONS } from '../../../redux/actions';
import { AppDispatch } from '../../../redux/store';
import { UpdateProfilePayload } from '../../../interfaces/user';
import { UserAction } from '../../../redux/thunk';

interface Props extends DispatchToProps { };

const EditAboutMe = (props: Props) => {
    const dispatch = useDispatch();
    const { editProfileModal, profile } = useSelector((state: RootReducer) => state.user);
    const { theme } = useSelector((state: RootReducer) => state.common);


    const closeModal = () => {
        dispatch({ type: ACTIONS.SET_PROFILE_MODAL, profileModal: '' });
    };

    const editAboutMeFormik = useFormik({
        initialValues: {
            bio: '',

        },
        onSubmit: (values) => {
            const payload = {
                bio: values.bio,
            };

            props.updateProfile(payload);
        },
    });

    useEffect(() => {
        
        
        if (profile&&Object.keys(profile)?.length) {
            editAboutMeFormik.setValues({
                bio: profile?.bio as string,
            });
        }
    }, [profile]);

    return (
        <div className={`modal login-modal ${editProfileModal === EDIT_PROFILE_MODAL.EDIT_ABOUT_ME ? 'd-block' : ''}`}>
            <div className="modal-dialog modal-dialog-centered modal-lg">
                <div className="modal-content Edit_your_info">

                    <button type="button" onClick={closeModal} className={`btn-close ${theme===THEME.DARK?'btn-close-white':''} close`} data-bs-dismiss="modal"></button>
                    <div className="modal-header">
                        <h1>Edit About Me</h1>
                    </div>
                    <div className="modal-body px-0 pb-0">
                        <div className="form-sec">
                            <form>
                                <div className="row">
                                    <div className="Choose_Username about_useredit">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="mb-3">
                                                    <textarea
                                                        className="form-control"
                                                        name="bio"
                                                        onChange={editAboutMeFormik.handleChange}
                                                        value={editAboutMeFormik.values.bio}
                                                    ></textarea>
                                                    <div className="text-start text-danger">166/4000 characters</div>
                                                </div>
                                            </div>

                                            <div className="col-md-12">
                                                <div className="cancel_save">
                                                    <button type="button" onClick={closeModal}>Cancel</button>
                                                    <button type="button" onClick={editAboutMeFormik.submitForm}>Save About Me</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    updateProfile: (payload: UpdateProfilePayload) => dispatch(UserAction.updateProfileDetails(payload)),
});

interface DispatchToProps {
    updateProfile: (payload: UpdateProfilePayload) => void;
};

export default connect(null, mapDispatchToProps)(EditAboutMe);