/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./style.css";
import Header from "../../components/Header";
import { UserProfile } from "../../assets/images";
import Footer from "../../components/Footer";
import { AppDispatch } from "../../redux/store";
import { MemberAction } from "../../redux/thunk";
import { MemberListPayload } from "../../interfaces/members";
import { RootReducer } from "../../interfaces/reducer";
import SearchSidebar from "../../components/SearchSidebar";
import ROUTES from "../../constants/routes";
import Loader from "../../components/Loader/Spinner";
import { Tabs, Tab } from "react-bootstrap";
import ReactPagination from "../../components/UI/Pagination/ReactPagination";
import { Constants } from "../../utils/constants";
import Women from "./../../assets/images/femaleProfile@3x.png";
import Men from "./../../assets/images/maleProfile@3x.png";
import Common from "./../../assets/images/dummyProfile.png";

interface Props extends DispatchToProps {}

const Members = (props: Props) => {
  const navigate = useNavigate();
  const [activeKey, setActiveKey] = useState<string>("newest");
  const {
    filteredMembers,
    filteredMembersLastPage,
    memberFilters,
    filteredMembersList,
  } = useSelector((state: RootReducer) => state.members);
  const { newestMembers, nearestMembers, recentActiveMembers, membersLoading } =
    useSelector((state: RootReducer) => state.members);

  // useEffect(() => {
  //   getNewestMembers(1);
  //   getNearestMembers(1);
  //   getActiveMembers(1);
  // }, []);

  const getNewestMembers = (page: number) => {
    const newestMembers = {
      sort_by: "created_by",
      latitude: "30.7163",
      longitude: "76.744",
      page: page,
    };
    props.membersList(newestMembers);
  };

  const getNearestMembers = (page: number) => {
    const nearestMembers = {
      sort_by: "distance",
      latitude: "30.7163",
      longitude: "76.744",
      page: page,
    };
    props.membersList(nearestMembers);
  };

  const getActiveMembers = (page: number) => {
    const recentActiveMembers = {
      sort_by: "online_status",
      latitude: "30.7163",
      longitude: "76.744",
      page: page,
    };
    props.membersList(recentActiveMembers);
  };

  const handleNavigate = (userId: any) => {
    navigate(`${ROUTES.USER_PROFILE}/${userId}`);
  };

  return (
    <Fragment>
      {membersLoading && <Loader />}
      <Header />
      <section className="main-sec home_page top-space">
        <div className="container mt-3">
          <div className="row">
            {<SearchSidebar />}
            {/* {filteredMembers ? (
              <div className="col-lg-9 col-md-12 col-sm-12 mx-auto d-block search_page">
                <div className="listing-sec Sort_Recently">
                  <div className="results_fount mb-4 d-flex">
                    <select
                      onClick={(event: any) => {
                        let payload = {
                          ...memberFilters,
                          page: 1,
                          sort_by: event.target.value,
                        };
                        props.filteredList(payload);
                        memberFilters.sort_by = event.target.value;
                      }}
                      className="form-select bg-white border-0 w-25 cursor-pointer"
                      aria-label="Default select example"
                    >
                      <option value="online_status">
                        Sort by Recently Active
                      </option>
                      <option value="distance">Sort by nearest</option>
                      <option value="created_by">Sort by newest</option>
                    </select>
                    <hr className="w-100" />
                    <div className="perpage d-flex justify-content-between w-100"></div>
                  </div>
                  {filteredMembersList?.length > 0 ? (
                    <ul className="nav row listing-5">
                      {filteredMembersList?.map((member, index) => {
                        return (
                          <li key={index}>
                            <div
                              onClick={() => {
                                navigate(
                                  `${ROUTES.USER_PROFILE}/${member?.filters?.user_id}`
                                );
                              }}
                              key={member.id}
                              className="list-img-size cursor-pointer"
                            >
                              <img
                                src={
                                  member?.is_profile_image_approved
                                    ? member.profile_image
                                    : member?.gender === 1
                                    ? Women
                                    : member?.gender === 2
                                    ? Men
                                    : Common
                                }
                                alt=""
                                onError={(e) => {
                                  const target = e.target as HTMLImageElement;
                                  target.onerror = null;
                                  target.src = UserProfile;
                                }}
                              />
                              <div className="n-adress">
                                <h4>
                                  {member?.is_name_approved
                                    ? member.user_name
                                    : Constants.newUser}
                                </h4>
                                <p>
                                  {member.age} - {member.location}
                                </p>
                                <h6>{member.photos?.length} - Photos</h6>
                              </div>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  ) : (
                    <p className="text-center">No member found</p>
                  )}
                  {filteredMembersLastPage > 1 && (
                    <ReactPagination
                      totalPage={filteredMembersLastPage}
                      newOffset={(offset: number) => {
                        let payload = {
                          ...memberFilters,
                          page: offset,
                        };
                        props.filteredList(payload);
                      }}
                    />
                  )}
                </div>
              </div>
            ) : (
              <div className="col-lg-9 col-md-12 col-sm-12 mx-auto d-block">
                <Tabs
                  id="member-tabs"
                  className="tabs_member"
                  activeKey={activeKey}
                  onSelect={(k) => setActiveKey(k!)}
                >
                  <Tab eventKey="newest" title="Newest Members">
                    <ul className="nav row listing-5">
                      {newestMembers?.data?.length === 0 && !membersLoading ? (
                        <p className="mb-0 text-center">No member found</p>
                      ) : (
                        <>
                          {newestMembers?.data?.map((item) => (
                            <li
                              onClick={() =>
                                handleNavigate(item?.filters?.user_id)
                              }
                              key={item.id}
                              className="cursor-pointer"
                            >
                              <div className="list-img-size">
                                <img
                                  src={
                                    item?.is_profile_image_approved
                                      ? item.profile_image
                                      : item?.gender === 1
                                      ? Women
                                      : item?.gender === 2
                                      ? Men
                                      : Common
                                  }
                                  alt=""
                                  onError={(e) => {
                                    const target = e.target as HTMLImageElement;
                                    target.onerror = null;
                                    target.src = UserProfile;
                                  }}
                                />
                                <div className="n-adress">
                                  <h4>
                                    {item?.is_name_approved
                                      ? item.user_name
                                      : Constants.newUser}
                                  </h4>
                                  <p>
                                    {item.age} - {item.location}
                                  </p>
                                </div>
                              </div>
                            </li>
                          ))}
                          {newestMembers?.last_page! > 1 && (
                            <ReactPagination
                              totalPage={newestMembers?.last_page}
                              newOffset={(offset: number) => {
                                getNewestMembers(offset);
                              }}
                            />
                          )}
                        </>
                      )}
                    </ul>
                  </Tab>
                  <Tab eventKey="nearest" title="Nearest Members">
                    <ul className="nav row listing-5">
                      {nearestMembers?.data?.length === 0 && !membersLoading ? (
                        <p className="mb-0 text-center">No member found</p>
                      ) : (
                        <>
                          {nearestMembers?.data?.map((item) => (
                            <li
                              onClick={() =>
                                handleNavigate(item?.filters?.user_id)
                              }
                              key={item.id}
                              className="cursor-pointer"
                            >
                              <div className="list-img-size">
                                <img
                                  src={
                                    item?.is_profile_image_approved
                                      ? item.profile_image
                                      : item?.gender === 1
                                      ? Women
                                      : item?.gender === 2
                                      ? Men
                                      : Common
                                  }
                                  alt=""
                                  onError={(e) => {
                                    const target = e.target as HTMLImageElement;
                                    target.onerror = null;
                                    target.src = UserProfile;
                                  }}
                                />
                                <div className="n-adress">
                                  <h4>
                                    {item?.is_name_approved
                                      ? item.user_name
                                      : Constants.newUser}
                                  </h4>
                                  <p>
                                    {item.age} - {item.location}
                                  </p>
                                </div>
                              </div>
                            </li>
                          ))}
                          {nearestMembers?.last_page! > 1 && (
                            <ReactPagination
                              totalPage={nearestMembers?.last_page}
                              newOffset={(offset: number) => {
                                getNearestMembers(offset);
                              }}
                            />
                          )}
                        </>
                      )}
                    </ul>
                  </Tab>
                  <Tab eventKey="online" title="Members Recently Active">
                    <ul className="nav row listing-5">
                      {recentActiveMembers?.data?.length === 0 &&
                      !membersLoading ? (
                        <p className="mb-0 text-center">No member found</p>
                      ) : (
                        <>
                          {recentActiveMembers?.data?.map((item) => (
                            <li
                              onClick={() =>
                                handleNavigate(item.filters?.user_id)
                              }
                              key={item.id}
                              className="cursor-pointer"
                            >
                              <div className="list-img-size">
                                <img
                                  src={
                                    item?.is_profile_image_approved
                                      ? item.profile_image
                                      : item?.gender === 1
                                      ? Women
                                      : item?.gender === 2
                                      ? Men
                                      : Common
                                  }
                                  alt=""
                                  onError={(e) => {
                                    const target = e.target as HTMLImageElement;
                                    target.onerror = null;
                                    target.src = UserProfile;
                                  }}
                                />
                                <div className="n-adress">
                                  <h4>
                                    {item?.is_name_approved
                                      ? item.user_name
                                      : Constants.newUser}
                                  </h4>
                                  <p>
                                    {item.age} - {item.location}
                                  </p>
                                </div>
                              </div>
                            </li>
                          ))}
                          {recentActiveMembers?.last_page! > 1 && (
                            <ReactPagination
                              totalPage={recentActiveMembers?.last_page}
                              newOffset={(offset: number) => {
                                getActiveMembers(offset);
                              }}
                            />
                          )}
                        </>
                      )}
                    </ul>
                  </Tab>
                </Tabs>
              </div>
            )} */}
          </div>
        </div>
      </section>
      <Footer />
    </Fragment>
  );
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  membersList: (params: MemberListPayload) =>
    dispatch(MemberAction.getMembers(params)),
  filteredList: (params: any) =>
    dispatch(MemberAction.getFilteredMembers(params)),
});

interface DispatchToProps {
  membersList: (params: MemberListPayload) => void;
  filteredList: (params: any) => void;
}

export default connect(null, mapDispatchToProps)(Members);
