import {
  faInstagram,
  faFacebook,
  faLinkedin,
  faYoutube,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { AppStore, GooglePay, neuDaddyLogo } from "../../assets/images";
import "./style.css";
import { useNavigate } from "react-router-dom";
import ROUTES from "../../constants/routes";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <footer className="footer-sec">
      <div className="container">
        <div className="footer-logo">
          <div className="l-sec">
            <img src={neuDaddyLogo} alt="" className="img-fluid me-2" />
            NeuDaddy
          </div>
          <div className="s-icon">
            <FontAwesomeIcon icon={faFacebook} />
            <FontAwesomeIcon icon={faTwitter} />
            <FontAwesomeIcon icon={faLinkedin} />
            <FontAwesomeIcon icon={faInstagram} />
          </div>
        </div>
        <ul className="nav nav-footer pt-3 pb-3 inner-footer mt-3">
          <li>
            <a href={ROUTES.PRIVACYPOLICY}>Privacy</a>
          </li>
          <li>
            <a href={ROUTES.TERMSANDCONDITIONS}>Terms and Conditions</a>
          </li>
          <li>
            <a href={ROUTES.aboutUs}>About Us</a>
          </li>
        </ul>

        <p className="powered-by">© 2023 NeuDaddy | Powered by NeuDaddy</p>
      </div>
    </footer>
  );
};

export default Footer;
