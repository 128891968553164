import React, { useEffect } from 'react'
import { connect, useSelector } from 'react-redux';
import { AppDispatch } from '../../redux/store';
import { AuthAction, CommonAction, UserAction } from '../../redux/thunk';
import { RootReducer } from '../../interfaces/reducer';
import Header from '../../components/Header';
// import './style.css'
import Footer from '../../components/Footer';
interface Props extends DispatchToProps { };


const TermsAndCondition = (props: Props) => {
    const  termsAndConditions: String  = useSelector((state: RootReducer) => state.common.termsAndConditions);




    useEffect(() => {
      props.getTermsAndConditions()
    
     
    }, [props])

    

  return (
    <div className='top-space'>
         <div className='container'>
        <div className='termsAndCondition privacypolicy'>
     {termsAndConditions!==''? <div  dangerouslySetInnerHTML={{ __html: termsAndConditions as string }} />:<></>}</div>
     </div>
    
     <Footer/>
    </div>
  )
}

const mapDispatchToProps = (dispatch: AppDispatch) => ({
   getTermsAndConditions: () => dispatch(CommonAction.getTermsAndCondition()),
   
});

interface DispatchToProps {
   
    getTermsAndConditions: () => void;
};

export default connect(null, mapDispatchToProps)(TermsAndCondition);