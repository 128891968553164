/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../style.css";
import { RootReducer } from "../../../../interfaces/reducer";
import { ACTIONS } from "../../../../redux/actions";
import {
  GENERAL_DETAILS_STEP,
  SIGNUP_STEPS,
} from "../../../../utils/constants";
import LocationDetails from "./GeneralDetails/LocationDetails";
import BodyDetails from "./GeneralDetails/BodyDetails";
import ProfessionalDetails from "./GeneralDetails/ProfessionalDetails";
import SeekingInterestDetails from "./GeneralDetails/SeekingInterestDetails";
import AboutMeDetails from "./GeneralDetails/AboutMeDetails";
import TermCondition from "./GeneralDetails/TermCondition";

const PreviewCroppedFile = () => {
  const [active, setActive] = useState<string[]>([]);
  const dispatch = useDispatch();
  const {
    signupValues: { croppedImage, image },
    generalDetailSteps,
  } = useSelector((state: RootReducer) => state.auth);

  useEffect(() => {
    setActive([...active, generalDetailSteps]);
  }, [generalDetailSteps]);

  const getGeneralDetailsSteps = (step: string) => {
    switch (step) {
      case SIGNUP_STEPS.PREVIEW_CROPPED_PROFILE_PHOTO:
        return <CroppedFile />;
      case GENERAL_DETAILS_STEP.ADD_LOCATION:
        return <LocationDetails />;
      case GENERAL_DETAILS_STEP.ADD_BODY_DETAILS:
        return <BodyDetails />;
      case GENERAL_DETAILS_STEP.ADD_PROFESSIONAL_DETAILS:
        return <ProfessionalDetails />;
      case GENERAL_DETAILS_STEP.ADD_SEEKING_DETAILS:
        return <SeekingInterestDetails />;
      case GENERAL_DETAILS_STEP.ADD_ME_DETAILS:
        return <AboutMeDetails />;
      case GENERAL_DETAILS_STEP.VERIFY_TERMS:
        return <TermCondition />;
      // case GENERAL_DETAILS_STEP.ADD_VERIFY_EMAIL:
      //     return <VerifyEmail />
    }
  };

  const CroppedFile = () => {
    return (
      <Fragment>
        <h6 className="looks-great">You look great!</h6>
        <div className="image-upload">
          <div className="image-upload-show">
            <img
              src={URL.createObjectURL(
                (croppedImage as File) || (image as File)
              )}
              alt=""
              className="preview-image"
            />
          </div>
          <div className="left-rotare-btn show-btns btn ">
            <button 
              onClick={() =>
                dispatch({
                  type: ACTIONS.SET_SIGNUP_STEP,
                  step: SIGNUP_STEPS.CHOOSE_USERNAME,
                })
              }
            >
              Change
            </button>
            <button
              onClick={() => {
                dispatch({
                  type: ACTIONS.SET_SIGNUP_STEP,
                  step: SIGNUP_STEPS.GALLERY_PHOTOS,
                });
                // dispatch({
                //   type: ACTIONS.SET_SIGNUP_STEP,
                //   step: SIGNUP_STEPS.PREVIEW_CROPPED_PROFILE_PHOTO,
                //   generalStep: GENERAL_DETAILS_STEP.ADD_LOCATION,
                // })
              }}
            >
              Continue
            </button>
          </div>
        </div>
      </Fragment>
    );
  };

  return (
    <div className="modal-body px-0 pb-0">
      <div className="steap-form-list">
        <ul>
          {Object.keys(GENERAL_DETAILS_STEP).map((value, index) => {
            return (
              <li
                key={index}
                className={active.indexOf(value) !== -1 ? "active" : ""}
              >
                <span>{index + 1}</span>
              </li>
            );
          })}
        </ul>
      </div>
      {getGeneralDetailsSteps(generalDetailSteps)}
    </div>
  );
};

export default PreviewCroppedFile;
