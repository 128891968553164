import React from "react";
import "./style.css";
import Header from "../../components/Header";
import UnAuthFooter from "../Home/footer";
import ContactUs from "../../components/Settings/ContactUs";

const Contactus = () => {
  return (
    <>
      <Header />
      <div className="top-space">
        <ContactUs />
      </div>
      <UnAuthFooter />
    </>
  );
};

export default Contactus;
