import { Fragment } from "react";
import { useNavigate } from "react-router";
import { Message } from "../../../interfaces/common";
import Women from "../../../assets/images/femaleProfile@3x.png";
import Men from "../../../assets/images/maleProfile@3x.png";
import Common from "../../../assets/images/dummyProfile.png";
import { Constants } from "../../../utils/constants";
import { formatDistanceToNow } from "date-fns";
import { UserProfile } from "../../../interfaces/reducer";

interface MessageProp {
  message: Message;
  profile: UserProfile;
}

const MessageCard: React.FC<MessageProp> = ({ message, profile }) => {
  const navigate = useNavigate();
  const user = message?.other_user;

  return (
    <Fragment>
      <div
        className={`inner_content row ${
          message?.unread_messages > 0 ? "active" : ""
        }`}
      >
        <div
          className="col-md-10"
          onClick={() => navigate(`/chat/${user?.id}`)}
        >
          <span>
            <div className="profile-size position-relative">
              {!!message?.other_user?.online_status && (
                <div className="online_dot"></div>
              )}
              <img
                className="profile_img"
                src={
                  user?.is_profile_image_approved
                    ? user?.profile_image
                    : user?.gender === 1
                    ? Women
                    : user?.gender === 2
                    ? Men
                    : Common
                }
                alt=""
              />
            </div>
            <div className="deatslist_name">
              <h4>
                {!!user?.is_name_approved ? user?.user_name : Constants.newUser}
              </h4>
              <h6>
                {message?.last_message?.message}
                {profile?.id !== message?.last_message?.sender_id ? (
                  <i className="fa fa-level-down ps-2" aria-hidden="true"></i>
                ) : (
                  <i className="fa fa-level-up ps-2" aria-hidden="true"></i>
                )}
              </h6>
            </div>
          </span>
        </div>
        <div className="col-md-2">
          <div className="flex-drop">
            <h6 className="last_seen">
              {formatDistanceToNow(new Date(message?.last_message_at), {
                addSuffix: true,
              })}
            </h6>
            {/* <div className="dropdown">
              <button
                type="button"
                className="btn-dot dropdown-toggle"
                data-bs-toggle="dropdown"
              >
                <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
              </button>
              <ChatOptions userId={message?.other_user?.id} />
            </div> */}
          </div>
          {message?.unread_messages > 0 && (
            <span className="msg_unread">
              <p> {message?.unread_messages}</p>
            </span>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default MessageCard;
