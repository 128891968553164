import { Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import {
  faInstagram,
  faFacebook,
  faTwitter,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import Slider from "react-slick";

import {
  Happen1,
  Happen2,
  Happen3,
  OurMission,
  RightBanner,
  DemoImg,
  LeftHand,
  Mobile,
  AppleStoreApp,
  RightHand,
  AndroidApp,
  FooterLogo,
  neuDaddyLogo,
} from "../../assets/images";
import Header from "../../components/Header";
import "./style.css";
import { homeSlickerSettings } from "../../utils/slicker";
import { useDispatch } from "react-redux";
import { ACTIONS } from "../../redux/actions";
import { Link, useNavigate } from "react-router-dom";
import ROUTES from "../../constants/routes";
import UnAuthFooter from "./footer";

const Home = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleLoginModal = (status: boolean) => {
    dispatch({ type: ACTIONS.SET_LOGIN_MODAL, status });
  };
  return (
    <Fragment>
      <Header />
      <div className="banner">
        <div className="container">
          <div className="row d-flex align-items-center">
            <div className="col-md-6">
              <div className="banner-left">
                <h1>LETS DO IT IN A BETTER WAY WITH NEUDADDY ❣️</h1>
                <a onClick={() => handleLoginModal(true)} href="#">
                  Start Exploring Now!
                </a>
              </div>
            </div>
            <div className="col-md-6">
              <div className="banner-right">
                <img src={RightBanner} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="mission-sec">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-5">
              <img
                src={OurMission}
                alt=""
                className="img-fluid mx-auto d-block"
              />
            </div>
            <div className="col-md-7">
              <h2>Our Mission!</h2>
              <p>
                It is a long established fact that a reader will be distracted
                by the readable content of a page when looking at its layout.
                The point of using Lorem Ipsum is that it has a more-or-less
                normal distribution of letters, as opposed to using 'Content
                here, content here', making it look like readable English. Many
                desktop publishing packages and web page editors now use Lorem
                Ipsum as their default.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="happen-sec">
        <div className="container">
          <h3 className="heading-60">Make it Happen!</h3>
          <div className="row">
            <div
              onClick={() => handleLoginModal(true)}
              className="col-lg-4 col-md-6 col-sm-6"
            >
              <div className="outer-happen ohd">
                <img src={Happen1} alt="" className="mx-auto d-block" />
                <h3>Find People near you!</h3>
                <p>
                  It is a long established fact that a reader will be distracted
                  by the readable content.
                </p>
              </div>
            </div>

            <div
              onClick={() => handleLoginModal(true)}
              className="col-lg-4 col-md-6 col-sm-6"
            >
              <div className="outer-happen ohd">
                <img src={Happen2} alt="" className="mx-auto d-block" />
                <h3>
                  Search from <span>1000+</span> profiles
                </h3>
                <p>
                  It is a long established fact that a reader will be distracted
                  by the readable content.
                </p>
              </div>
            </div>

            <div
              onClick={() => handleLoginModal(true)}
              className="col-lg-4 col-md-6 col-sm-6"
            >
              <div className="outer-happen ohd">
                <img src={Happen3} alt="" className="mx-auto d-block" />
                <h3>Find your match ❣️ </h3>
                <p>
                  It is a long established fact that a reader will be distracted
                  by the readable content.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="seeker">
        <div className="container">
          <div className="bg-seeker">
            <div className="row">
              <div className="col-md-10  col-lg-7 col-sm-10 mx-auto d-block">
                <h3>LETS DO IT IN A BETTER WAY WITH NEUDADDY❣️</h3>
                <p>
                  It is a long established fact that a reader will be distracted
                  by the readable content of a page when looking at its layout.
                  The point of using Lorem Ipsum is that it has a more-or-less
                  normal distribution of letters, as opposed.
                </p>
                <a
                  onClick={() => handleLoginModal(true)}
                  className="start-seek"
                >
                  Start Exploring Now!
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="testimonials">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-4 col-sm-12">
              <div className="client-say">
                <h4>What our clients say about us!</h4>
              </div>
            </div>
            <div className="col-md-12 col-lg-8">
              <Slider {...homeSlickerSettings} className="client-slider">
                <div className="col test-outer">
                  <div className="card-text">
                    <div className="test-img">
                      <img src={DemoImg} alt="" className="img-fluid" />
                    </div>
                    <div className="test-name">
                      Roxanne
                      <span>
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                      </span>
                    </div>
                  </div>
                  <p className="test-p">
                    Quasi quo sit suscipit tempora aperiam rerum placeat id.
                    Voluptatem praesentium excepturi id. Repudiandae incidunt
                    doloremque. Error est et ullam.
                  </p>
                </div>

                <div className="col test-outer">
                  <div className="card-text">
                    <div className="test-img">
                      <img src={DemoImg} alt="" className="img-fluid" />
                    </div>
                    <div className="test-name">
                      Roxanne
                      <span>
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                      </span>
                    </div>
                  </div>
                  <p className="test-p">
                    Quasi quo sit suscipit tempora aperiam rerum placeat id.
                    Voluptatem praesentium excepturi id. Repudiandae incidunt
                    doloremque. Error est et ullam.
                  </p>
                </div>

                <div className="col test-outer">
                  <div className="card-text">
                    <div className="test-img">
                      <img src={DemoImg} alt="" className="img-fluid" />
                    </div>
                    <div className="test-name">
                      Roxanne
                      <span>
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                      </span>
                    </div>
                  </div>
                  <p className="test-p">
                    Quasi quo sit suscipit tempora aperiam rerum placeat id.
                    Voluptatem praesentium excepturi id. Repudiandae incidunt
                    doloremque. Error est et ullam.
                  </p>
                </div>

                <div className="col test-outer">
                  <div className="card-text">
                    <div className="test-img">
                      <img src={DemoImg} alt="" className="img-fluid" />
                    </div>
                    <div className="test-name">
                      Roxanne
                      <span>
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                      </span>
                    </div>
                  </div>
                  <p className="test-p">
                    Quasi quo sit suscipit tempora aperiam rerum placeat id.
                    Voluptatem praesentium excepturi id. Repudiandae incidunt
                    doloremque. Error est et ullam.
                  </p>
                </div>

                <div className="col test-outer">
                  <div className="card-text">
                    <div className="test-img">
                      <img src={DemoImg} alt="" className="img-fluid" />
                    </div>
                    <div className="test-name">
                      Roxanne
                      <span>
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                      </span>
                    </div>
                  </div>
                  <p className="test-p">
                    Quasi quo sit suscipit tempora aperiam rerum placeat id.
                    Voluptatem praesentium excepturi id. Repudiandae incidunt
                    doloremque. Error est et ullam.
                  </p>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </section>

      <section className="hand-sec">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-md-3">
              <img src={LeftHand} alt="" className="img-fluid" />
            </div>
            <div className="col-lg-5 col-md-6 col-sm-6 mx-auto">
              <div className="join-c">
                <img src={Mobile} alt="" className="img-fluid mob-pos" />
                <h3>
                  Join our community
                  <span>&</span> meet your partner!
                </h3>
                <p>
                  It is a long established fact that a reader will be distracted
                  by the readable content.
                </p>
                <div className="d-flex align-items-center justify-content-center">
                  <a href="#" className="pull-right">
                    <img src={AppleStoreApp} alt="" className="img-fluid " />
                  </a>

                  <a href="#">
                    <img src={AndroidApp} alt="" className="img-fluid" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <img
                src={RightHand}
                alt=""
                className="img-fluid pull-right r-hand"
              />
            </div>
          </div>
        </div>
      </section>

      <UnAuthFooter />
    </Fragment>
  );
};

export default Home;
