import { useEffect, useState } from "react";
import BlockUser from "../Modals/Profile/BlockUser";
import { connect, useSelector } from "react-redux";
import { AppDispatch } from "../../redux/store";
import ReportUser from "../Modals/Profile/ReportUser";
import { MessagesAction } from "../../redux/thunk";
import { useNavigate } from "react-router-dom";
import ROUTES from "../../constants/routes";
import { RootReducer } from "../../interfaces/reducer";

interface Props extends MapDispatchToProps {
  otherUser: any;
  handleRemovePermissions: any;
  chatId: any;
}

const ChatOptions = (props: Props) => {
  const { otherMessages } = useSelector((state: RootReducer) => state.messages);
  const navigate = useNavigate();
  const [options, setOptions] = useState({ block: false, report: false });
  const [archiveMsg, setArchiveMsg] = useState(true);

  useEffect(() => {
    const id = otherMessages?.chatConversation?.archived_chat?.[0]?.id;
    if (id) setArchiveMsg(false);
  }, [otherMessages]);

  const handleBlockClose = async () => {
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  const archive = async () => {
    await props.archiveChat({ chat_conversation_id: props.chatId });
    navigate(ROUTES.MESSAGES);
  };

  return (
    <>
      <ul className="dropdown-menu">
        <li>
          <p
            className="dropdown-item cursor-pointer"
            onClick={() => setOptions((prev) => ({ ...prev, block: true }))}
          >
            {props.otherUser?.is_blocked ? "Unblock User" : "Block User"}
          </p>
        </li>
        {!props?.otherUser?.is_blocked && (
          <>
            <li>
              <p
                className="dropdown-item cursor-pointer"
                onClick={() => props.handleRemovePermissions()}
              >
                Remove Permissions
              </p>
            </li>
            <li>
              <p
                className="dropdown-item cursor-pointer"
                onClick={() =>
                  setOptions((prev) => ({ ...prev, report: true }))
                }
              >
                Report Member
              </p>
            </li>
            {!!props?.chatId && (
              <li>
                <p
                  className="dropdown-item cursor-pointer"
                  onClick={() => archive()}
                >
                  {`${archiveMsg ? "Archive" : "Unarchive"} Conversation`}
                </p>
              </li>
            )}
          </>
        )}
      </ul>
      {options?.block && (
        <BlockUser closeModal={handleBlockClose} userId={props.otherUser.id} />
      )}
      {options?.report && (
        <ReportUser
          closeModalReport={handleBlockClose}
          otherMemberProfile={props.otherUser}
        />
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  archiveChat: (payload: any) => dispatch(MessagesAction.archiveChat(payload)),
});

interface MapDispatchToProps {
  archiveChat: (payload: any) => void;
}

export default connect(null, mapDispatchToProps)(ChatOptions);
