import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faFacebook,
  faTwitter,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import { neuDaddyLogo } from "../../assets/images";
import "./style.css";
import ROUTES from "../../constants/routes";

const UnAuthFooter = () => {
  return (
    <footer className="footer-sec">
      <div className="container">
        <div className="footer-logo">
          <div className="l-sec">
            <img src={neuDaddyLogo} alt="" className="img-fluid me-2" />
            NeuDaddy
          </div>
          <div className="s-icon">
            <FontAwesomeIcon icon={faFacebook} />
            <FontAwesomeIcon icon={faTwitter} />
            <FontAwesomeIcon icon={faLinkedin} />
            <FontAwesomeIcon icon={faInstagram} />
          </div>
        </div>
        <ul className="nav nav-footer pt-3 pb-3 inner-footer mt-3">
          <li>
            <a href={ROUTES.HOME}>Home</a>
          </li>
          <li>
            <a href={ROUTES.ABOUTUS}>About</a>
          </li>
          <li>
            <a href={ROUTES.CONTACTUS}>Contact</a>
          </li>
          <li>
            <a href={ROUTES.PRIVACYP}>Privacy</a>
          </li>
          <li>
            <a href={ROUTES.TERMCONDITIONS}>Terms and Conditions</a>
          </li>
        </ul>
        <p className="foot-p">
          It is a long established fact that a reader will be distracted by the
          readable content of a page when looking at its layout.
        </p>
        <p className="powered-by">© 2023 NeuDaddy | Powered by NeuDaddy</p>
      </div>
    </footer>
  );
};

export default UnAuthFooter;
