import { MemberReducer } from "../../interfaces/reducer";
import { ACTIONS } from "../actions";

const initialState: MemberReducer = {
  newestMembers: null,
  nearestMembers: null,
  recentActiveMembers: null,
  searchActive: false,
  filteredMembers: false,
  filteredMembersList: [],
  filteredMembersLimit: 0,
  filteredMembersLastPage: 0,
  viewedMembers: null,
  favouriteMembers: null,
  favouriteMeMembers: null,
  memberFilters: {
    page: 0,
  },
  membersLoading: false,
  otherMemberProfile: {},
};

const memberReducer = (
  state = initialState,
  action: { type: string } & any
) => {
  if (action.type === ACTIONS.SET_NEWEST_MEMBERS) {
    return {
      ...state,
      newestMembers: action.members,
    };
  } else if (action.type === ACTIONS.SET_NEAREST_MEMBERS) {
    return {
      ...state,
      nearestMembers: action.members,
    };
  } else if (action.type === ACTIONS.SET_RECENT_ACTIVE_MEMBERS) {
    return {
      ...state,
      recentActiveMembers: action.members,
    };
  } else if (action.type === ACTIONS.SET_SEARCH) {
    return {
      ...state,
      searchActive: action.search,
    };
  } else if (action.type === ACTIONS.SET_FILTERED) {
    return {
      ...state,
      filteredMembers: action.filtered,
    };
  } else if (action.type === ACTIONS.SET_FILTERED_MEMBERS) {
    return {
      ...state,
      filteredMembersList: action.members,
      filteredMembersLimit: action.limit,
      filteredMembersLastPage: action.lastPage,
    };
  } else if (action.type === ACTIONS.SET_VIEWED_MEMBERS) {
    return {
      ...state,
      viewedMembers: action.members,
    };
  } else if (action.type === ACTIONS.SET_FAVOURITE_MEMBERS) {
    return {
      ...state,
      favouriteMembers: action.members,
    };
  } else if (action.type === ACTIONS.SET_FAVOURITEME_MEMBERS) {
    return {
      ...state,
      favouriteMeMembers: action.members,
    };
  } else if (action.type === ACTIONS.SET_FILTERS_OPTIONS) {
    return {
      ...state,
      memberFilters: action.filter,
    };
  } else if (action.type === ACTIONS.SET_MEMBER_LOADING) {
    return {
      ...state,
      membersLoading: action.payload,
    };
  } else if (action.type === ACTIONS.SET_OTHERMEMBER_PROFILE) {
    return {
      ...state,
      otherMemberProfile: action.member,
    };
  }
  return state;
};

export default memberReducer;
