import { CommonReducer } from "../../interfaces/reducer";
import { ACTIONS } from "../actions";
import { initializeSocket } from "../../Socket";
import { THEME } from "../../utils/constants";

const initialState: CommonReducer = {
  height: [],
  bodyType: [],
  ethnicity: [],
  education: [],
  relationshipStatus: [],
  childrenStatus: [],
  smokerStatus: [],
  drinkerStatus: [],
  datingTags: [],
  gender: [],
  hair: [],
  netWorth: [],
  annualIncome: [],
  occupation: [],
  lookingFor: [],
  interests: [],
  socket: {},
  activeHeaderOption: "",
  termsAndConditions: "",
  aboutUs: "",
  privacyPolicy: "",
  theme: localStorage.getItem(THEME.THEME) || THEME.LIGHT,
};
export interface SetPrivacyPolicyAction {
  type: typeof ACTIONS.SET_PRIVACYPOLICY;
  text: string;
}

const commonReducer = (
  state = initialState,
  action: { type: string } & any
) => {
  if (action.type === ACTIONS.SET_STATIC_DATA) {
    return {
      ...state,
      height: action.data.HEIGHT,
      bodyType: action.data.BODY_TYPE,
      datingTags: action.data.DATING_TAGS,
      ethnicity: action.data.ETHNICITY,
      education: action.data.EDUCATION,
      relationshipStatus: action.data.RELATIONSHIP_STATUS,
      childrenStatus: action.data.CHILDREN_STATUS,
      smokerStatus: action.data.SMOKER_STATUS,
      drinkerStatus: action.data.DRINKER_STATUS,
      gender: action.data.GENDER,
      hair: action.data.HAIR_COLOR,
      netWorth: action.data.NET_WORTH,
      annualIncome: action.data.ANNUAL_INCOME,
      occupation: action.data.OCCUPATION,
      lookingFor: action.data.LOOKING_FOR,
      interests: action.data.WHO_YOU_ARE_INTERESTED_IN_MEETING,
    };
  } else if (action.type === ACTIONS.SET_DATING_TAGS) {
    return {
      ...state,
      datingTags: action.data,
    };
  } else if (action.type === ACTIONS.INITIALIZE_SOCKET) {
    const senderId: Number = action.senderId;
    return { ...state, socket: initializeSocket(senderId) };
  } else if (action.type === ACTIONS.SET_HEADER_OPTION) {
    return { ...state, activeHeaderOption: action.option };
  } else if (action.type === ACTIONS.SET_THEME) {
    localStorage.setItem(THEME.THEME, action.theme);
    return { ...state, theme: action.theme };
  } else if (action.type === ACTIONS.SET_TERMSANDCONDITION) {
    return { ...state, termsAndConditions: action.text };
  } else if (action.type === ACTIONS.SET_ABOUT_US) {
    return { ...state, aboutUs: action.text };
  } else if (action.type === ACTIONS.SET_PRIVACYPOLICY) {
    return { ...state, privacyPolicy: action.text };
  }

  return state;
};

export default commonReducer;
