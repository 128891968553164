import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import { useFormik } from "formik";
import "../../style.css";
import { ACTIONS } from "../../../../../redux/actions";
import { RootReducer } from "../../../../../interfaces/reducer";
import {
  GENERAL_DETAILS_STEP,
  SIGNUP_STEPS,
} from "../../../../../utils/constants";
import { toast } from "react-toastify";

const SeekingInterestDetails = () => {
  const dispatch = useDispatch();
  const { datingTags } = useSelector((state: RootReducer) => state.common);
  const { interestsId } = useSelector(
    (state: RootReducer) => state.auth.signupValues
  );

  // Set the first tag as selected by default
  const defaultSelectedTag = datingTags[0]?.id;
  const [interests, setInterests] = useState<Number[]>(
    interestsId.length > 0 ? interestsId : [defaultSelectedTag]
  );

  const interestDetailsFormik = useFormik({
    initialValues: {
      interestsId: interestsId.length > 0 ? interestsId : [defaultSelectedTag],
    },
    onSubmit: (values) => {
      dispatch({ type: ACTIONS.SET_SIGNUP_VALUES, values });
      dispatch({
        type: ACTIONS.SET_SIGNUP_STEP,
        step: SIGNUP_STEPS.PREVIEW_CROPPED_PROFILE_PHOTO,
        generalStep: GENERAL_DETAILS_STEP.ADD_ME_DETAILS,
      });
    },
  });

  const handleInterestClick = (item: { id: number }) => {
    const interestIndex = interests.indexOf(item.id);
    if (interestIndex === -1) {
      if (interests.length >= 8) {
        toast.error("You can select a maximum of 8 interests.");
        return;
      }
      const updatedInterests = [
        ...interestDetailsFormik.values.interestsId,
        item.id,
      ];
      interestDetailsFormik.setFieldValue("interestsId", updatedInterests);
      setInterests(updatedInterests);
    } else {
      const updatedInterests = interestDetailsFormik.values.interestsId.filter(
        (id) => id !== item.id
      );
      interestDetailsFormik.setFieldValue("interestsId", updatedInterests);
      setInterests(updatedInterests);
    }
  };

  return (
    <div className="modal-body px-0 pb-0">
      <div className="accordion-body">
        <ul>
          {datingTags.map((item) => (
            <li
              onClick={() => handleInterestClick(item)}
              key={item.id}
              className={
                interests.indexOf(item.id) === -1
                  ? "cursor-pointer"
                  : "active cursor-pointer"
              }
            >
              {item.tag_name}
            </li>
          ))}
        </ul>
      </div>
      {/* <div className="left-rotare-btn show-btns"> */}
        <button className="left-rotare-btn show-btns btn-public" onClick={() => interestDetailsFormik.submitForm()}>
          Continue
        </button>
      {/* </div> */}
      <button
        className="btn_back"
        onClick={() =>
          dispatch({
            type: ACTIONS.SET_SIGNUP_STEP,
            step: SIGNUP_STEPS.PREVIEW_CROPPED_PROFILE_PHOTO,
            generalStep: GENERAL_DETAILS_STEP.ADD_PROFESSIONAL_DETAILS,
          })
        }
      >
        <i className="fas fa-arrow-left"></i> Back
      </button>
    </div>
  );
};

export default SeekingInterestDetails;
