import React from "react";
import { Modal, Button } from "react-bootstrap"; // Assuming you're using react-bootstrap
import { connect } from "react-redux";
import { AppDispatch } from "../../../redux/store";
import { AuthAction } from "../../../redux/thunk";

interface Props extends MapDispatchToProps {
  isOpen: boolean;
  onClose: () => void;
}

const LogoutComponent: React.FC<Props> = (props) => {
  const handleLogout = () => {
    props.logout();
    props.onClose();
  };

  return (
    <div>
      <Modal show={props.isOpen} onHide={props.onClose}>
        <Modal.Header closeButton>
          <Modal.Title>Logout</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to log out?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onClose}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleLogout}>
            Logout
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  logout: () => dispatch(AuthAction.logout()),
});

interface MapDispatchToProps {
  logout: () => void;
}

export default connect(null, mapDispatchToProps)(LogoutComponent);
