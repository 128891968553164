import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import "../../style.css";
import { RootReducer } from "../../../../../interfaces/reducer";
import { ACTIONS } from "../../../../../redux/actions";
import {
  GENERAL_DETAILS_STEP,
  SIGNUP_STEPS,
} from "../../../../../utils/constants";
import { BodyDetailsSchema } from "../../../../../schemas";
import { useState } from "react";
import MultiRangeSlider from "../../../../MultiRangeSlider";

const BodyDetails = () => {
  const dispatch = useDispatch();
  const { bodyType, ethnicity, gender, hair } = useSelector(
    (state: RootReducer) => state.common
  );
  const {
    bodyType: selectedBT,
    hair: selectedHair,
    height: selectedHt,
    gender: selectedGender,
    ethnicity: selectedEth,
    fromAge: selectedFromAge,
    toAge: selectedToAge,
    looking_for: selectedLookingFor,
  } = useSelector((state: RootReducer) => state.auth.signupValues);

  const [showErrors, setShowErrors] = useState(false);
  const [openAccordion, setOpenAccordion] = useState("collapseZero");
  const [bodyDetails, setBodyDetails] = useState({
    inch: "",
    bodyName: "",
    ethnicityName: "",
    gender: "",
    looking_for: "",
    hair: "",
  });
  const [selectedHeight, setSelectedHeight] = useState(+selectedHt || 0);
  const [fromAge, setFromAge] = useState<Number>(selectedFromAge || 18);
  const [toAge, setToAge] = useState<Number>(selectedToAge || 60);

  const bodyDetailsFormik = useFormik({
    initialValues: {
      height: selectedHt || 0,
      bodyType: selectedBT || 0,
      ethnicity: selectedEth || 0,
      gender: selectedGender || 0,
      hair: selectedHair || 0,
      looking_for: selectedLookingFor || 1,
    },
    onSubmit: (values) => {
      dispatch({ type: ACTIONS.SET_SIGNUP_VALUES, values });
      dispatch({
        type: ACTIONS.SET_SIGNUP_STEP,
        step: SIGNUP_STEPS.PREVIEW_CROPPED_PROFILE_PHOTO,
        generalStep: GENERAL_DETAILS_STEP.ADD_PROFESSIONAL_DETAILS,
      });
    },
    validationSchema: BodyDetailsSchema,
  });

  const handleSubmit = () => {
    setShowErrors(true);
    bodyDetailsFormik.submitForm();
  };

  const handleAccordionClick = (accordionId: string) => {
    setOpenAccordion(openAccordion === accordionId ? "" : accordionId);
  };

  const handleAgeChange = (ages: [Number, Number]) => {
    bodyDetailsFormik.setFieldValue("fromAge", ages[0]);
    bodyDetailsFormik.setFieldValue("toAge", ages[1]);
    setFromAge(ages[0]);
    setToAge(ages[1]);
  };

  return (
    <div className="modal-body px-0 pb-0">
      <h6 className="looks-great">What do you look like?</h6>
      <div className="accordian-page">
        <div className="accordion" id="accordionExample">
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingZero">
              <button
                className={`accordion-button ${
                  openAccordion === "collapseZero" ? "" : "collapsed"
                }`}
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseZero"
                aria-expanded={openAccordion === "collapseZero"}
                aria-controls="collapseZero"
                onClick={() => handleAccordionClick("collapseZero")}
              >
                Gender
                {showErrors && bodyDetailsFormik.errors.gender ? (
                  <span className="text-danger">
                    {bodyDetailsFormik.errors.gender}
                  </span>
                ) : (
                  <></>
                )}
              </button>
            </h2>
            <div
              id="collapseZero"
              className={`accordion-collapse collapse ${
                openAccordion === "collapseZero" ? "show" : ""
              }`}
              aria-labelledby="headingZero"
            >
              <div className="accordion-body">
                <ul>
                  {gender.map((item) => (
                    <li
                      onClick={() => {
                        // Set the gender value
                        bodyDetailsFormik.setFieldValue("gender", item.id);
                        setBodyDetails({ ...bodyDetails, gender: item.name });
                        // Automatically set "Looking For" based on selected gender
                        const lookingForId = item.id === 1 ? 2 : 1;
                        bodyDetailsFormik.setFieldValue(
                          "looking_for",
                          lookingForId
                        );
                      }}
                      key={item.id}
                      className={
                        bodyDetailsFormik.values.gender === item.id
                          ? "active"
                          : ""
                      }
                    >
                      {item.name}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingLookingFor">
              <button
                className={`accordion-button ${
                  openAccordion === "collapseLookingFor" ? "" : "collapsed"
                }`}
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseLookingFor"
                aria-expanded={openAccordion === "collapseLookingFor"}
                aria-controls="collapseLookingFor"
                onClick={() => handleAccordionClick("collapseLookingFor")}
              >
                Looking For
              </button>
            </h2>
            <div
              id="collapseLookingFor"
              className={`accordion-collapse collapse ${
                openAccordion === "collapseLookingFor" ? "show" : ""
              }`}
              aria-labelledby="headingLookingFor"
            >
              <div className="accordion-body">
                <ul>
                  {gender.map((item) => (
                    <li
                      key={item.id}
                      className={
                        bodyDetailsFormik.values.looking_for === item.id
                          ? "active"
                          : ""
                      }
                    >
                      {item.name}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingOnee">
              <button
                className={`accordion-button ${
                  openAccordion === "collapseOne" ? "" : "collapsed"
                }`}
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded={openAccordion === "collapseOne"}
                aria-controls="collapseOne"
                onClick={() => handleAccordionClick("collapseOne")}
              >
                Hair
                {showErrors && bodyDetailsFormik.errors.hair ? (
                  <span className="text-danger">
                    {bodyDetailsFormik.errors.hair}
                  </span>
                ) : (
                  // <span>{bodyDetails.hair}</span>
                  <></>
                )}
              </button>
            </h2>
            <div
              id="collapseOne"
              className={`accordion-collapse collapse ${
                openAccordion === "collapseOne" ? "show" : ""
              }`}
              aria-labelledby="headingOnee"
            >
              <div className="accordion-body">
                <ul>
                  {hair.map((item) => (
                    <li
                      onClick={() => {
                        bodyDetailsFormik.setFieldValue("hair", item.id);
                        setBodyDetails({ ...bodyDetails, hair: item.name });
                      }}
                      key={item.id}
                      className={
                        bodyDetailsFormik.values.hair === item.id
                          ? "active"
                          : ""
                      }
                    >
                      {item.name}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingThree">
              <button
                className={`accordion-button ${
                  openAccordion === "collapseThree" ? "" : "collapsed"
                }`}
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree"
                aria-expanded={openAccordion === "collapseThree"}
                aria-controls="collapseThree"
                onClick={() => handleAccordionClick("collapseThree")}
              >
                Height
                {showErrors && bodyDetailsFormik.errors.height ? (
                  <span className="text-danger">
                    {bodyDetailsFormik.errors.height}
                  </span>
                ) : (
                  <></>
                )}
              </button>
            </h2>
            <div
              id="collapseThree"
              className={`accordion-collapse collapse ${
                openAccordion === "collapseThree" ? "show" : ""
              }`}
              aria-labelledby="headingThree"
            >
              <div className="accordion-body">
                <div className="distance my-1">
                  <span className="d-flex align-items-center my-3">
                    <h6>
                      <span>
                        {+selectedHeight === 213 ? "213+" : selectedHeight}
                        cm(s)
                      </span>
                    </h6>
                  </span>
                  <input
                    className="w-100"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setSelectedHeight(+e.target.value);
                      bodyDetailsFormik.setFieldValue("height", e.target.value);
                    }}
                    value={selectedHeight} // Set the input value to reflect the selected height
                    min={134}
                    max={213}
                    type="range"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingPreference">
              <button
                className={`accordion-button ${
                  openAccordion === "collapsePreference" ? "" : "collapsed"
                }`}
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapsePreference"
                aria-expanded={openAccordion === "collapsePreference"}
                aria-controls="collapsePreference"
                onClick={() => handleAccordionClick("collapsePreference")}
              >
                Preference Age
              </button>
            </h2>
            <div
              id="collapsePreference"
              className={`accordion-collapse collapse ${
                openAccordion === "collapsePreference" ? "show" : ""
              }`}
              aria-labelledby="headingThree"
            >
              <div className="accordion-body">
                <div className="distance my-1">
                  <span className="d-flex align-items-center my-3">
                    <h6>
                      <span>
                        {String(fromAge)} - {String(toAge)}
                      </span>
                    </h6>
                  </span>
                  <MultiRangeSlider
                    min={18}
                    max={60}
                    handleSlideChange={handleAgeChange}
                    defaultMax={Number(toAge)}
                    defaultMin={Number(fromAge)}
                    step={1}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingFour">
              <button
                className={`accordion-button ${
                  openAccordion === "collapseFour" ? "" : "collapsed"
                }`}
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFour"
                aria-expanded={openAccordion === "collapseFour"}
                aria-controls="collapseFour"
                onClick={() => handleAccordionClick("collapseFour")}
              >
                Body Type
                {showErrors && bodyDetailsFormik.errors.bodyType ? (
                  <span className="text-danger">
                    {bodyDetailsFormik.errors.bodyType}
                  </span>
                ) : (
                  // <span>{bodyDetails.bodyName}</span>
                  <></>
                )}
              </button>
            </h2>
            <div
              id="collapseFour"
              className={`accordion-collapse collapse ${
                openAccordion === "collapseFour" ? "show" : ""
              }`}
              aria-labelledby="headingFour"
            >
              <div className="accordion-body">
                <ul>
                  {bodyType.map((item) => (
                    <li
                      onClick={() => {
                        bodyDetailsFormik.setFieldValue("bodyType", item.id);
                        setBodyDetails({ ...bodyDetails, bodyName: item.name });
                      }}
                      key={item.id}
                      className={
                        bodyDetailsFormik.values.bodyType === item.id
                          ? "active"
                          : ""
                      }
                    >
                      {item.name}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingFive">
              <button
                className={`accordion-button ${
                  openAccordion === "collapseFive" ? "" : "collapsed"
                }`}
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFive"
                aria-expanded={openAccordion === "collapseFive"}
                aria-controls="collapseFive"
                onClick={() => handleAccordionClick("collapseFive")}
              >
                Ethnicity
                {showErrors && bodyDetailsFormik.errors.ethnicity ? (
                  <span className="text-danger">
                    {bodyDetailsFormik.errors.ethnicity}
                  </span>
                ) : (
                  // <span>{bodyDetails.ethnicityName}</span>
                  <></>
                )}
              </button>
            </h2>
            <div
              id="collapseFive"
              className={`accordion-collapse collapse ${
                openAccordion === "collapseFive" ? "show" : ""
              }`}
              aria-labelledby="headingFive"
            >
              <div className="accordion-body">
                <ul>
                  {ethnicity.map((item) => (
                    <li
                      onClick={() => {
                        bodyDetailsFormik.setFieldValue("ethnicity", item.id);
                        setBodyDetails({
                          ...bodyDetails,
                          ethnicityName: item.name,
                        });
                      }}
                      key={item.id}
                      className={
                        bodyDetailsFormik.values.ethnicity === item.id
                          ? "active"
                          : ""
                      }
                    >
                      {item.name}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="left-rotare-btn show-btns"> */}
        <button className="left-rotare-btn show-btns btn-public mx-auto" type="button" onClick={handleSubmit}>
          Continue
        </button>
      {/* </div> */}
      <button
        className="btn_back"
        onClick={() =>
          dispatch({
            type: ACTIONS.SET_SIGNUP_STEP,
            step: SIGNUP_STEPS.PREVIEW_CROPPED_PROFILE_PHOTO,
            generalStep: GENERAL_DETAILS_STEP.ADD_LOCATION,
          })
        }
      >
        <i className="fas fa-arrow-left"></i> back
      </button>
    </div>
  );
};

export default BodyDetails;
