import * as Yup from "yup";

export const SignUpPersonalDetailsSchema = Yup.object().shape({
  email: Yup.string().email("Invalid Email").required("Required"),
  mobileNumber: Yup.string().required("Required"),
  password: Yup.string()
    .required("Required")
    .min(8, "Password must be at least 8 characters"),
  retype_pass: Yup.string()
    .oneOf([Yup.ref("password")], "Password mismatch")
    .nullable()
    .required("Required"),
  dob: Yup.string().required("Required"),
});
export const SignUpPersonalDetailsWithoutPasswordSchema = Yup.object().shape({
  email: Yup.string().email("Invalid Email").required("Required"),
  mobileNumber: Yup.string().required("Required"),
  dob: Yup.string().required("Required"),
});
