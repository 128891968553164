import { UserReducer } from "../../interfaces/reducer";
import { ACTIONS } from "../actions";

const initialState: UserReducer = {
    profile: {},
    editProfileModal: '',
};

const userReducer = (state = initialState, action: { type: string } & any) => {
    if(action.type === ACTIONS.SET_PROFILE_DETAILS) {
        return {
            ...state,
            profile: action.profile,
        };
    }
    else if(action.type === ACTIONS.SET_PROFILE_MODAL) {
        return {
            ...state,
            editProfileModal: action.profileModal,
        };
    }
    return state;
};

export default userReducer;