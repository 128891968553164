import "./style.css";
import Header from "../../components/Header";
import UnAuthFooter from "../Home/footer";
import AboutUs from "../../components/CMS/AboutUs";

const AboutUsLogoutPage = () => {
  return (
    <div>
      <Header />
      <AboutUs />
      <UnAuthFooter />
    </div>
  );
};

export default AboutUsLogoutPage;
