/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { MessageRequestPayload, RootReducer } from "../../interfaces/reducer";
import { useFormik } from "formik";
import MultiRangeSlider from "../MultiRangeSlider";
import { CommonAction, MessagesAction } from "../../redux/thunk";
import { AppDispatch } from "../../redux/store";
import Loader from "../Loader/Spinner";

interface Props extends MapDispatchToProps {}

const ChatFilters = (props: Props) => {
  const statics = useSelector((state: RootReducer) => state.common);
  const [fromAge, setFromAge] = useState("18");
  const [toAge, setToAge] = useState("59");
  const [fromHt, setFromHt] = useState("134");
  const [toHt, setToHt] = useState("213");
  const [distance, setDistance] = useState("1000");
  const [loader, setLoader] = useState(false);
  const [showToggle, setShowToggle] = useState(false);

  const initValues = {
    distance: "1000",
    from_age: "",
    to_age: "",
    from_height: "",
    to_height: "",
    weight: 0,
    body_type: [],
    hair: [],
    ethnicity: [],
    is_smoker: [],
    is_drinker: [],
    education: [],
    dating_tags: [],
    dating_tags_not_to_show: [],
    relationship_status: [],
    has_photos: 0,
    viewed_me: 0,
    viewed: 0,
    unviewed_me: 0,
    is_recent_active: 0,
    favourited: 0,
    favourited_me: 0,
  };

  useEffect(() => {
    handleInitialState();
  }, []);

  const handleInitialState = async () => {
    setLoader(true);
    await props.staticList();
    await props.addFilterForMessages(initValues);
    await props.getFilterMessages({ page: 1 });
    setLoader(false);
  };

  const handleAge = (ages: any) => {
    searchFormik.values.from_age = String(ages[0]);
    searchFormik.values.to_age = String(ages[1]);
    setFromAge(ages[0]);
    setToAge(ages[1]);
  };

  const handleHeight = (heights: any) => {
    searchFormik.values.from_height = String(heights[0]);
    searchFormik.values.to_height = String(heights[1]);
    setFromHt(heights[0]);
    setToHt(heights[1]);
  };

  const searchFormik = useFormik({
    initialValues: initValues,
    onSubmit: async (values) => {
      setLoader(true);
      await props.addFilterForMessages(values);
      await props.getFilterMessages({ page: 1 });
      setLoader(false);
    },
  });

  const [openAccordion, setOpenAccordion] = useState("collapseTwo");

  const handleAccordionClick = (id: any) => {
    setOpenAccordion(openAccordion === id ? "" : id);
  };

  const handleResetAll = async () => {
    window.location.reload();
  };

  return (
    <>
      <button
        type="button"
        onClick={() => setShowToggle((prev) => !prev)}
        className="btn btn-filter chat-btn-filter"
      >
        <i className="fas fa-sort-amount-down"></i>
      </button>
      <div
        className={`search_top border-ll filter_side ${
          showToggle ? "filter-toggle" : ""
        }`}
      >
        {loader && <Loader />}
        <div className="reset_search p-1 d-flex justify-content-between align-items-center">
          <span onClick={() => setShowToggle(false)} className="btn-cross">
            <i className="fa fa-times" aria-hidden="true"></i>
          </span>
          <button
            onClick={() => {
              searchFormik.submitForm();
            }}
            className="border-0 text-white"
          >
            Search
          </button>
          <h6 onClick={handleResetAll}>Reset All</h6>
        </div>
        <hr />
        <div className="overflow_top pb-4" id="style-2">
          <h5>Distance</h5>
          <div className="distance mt-2 mb-4">
            <span className="d-flex align-items-center my-3">
              <h6>
                0 <span>-</span>
                <span>{+distance === 1000 ? "1000+" : distance} Km</span>
              </h6>
            </span>
            <input
              className="w-100"
              onChange={(e) => {
                setDistance(e.target.value);
                searchFormik.setFieldValue("distance", e.target.value);
              }}
              defaultValue={1000}
              min={0}
              max={1000}
              type="range"
            />
          </div>
          <hr />
          <h5>Options</h5>
          <div className="option_location mt-2 mb-4">
            <label className="search_location">
              Online now
              <input
                onChange={(e) => {
                  if (e.target.checked) {
                    searchFormik.setFieldValue("is_recent_active", 1);
                  } else searchFormik.setFieldValue("is_recent_active", 0);
                }}
                type="checkbox"
                name="Photos"
                checked={searchFormik.values.is_recent_active === 1}
              />
              <span className="checkmark"></span>
            </label>
            <label className="search_location">
              Photos
              <input
                onChange={(e) => {
                  if (e.target.checked)
                    searchFormik.setFieldValue("has_photos", 1);
                  else searchFormik.setFieldValue("has_photos", 0);
                }}
                type="checkbox"
                name="Photos"
                checked={searchFormik.values.has_photos === 1}
              />
              <span className="checkmark"></span>
            </label>
            <label className="search_location">
              Viewed Me
              <input
                onChange={(e) => {
                  if (e.target.checked)
                    searchFormik.setFieldValue("viewed_me", 1);
                  else searchFormik.setFieldValue("viewed_me", 0);
                }}
                type="checkbox"
                name="Viewed"
                checked={searchFormik.values.viewed_me === 1}
              />
              <span className="checkmark"></span>
            </label>
            <label className="search_location">
              Unviewed
              <input
                onChange={(e) => {
                  if (e.target.checked) {
                    searchFormik.setFieldValue("unviewed_me", 1);
                    searchFormik.setFieldValue("viewed", 0);
                  } else searchFormik.setFieldValue("unviewed_me", 0);
                }}
                type="checkbox"
                name="Viewed"
                checked={searchFormik.values.unviewed_me === 1}
              />
              <span className="checkmark"></span>
            </label>
            <label className="search_location">
              Viewed
              <input
                onChange={(e) => {
                  if (e.target.checked) {
                    searchFormik.setFieldValue("viewed", 1);
                  } else {
                    searchFormik.setFieldValue("viewed", 0);
                  }
                  if (!searchFormik.values.viewed) {
                    searchFormik.setFieldValue("unviewed_me", 0);
                  }
                }}
                type="checkbox"
                name="Viewed"
                checked={!!searchFormik.values.viewed}
              />
              <span className="checkmark"></span>
            </label>
            <label className="search_location">
              My Favourite
              <input
                onChange={(e) => {
                  if (e.target.checked) {
                    searchFormik.setFieldValue("favourited", 1);
                  } else {
                    searchFormik.setFieldValue("favourited", 0);
                  }
                }}
                type="checkbox"
                name="Favourite"
                checked={searchFormik.values.favourited === 1}
              />
              <span className="checkmark"></span>
            </label>
            <label className="search_location">
              Favourited me
              <input
                onChange={(e) => {
                  if (e.target.checked) {
                    searchFormik.setFieldValue("favourited_me", 1);
                  } else {
                    searchFormik.setFieldValue("favourited_me", 0);
                  }
                }}
                type="checkbox"
                name="Favourite"
                checked={searchFormik.values.favourited_me === 1}
              />
              <span className="checkmark"></span>
            </label>
          </div>
          <hr />
          <div className="accordion" id="accordion">
            {/* Body Type */}
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingTwo">
                <button
                  className={`accordion-button ${
                    openAccordion === "collapseTwo" ? "" : "collapsed"
                  }`}
                  type="button"
                  onClick={() => handleAccordionClick("collapseTwo")}
                  aria-expanded={openAccordion === "collapseTwo"}
                  aria-controls="collapseTwo"
                >
                  Body Type
                </button>
              </h2>
              <div
                id="collapseTwo"
                className={`accordion-collapse collapse ${
                  openAccordion === "collapseTwo" ? "show" : ""
                }`}
                aria-labelledby="headingTwo"
              >
                <div className="accordion-body">
                  <div className="option_location mt-2 mb-4">
                    {statics.bodyType.map((bt) => (
                      <label key={bt.id} className="search_location">
                        {bt.name}
                        <input
                          onChange={(e) => {
                            const updatedValues = e.target.checked
                              ? [...searchFormik.values.body_type, bt.id]
                              : searchFormik.values.body_type.filter(
                                  (id) => id !== bt.id
                                );
                            searchFormik.setFieldValue(
                              "body_type",
                              updatedValues
                            );
                          }}
                          type="checkbox"
                          name={bt.name}
                          checked={(
                            searchFormik.values.body_type as any
                          ).includes(bt.id)}
                        />
                        <span className="checkmark"></span>
                      </label>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <hr />
            {/* Age */}
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingThree">
                <button
                  className={`accordion-button ${
                    openAccordion === "collapseThree" ? "" : "collapsed"
                  }`}
                  type="button"
                  onClick={() => handleAccordionClick("collapseThree")}
                  aria-expanded={openAccordion === "collapseThree"}
                  aria-controls="collapseThree"
                >
                  Age
                </button>
              </h2>
              <div
                id="collapseThree"
                className={`accordion-collapse collapse ${
                  openAccordion === "collapseThree" ? "show" : ""
                }`}
                aria-labelledby="headingThree"
              >
                <div className="accordion-body">
                  <span className="d-flex align-items-center m-3">
                    {fromAge}-<h6>{+toAge === 60 ? "60+" : toAge}</h6>
                  </span>
                  <MultiRangeSlider
                    min={18}
                    max={60}
                    defaultMin={18}
                    defaultMax={60}
                    step={1}
                    handleSlideChange={handleAge}
                  />
                </div>
              </div>
            </div>
            <hr />
            {/* Height */}
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingHeight">
                <button
                  className={`accordion-button ${
                    openAccordion === "collapseHeight" ? "" : "collapsed"
                  }`}
                  type="button"
                  onClick={() => handleAccordionClick("collapseHeight")}
                  aria-expanded={openAccordion === "collapseHeight"}
                  aria-controls="collapseHeight"
                >
                  Height
                </button>
              </h2>
              <div
                id="collapseHeight"
                className={`accordion-collapse collapse ${
                  openAccordion === "collapseHeight" ? "show" : ""
                }`}
                aria-labelledby="headingHeight"
              >
                <div className="accordion-body">
                  <span className="d-flex align-items-center m-3">
                    {fromHt}-<h6>{+toHt === 213 ? "213+" : toHt}</h6>
                  </span>
                  <MultiRangeSlider
                    min={134}
                    max={213}
                    defaultMin={134}
                    defaultMax={213}
                    step={1}
                    handleSlideChange={handleHeight}
                  />
                </div>
              </div>
            </div>
            <hr />
            {/* Hair Color */}
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingFour">
                <button
                  className={`accordion-button ${
                    openAccordion === "collapseFour" ? "" : "collapsed"
                  }`}
                  type="button"
                  onClick={() => handleAccordionClick("collapseFour")}
                  aria-expanded={openAccordion === "collapseFour"}
                  aria-controls="collapseFour"
                >
                  Hair color
                </button>
              </h2>
              <div
                id="collapseFour"
                className={`accordion-collapse collapse ${
                  openAccordion === "collapseFour" ? "show" : ""
                }`}
                aria-labelledby="headingFour"
              >
                <div className="accordion-body">
                  <div className="option_location mt-2 mb-4">
                    {statics.hair.map((hair) => (
                      <label key={hair.id} className="search_location">
                        {hair.name}
                        <input
                          onChange={(e) => {
                            const updatedValues = e.target.checked
                              ? [...searchFormik.values.hair, hair.id]
                              : searchFormik.values.hair.filter(
                                  (id) => id !== hair.id
                                );
                            searchFormik.setFieldValue("hair", updatedValues);
                          }}
                          type="checkbox"
                          name={hair.name}
                          checked={(searchFormik.values.hair as any).includes(
                            hair.id
                          )}
                        />
                        <span className="checkmark"></span>
                      </label>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <hr />
            {/* Smoking */}
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingSmoking">
                <button
                  className={`accordion-button ${
                    openAccordion === "collapseSmoking" ? "" : "collapsed"
                  }`}
                  type="button"
                  onClick={() => handleAccordionClick("collapseSmoking")}
                  aria-expanded={openAccordion === "collapseSmoking"}
                  aria-controls="collapseSmoking"
                >
                  Smoking
                </button>
              </h2>
              <div
                id="collapseSmoking"
                className={`accordion-collapse collapse ${
                  openAccordion === "collapseSmoking" ? "show" : ""
                }`}
                aria-labelledby="headingSmoking"
              >
                <div className="accordion-body">
                  <div className="option_location mt-4 mb-4">
                    {statics.smokerStatus.map((sm) => {
                      return (
                        <label key={sm.id} className="search_location">
                          {sm.name}
                          <input
                            onChange={(e) => {
                              if (e.target.checked) {
                                searchFormik.setFieldValue("is_smoker", [
                                  ...searchFormik.values.is_smoker,
                                  sm.id,
                                ]);
                              } else {
                                const id: any = [
                                  ...searchFormik.values.is_smoker,
                                ];
                                const index = id.indexOf(sm.id);
                                if (index > -1) {
                                  id.splice(index, 1);
                                }

                                searchFormik.setFieldValue("is_smoker", id);
                              }
                            }}
                            type="checkbox"
                            name={sm.name}
                            checked={(
                              searchFormik.values.is_smoker as any
                            ).includes(sm.id)}
                          />
                          <span className="checkmark"></span>
                        </label>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            <hr />
            {/* Drinking */}
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingDrinking">
                <button
                  className={`accordion-button ${
                    openAccordion === "collapseDrinking" ? "" : "collapsed"
                  }`}
                  type="button"
                  onClick={() => handleAccordionClick("collapseDrinking")}
                  aria-expanded={openAccordion === "collapseDrinking"}
                  aria-controls="collapseDrinking"
                >
                  Drinking
                </button>
              </h2>
              <div
                id="collapseDrinking"
                className={`accordion-collapse collapse ${
                  openAccordion === "collapseDrinking" ? "show" : ""
                }`}
                aria-labelledby="headingDrinking"
              >
                <div className="accordion-body">
                  <div className="option_location mt-2 mb-4">
                    {statics.drinkerStatus.map((drink) => {
                      return (
                        <label key={drink.id} className="search_location">
                          {drink.name}
                          <input
                            onChange={(e) => {
                              if (e.target.checked) {
                                searchFormik.setFieldValue("is_drinker", [
                                  ...searchFormik.values.is_drinker,
                                  drink.id,
                                ]);
                              } else {
                                const id: any = [
                                  ...searchFormik.values.is_drinker,
                                ];
                                const index = id.indexOf(drink.id);
                                if (index > -1) {
                                  id.splice(index, 1);
                                }

                                searchFormik.setFieldValue("is_drinker", id);
                              }
                            }}
                            type="checkbox"
                            name={drink.name}
                            checked={(
                              searchFormik.values.is_drinker as any
                            ).includes(drink.id)}
                          />
                          <span className="checkmark"></span>
                        </label>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            <hr />
            {/* Relationship Status */}
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingRelationshipStatus">
                <button
                  className={`accordion-button ${
                    openAccordion === "collapseRelationshipStatus"
                      ? ""
                      : "collapsed"
                  }`}
                  type="button"
                  onClick={() =>
                    handleAccordionClick("collapseRelationshipStatus")
                  }
                  aria-expanded={openAccordion === "collapseRelationshipStatus"}
                  aria-controls="collapseRelationshipStatus"
                >
                  Relationship Status
                </button>
              </h2>
              <div
                id="collapseRelationshipStatus"
                className={`accordion-collapse collapse ${
                  openAccordion === "collapseRelationshipStatus" ? "show" : ""
                }`}
                aria-labelledby="headingRelationshipStatus"
              >
                <div className="accordion-body">
                  <div className="option_location mt-4 mb-4">
                    {statics.relationshipStatus.map((rs) => {
                      return (
                        <label key={rs.id} className="search_location">
                          {rs.name}
                          <input
                            onChange={(e) => {
                              if (e.target.checked) {
                                searchFormik.setFieldValue(
                                  "relationship_status",
                                  [
                                    ...searchFormik.values.relationship_status,
                                    rs.id,
                                  ]
                                );
                              } else {
                                const id: any = [
                                  ...searchFormik.values.relationship_status,
                                ];
                                const index = id.indexOf(rs.id);
                                if (index > -1) {
                                  id.splice(index, 1);
                                }

                                searchFormik.setFieldValue(
                                  "relationship_status",
                                  id
                                );
                              }
                            }}
                            type="checkbox"
                            name={rs.name}
                            checked={(
                              searchFormik.values.relationship_status as any
                            ).includes(rs.id)}
                          />
                          <span className="checkmark"></span>
                        </label>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            <hr />
            {/* Education */}
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingEducation">
                <button
                  className={`accordion-button ${
                    openAccordion === "collapseEducation" ? "" : "collapsed"
                  }`}
                  type="button"
                  onClick={() => handleAccordionClick("collapseEducation")}
                  aria-expanded={openAccordion === "collapseEducation"}
                  aria-controls="collapseEducation"
                >
                  Education
                </button>
              </h2>
              <div
                id="collapseEducation"
                className={`accordion-collapse collapse ${
                  openAccordion === "collapseEducation" ? "show" : ""
                }`}
                aria-labelledby="headingEducation"
              >
                <div className="accordion-body">
                  <div className="option_location mt-2 mb-4">
                    {statics.education.map((edu) => {
                      return (
                        <label key={edu.id} className="search_location">
                          {edu.name}
                          <input
                            onChange={(e) => {
                              if (e.target.checked) {
                                searchFormik.setFieldValue("education", [
                                  ...searchFormik.values.education,
                                  edu.id,
                                ]);
                              } else {
                                const id: any = [
                                  ...searchFormik.values.education,
                                ];
                                const index = id.indexOf(edu.id);
                                if (index > -1) {
                                  id.splice(index, 1);
                                }

                                searchFormik.setFieldValue("education", id);
                              }
                            }}
                            type="checkbox"
                            name={edu.name}
                            checked={(
                              searchFormik.values.education as any
                            ).includes(edu.id)}
                          />
                          <span className="checkmark"></span>
                        </label>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            <hr />
            {/* Ethnicity */}
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingEthnicity">
                <button
                  className={`accordion-button ${
                    openAccordion === "collapseEthnicity" ? "" : "collapsed"
                  }`}
                  type="button"
                  onClick={() => handleAccordionClick("collapseEthnicity")}
                  aria-expanded={openAccordion === "collapseEthnicity"}
                  aria-controls="collapseEthnicity"
                >
                  Ethnicity
                </button>
              </h2>
              <div
                id="collapseEthnicity"
                className={`accordion-collapse collapse ${
                  openAccordion === "collapseEthnicity" ? "show" : ""
                }`}
                aria-labelledby="headingEthnicity"
              >
                <div className="accordion-body">
                  <div className="option_location mt-2 mb-4">
                    {statics.ethnicity.map((bt) => (
                      <label key={bt.id} className="search_location">
                        {bt.name}
                        <input
                          onChange={(e) => {
                            const updatedValues = e.target.checked
                              ? [...searchFormik.values.ethnicity, bt.id]
                              : searchFormik.values.ethnicity.filter(
                                  (id) => id !== bt.id
                                );
                            searchFormik.setFieldValue(
                              "ethnicity",
                              updatedValues
                            );
                          }}
                          type="checkbox"
                          name={bt.name}
                          checked={(
                            searchFormik.values.ethnicity as any
                          ).includes(bt.id)}
                        />
                        <span className="checkmark"></span>
                      </label>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <hr />
            {/* Looking for tags */}
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingLookingFor">
                <button
                  className={`accordion-button ${
                    openAccordion === "collapseLookingFor" ? "" : "collapsed"
                  }`}
                  type="button"
                  onClick={() => handleAccordionClick("collapseLookingFor")}
                  aria-expanded={openAccordion === "collapseLookingFor"}
                  aria-controls="collapseLookingFor"
                >
                  Looking for tags
                </button>
              </h2>
              <div
                id="collapseLookingFor"
                className={`accordion-collapse collapse ${
                  openAccordion === "collapseLookingFor" ? "show" : ""
                }`}
                aria-labelledby="headingLookingFor"
              >
                <div className="accordion-body">
                  <div className="option_location mt-2 mb-4">
                    {statics?.datingTags?.map((bt) => (
                      <label key={bt.id} className="search_location">
                        {bt.tag_name}
                        <input
                          onChange={(e) => {
                            const updatedValues = e.target.checked
                              ? [...searchFormik.values.dating_tags, bt.id]
                              : searchFormik.values.dating_tags.filter(
                                  (id) => id !== bt.id
                                );
                            searchFormik.setFieldValue(
                              "dating_tags",
                              updatedValues
                            );
                          }}
                          type="checkbox"
                          name={bt.tag_name}
                          checked={(
                            searchFormik.values.dating_tags as any
                          ).includes(bt.id)}
                          disabled={(
                            searchFormik.values.dating_tags_not_to_show as any
                          ).includes(bt.id)}
                        />
                        <span className="checkmark"></span>
                      </label>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <hr />
            {/* Not Looking for tags */}
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingNotLookingFor">
                <button
                  className={`accordion-button ${
                    openAccordion === "collapseNotLookingFor" ? "" : "collapsed"
                  }`}
                  type="button"
                  onClick={() => handleAccordionClick("collapseNotLookingFor")}
                  aria-expanded={openAccordion === "collapseNotLookingFor"}
                  aria-controls="collapseNotLookingFor"
                >
                  Not Looking for tags
                </button>
              </h2>
              <div
                id="collapseNotLookingFor"
                className={`accordion-collapse collapse ${
                  openAccordion === "collapseNotLookingFor" ? "show" : ""
                }`}
                aria-labelledby="headingNotLookingFor"
              >
                <div className="accordion-body">
                  <div className="option_location mt-2 mb-4">
                    {statics?.datingTags?.map((bt) => (
                      <label key={bt.id} className="search_location">
                        {bt.tag_name}
                        <input
                          onChange={(e) => {
                            const updatedValues = e.target.checked
                              ? [
                                  ...searchFormik.values
                                    .dating_tags_not_to_show,
                                  bt.id,
                                ]
                              : searchFormik.values.dating_tags_not_to_show.filter(
                                  (id) => id !== bt.id
                                );
                            searchFormik.setFieldValue(
                              "dating_tags_not_to_show",
                              updatedValues
                            );
                          }}
                          type="checkbox"
                          name={bt.tag_name}
                          checked={(
                            searchFormik.values.dating_tags_not_to_show as any
                          ).includes(bt.id)}
                          disabled={(
                            searchFormik.values.dating_tags as any
                          ).includes(bt.id)}
                        />
                        <span className="checkmark"></span>
                      </label>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  staticList: () => dispatch(CommonAction.getStaticList()),
  getFilterMessages: (payload: MessageRequestPayload) =>
    dispatch(MessagesAction.getFilterMessages(payload)),
  addFilterForMessages: (payload: any) =>
    dispatch(MessagesAction.addFilterForMessages(payload)),
});

interface MapDispatchToProps {
  staticList: () => void;
  addFilterForMessages: (payload: any) => void;
  getFilterMessages: (payload: MessageRequestPayload) => void;
}

export default connect(null, mapDispatchToProps)(ChatFilters);
