import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { CommonAction } from "../../redux/thunk";
import { RootReducer } from "../../interfaces/reducer";
import Header from "../../components/Header";
import "./style.css";
import Footer from "../../components/Footer";
import UnAuthFooter from "../Home/footer";
interface Props extends DispatchToProps {}

const TermsAndCondition = (props: Props) => {
  const termsAndConditions: String = useSelector(
    (state: RootReducer) => state.common.termsAndConditions
  );
  const { authenticated } = useSelector((state: RootReducer) => state.auth);

  useEffect(() => {
    props.getTermsAndConditions();
  }, []);

  return (
    <div>
      <Header />
      <div className="top-space">
        <div className="termsAndCondition privacypolicy">
          <div className="container">
            {termsAndConditions !== "" ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: termsAndConditions as string,
                }}
              />
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      {authenticated ? <Footer /> : <UnAuthFooter />}
    </div>
  );
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getTermsAndConditions: () => dispatch(CommonAction.getTermsAndCondition()),
});

interface DispatchToProps {
  getTermsAndConditions: () => void;
}

export default connect(null, mapDispatchToProps)(TermsAndCondition);
