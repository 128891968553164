import { useFormik } from "formik";
import Autocomplete from "react-google-autocomplete";
import { Jio } from "../../../../../assets/images";
import "../../style.css";
import { LocationDetailSchema } from "../../../../../schemas";
import { useDispatch, useSelector } from "react-redux";
import { ACTIONS } from "../../../../../redux/actions";
import {
  GENERAL_DETAILS_STEP,
  SIGNUP_STEPS,
} from "../../../../../utils/constants";
import { useEffect, useState } from "react";
import { RootReducer } from "../../../../../interfaces/reducer";

const LocationDetails = () => {
  const dispatch = useDispatch();
  const [address, setAddress] = useState<any>("");
  const { location, latitude, longitude } = useSelector(
    (state: RootReducer) => state.auth.signupValues
  );

  const locationFormik = useFormik({
    initialValues: {
      location: location || "",
      latitude: latitude || 0,
      longitude: longitude || 0,
    },
    validationSchema: LocationDetailSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      dispatch({ type: ACTIONS.SET_SIGNUP_VALUES, values });
      dispatch({
        type: ACTIONS.SET_SIGNUP_STEP,
        step: SIGNUP_STEPS.PREVIEW_CROPPED_PROFILE_PHOTO,
        generalStep: GENERAL_DETAILS_STEP.ADD_BODY_DETAILS,
      });
    },
  });

  useEffect(() => {
    if (location) setAddress(location);
  }, [location]);

  return (
    <div className="modal-body px-0 pb-0">
      <h6 className="looks-great">Great! Tell us about yourself...</h6>
      <div className="form-sec">
        <form>
          <div className="row">
            <div className="Choose_Username about_useredit login-form">
              <div className="row">
                <div className="col-md-12">
                  <div className="mb-3">
                    <div className="location-jio">
                      <label>Where are you located ?</label>
                      <div className="form-group">
                        <div className="input_ic">
                        <span className="input_icc">
                          <img className="llogo" src={Jio} alt="" />
                        </span>
                        <Autocomplete
                          apiKey={process.env.REACT_APP_GOOGLE_MAPS_KEY!}
                          defaultValue={address}
                          onPlaceSelected={(place) => {
                            const {
                              geometry: { location },
                              formatted_address,
                            } = place;
                            const latitude = location.lat();
                            const longitude = location.lng();
                            locationFormik.setFieldValue(
                              "location",
                              formatted_address
                            );
                            locationFormik.setFieldValue("latitude", latitude);
                            locationFormik.setFieldValue(
                              "longitude",
                              longitude
                            );
                          }}
                          style={{
                            height: "50px",
                            borderRadius: "10px",
                            borderWidth: "2px",
                          }}
                        />
                        </div>
                       
                        {/* <Input
                                                    type="text"
                                                    name="location"
                                                    placeHolder="Enter your location"
                                                    handleChange={(event) => {
                                                        const { target: { value } } = event;

                                                        locationFormik.setFieldValue('location', value);
                                                    }}
                                                    value={locationFormik.values.location}
                                                    error=''
                                                /> */}
                      </div>
                      <div className="location-error">
                        {locationFormik.touched.location &&
                        locationFormik.errors.location
                          ? locationFormik.errors.location
                          : ""}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      {/* <div className="left-rotare-btn show-btns"> */}
        <button className="left-rotare-btn show-btns btn-public mx-auto" onClick={locationFormik.submitForm}>Continue</button>
      {/* </div> */}
    </div>
  );
};

export default LocationDetails;
