import { useEffect, useState } from "react";
import Header from "../../components/Header";
import Sidebar from "../../components/Settings/Sidebar";
import PrivacyPolicy from "../../components/Settings/PrivacyPolicy";
import { SidebarItems } from "../../components/Settings/SideBaritems";
import { Constants } from "../../utils/constants";
import TermAndConditon from "../../components/Settings/TermAndConditon";
import ContactUs from "../../components/Settings/ContactUs";
import SeeHiddenUsers from "../../components/Settings/SeeHiddenUsers";
import SeeBlockedUser from "../../components/Settings/SeeBlockedUser";
import { useLocation, useNavigate } from "react-router-dom";

const Setting = () => {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState<string>(SidebarItems?.[0]?.name);
  const navigate = useNavigate();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const tabParam = queryParams.get(Constants.activeTab);
    if (tabParam && SidebarItems.map((elt) => elt.name).includes(tabParam)) {
      setActiveTab(tabParam);
    }
  }, [location]);

  const changeActiveTab = (key: string) => {
    setActiveTab(key);
    const queryParams = new URLSearchParams(location.search);
    queryParams.set(Constants.activeTab, key);
    navigate(`?${queryParams.toString()}`);
  };

  return (
    <div>
      <Header />
      <div className="container top-space inner-pages ">
        <div className="row">
          <div className="col-md-4">
            <Sidebar activeTab={activeTab} changeActiveTab={changeActiveTab} />
          </div>
          <div className="col-md-8 common-texts">
            {activeTab === Constants.privacyPolicy && <PrivacyPolicy />}
            {activeTab === Constants.termsAndCondition && <TermAndConditon />}
            {activeTab === Constants.contactUs && <ContactUs />}
            {activeTab === Constants.seeHiddenUsers && <SeeHiddenUsers />}
            {activeTab === Constants.seeBlockUsers && <SeeBlockedUser />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Setting;
