import * as Yup from "yup";

const BodyDetailsSchema = Yup.object().shape({
  hair: Yup.number().required("Required").min(1, "* Required"),
  gender: Yup.number().required("Required").min(1, "* Required"),
  height: Yup.number().required("Required").min(1, "* Required"),
  bodyType: Yup.number().required("Required").min(1, "* Required"),
  ethnicity: Yup.number().required("Required").min(1, "* Required"),
  // height: Yup.number()
  // .min(134, "Height cannot be less than 134 cm")
  // .max(213, "Height cannot be more than 213 cm")
  // .notOneOf([0], "Height is required"),
});

export default BodyDetailsSchema;
