import { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../redux/store";
import { AuthAction } from "../../../../redux/thunk";
import { SendOtpPayload, VerifyOtpPayload } from "../../../../interfaces/auth";
import { RootReducer } from "../../../../interfaces/reducer";
import OtpInput from "react-otp-input";
import * as Yup from "yup";
import "../style.css";
import { ACTIONS } from "../../../../redux/actions";
import { SIGNUP_STEPS } from "../../../../utils/constants";

export const otpSchema = Yup.string()
  .length(4, "OTP must be exactly 4 digits")
  .required("OTP is required");

interface Props extends MapDispatchToProps {
  back: boolean;
  handlePhoneEdit?: () => void;
}

const Otp = (props: Props) => {
  const dispatch = useDispatch();
  const [otp, setOtp] = useState<string>("");
  const [otpError, setOtpError] = useState<string>("");
  const [timer, setTimer] = useState<number>(40);
  const { signupValues } = useSelector((state: RootReducer) => state.auth);
  const { profile } = useSelector((state: RootReducer) => state.user);

  // Logic to decrease the timer count by one after every 1 second
  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [timer]);

  // Function to resend otp
  const handleResendOtp = () => {
    const data = {
      countryCode: signupValues.countryCode,
      mobileNumber: signupValues.mobileNumber,
      email: signupValues.email,
      resend: true,
      user_id: profile?.id || null,
    };
    props.sendOtp(data);
    setOtp("");
    setTimer(40);
  };

  // Function to verify the OTP entered by user
  const handleVerifyOtp = async () => {
    try {
      await otpSchema.validate(otp);
      const data = {
        countryCode: signupValues.countryCode,
        mobileNumber: signupValues.mobileNumber,
        otp: otp,
        nextTab: props.back,
      };
      const resp: any = await props.verifyOtp(data);
      if (resp?.data?.success!) {
        if (props.handlePhoneEdit) props.handlePhoneEdit();
      }
    } catch (err: any) {
      setOtpError(err.message);
    }
  };

  // Function to handle Enter key press
  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleVerifyOtp();
    }
  };

  return (
    <div className="container">
      <div className="row">
        <div className="">
          <div className="otp-inputs">
            {/* OTP Input */}
            <div className="ott-input">
              <OtpInput
                value={otp}
                onChange={(e) => {
                  setOtp(e);
                  setOtpError("");
                }}
                numInputs={4}
                renderInput={(props, index) => (
                  <input
                    {...props}
                    autoFocus={index === 0}
                    onKeyPress={handleKeyPress}
                  />
                )}
              />
              {otpError && <p className="mt-2">{otpError}</p>}
            </div>
            {/* Show resend OTP button if timer is 0 else show timer decreasing after every 1 second */}
            <div className="lg:mt-[48px]">
              <button
                type="button"
                className="btn-verify"
                onClick={() => {
                  handleVerifyOtp();
                }}
              >
                Verify OTP
              </button>
            </div>

            <p className="resend-otps">
              {timer === 0 ? (
                <>
                  <button
                    className=""
                    onClick={() => {
                      handleResendOtp();
                    }}
                  >
                    Resend OTP
                  </button>
                </>
              ) : (
                `Resend Otp in ${timer} seconds`
              )}
            </p>
            {props.back && (
              <button
                className="btn_back"
                onClick={() =>
                  dispatch({
                    type: ACTIONS.SET_SIGNUP_STEP,
                    step: SIGNUP_STEPS.PERSONAL_DETAILS,
                  })
                }
              >
                <i className="fas fa-arrow-left"></i> Back
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  sendOtp: (payload: SendOtpPayload) => dispatch(AuthAction.sendOtp(payload)),
  verifyOtp: (payload: VerifyOtpPayload) =>
    dispatch(AuthAction.verifyOtp(payload)),
});

interface MapDispatchToProps {
  sendOtp: (payload: SendOtpPayload) => void;
  verifyOtp: (payload: VerifyOtpPayload) => void;
}

export default connect(null, mapDispatchToProps)(Otp);
