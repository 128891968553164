/* eslint-disable react-hooks/exhaustive-deps */
import { useSelector } from "react-redux";
import { RootReducer } from "../../interfaces/reducer";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import AboutUs from "../../components/CMS/AboutUs";

const LoginAboutUs = () => {
  const { authenticated } = useSelector((state: RootReducer) => state.auth);

  return (
    <>
      <Header />
      <AboutUs />
      {!!authenticated && <Footer />}
    </>
  );
};

export default LoginAboutUs;
