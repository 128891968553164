import { AuthReducer } from "../../interfaces/reducer";
import { SIGNUP_STEPS } from "../../utils/constants";
import { ACTIONS } from "../actions";

export const initialState: AuthReducer = {
  authenticated: false,
  googleSignUp: false,
  googleSignupPayload: {
    id: "",
    name: "",
    email: "",
  },
  loginModal: false,
  forgotPswdModal: false,
  newPswdModal: false,
  otpModal: false,
  resetForm: false,
  signupModal: false,
  signupStep: SIGNUP_STEPS.PERSONAL_DETAILS,
  passwordModal: false,
  userInfoModal: false,
  signupValues: {
    dob: "",
    email: "",
    password: "",
    mobileNumber: "",
    displayName: "",
    username: "",
    image: null,
    croppedImage: null,
    is_profile_image_public: "public",
    height: 0,
    bodyType: 0,
    ethnicity: 0,
    drinkerId: 0,
    smokerId: 0,
    childrenId: 0,
    educationId: 0,
    relationshipId: 0,
    occupationId: 0,
    countryCode: "",
    google_id: "",
    netWorth: 0,
    annualIncome: 0,
    location: "",
    latitude: 0,
    longitude: 0,
    fromAge: 18,
    toAge: 60,
    hair: 0,
    countryFlag: "",
    gender: 0,
    looking_for: 1,
    interestsId: [],
    photos: [],
    heading: "",
    bio: "",
    prefilledEmail: "",
  },
  generalDetailSteps: SIGNUP_STEPS.PREVIEW_CROPPED_PROFILE_PHOTO,
  fullName: "",
  redirectToHome: false,
};

const authReducer = (state = initialState, action: { type: string } & any) => {
  if (action.type === ACTIONS.SET_LOGIN_MODAL) {
    let payload = {
      ...state,
      loginModal: action.status,
      resetForm: action.resetForm,
    };

    return payload;
  }
  if (action.type === ACTIONS.FORGOT_PSWD_MODAL) {
    let payload = {
      ...state,
      forgotPswdModal: action.status,
      resetForm: action.resetForm,
    };
    return payload;
  }
  if (action.type === ACTIONS.OTP_MODAL) {
    let payload = {
      ...state,
      otpModal: action.status,
      resetForm: action.resetForm,
    };
    return payload;
  }
  if (action.type === ACTIONS.NEW_PSWD_MODAL) {
    let payload = {
      ...state,
      newPswdModal: action.status,
      resetForm: action.resetForm,
    };
    return payload;
  }
  if (action.type === ACTIONS.SET_SIGNUP_MODAL) {
    let payload = {
      ...state,
      signupModal: action.status,
      resetForm: action.resetForm,
      signupValues: {
        ...state.signupValues,
        email: action.prefilledEmail,
        displayName: action?.displayName || "",
        mobileNumber: `${action?.countryCode || "+91"}${
          action?.mobileNumber || ""
        }`,
        google_id: action?.google_id || null,
        password: action.password,
      },
    };

    return payload;
  } else if (action.type === ACTIONS.SET_GOOGLE_PAYLOAD) {
    let payload = {
      ...state,
      googleSignupPayload: {
        id: action.id,
        email: action.email,
        name: action.name,
        google_id: action.google_id,
      },
    };

    return payload;
  } else if (action.type === ACTIONS.SET_SIGNUP_STEP) {
    const generalStep =
      action.generalStep ?? SIGNUP_STEPS.PREVIEW_CROPPED_PROFILE_PHOTO;
    return {
      ...state,
      signupStep: action.step,
      generalDetailSteps: generalStep,
    };
  } else if (action.type === ACTIONS.SET_SIGNUP_VALUES) {
    return {
      ...state,
      signupValues: {
        ...state.signupValues,
        ...action.values,
      },
    };
  } else if (action.type === ACTIONS.SET_AUTHENTICATED_USER) {
    return {
      ...state,
      authenticated: action.authenticated,
      fullName: action.fullName,
      redirectToHome: true,
    };
  } else if (action.type === ACTIONS.SET_REDIRECTION_TO_HOME) {
    return {
      ...state,
      redirectToHome: action.status,
    };
  } else if (action.type === ACTIONS.SET_PASSWORD_MODAL) {
    return {
      ...state,
      passwordModal: action.status,
    };
  } else if (action.type === ACTIONS.SET_GOOGLE_LOGIN) {
    return {
      ...state,
      googleSignUp: action.status,
    };
  } else if (action.type === ACTIONS.RESET_SIGNUP_VALUES) {
    return {
      ...state,
      authenticated: false,
      googleSignUp: false,
      googleSignupPayload: {
        id: "",
        name: "",
        email: "",
      },
      loginModal: false,
      resetForm: false,
      signupModal: false,
      signupStep: SIGNUP_STEPS.PERSONAL_DETAILS,
      passwordModal: false,
      userInfoModal: false,
      signupValues: {
        dob: "",
        email: "",
        password: "",
        mobileNumber: "+91",
        username: "",
        displayName: "",
        image: null,
        croppedImage: null,
        height: 0,
        bodyType: 0,
        ethnicity: 0,
        drinkerId: 0,
        smokerId: 0,
        childrenId: 0,
        occupationId: 0,
        educationId: 0,
        relationshipId: 0,
        countryCode: "",
        netWorth: 0,
        annualIncome: 0,
        location: "",
        latitude: 0,
        longitude: 0,
        fromAge: 0,
        toAge: 0,
        hair: 0,
        countryFlag: "",
        gender: 0,
        interestsId: [],
        heading: "",
        bio: "",
        prefilledEmail: "",
      },
    };
  } else if (action.type === ACTIONS.SET_USERINFO_MODAL) {
    return {
      ...state,
      userInfoModal: action.status,
    };
  }

  return state;
};

export default authReducer;
