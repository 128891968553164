import { connect, useDispatch, useSelector } from "react-redux";
import { RootReducer } from "../../../interfaces/reducer";
import { ACTIONS } from "../../../redux/actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { neuDaddyLogo } from "../../../assets/images";
import OTPInput from "react-otp-input";
import { useEffect, useState } from "react";
import { otpSchema } from "../../Modals/Signup/Steps/Otp";
import { Constants } from "../../../utils/constants";
import {
  SendOtpForgotPasswordPayload,
  VerifyOtpForgotPasswordPayload,
} from "../../../interfaces/auth";
import { AuthAction } from "../../../redux/thunk";
import { AppDispatch } from "../../../redux/store";
import styles from "./../../Input/style.module.css";
import Loader from "../../Loader/Spinner";

interface Props extends MapDispatchToProps {}

const OtpModal = (props: Props) => {
  const dispatch = useDispatch();
  const { otpModal } = useSelector((state: RootReducer) => state.auth);
  const [timer, setTimer] = useState<number>(40);
  const [otp, setOtp] = useState<string>("");
  const [otpError, setOtpError] = useState<string>("");
  const [loader, setLoader] = useState(false);
  const number = localStorage.getItem(Constants.forgotPasswordNumber)!;

  // Logic to decrease the timer count by one after every 1 second
  useEffect(() => {
    if (otpModal) {
      let interval: NodeJS.Timeout;
      if (timer > 0) {
        interval = setInterval(() => {
          setTimer((prevTimer) => prevTimer - 1);
        }, 1000);
      }

      return () => {
        clearInterval(interval);
      };
    }
  }, [timer, otpModal]);

  // Function to resend otp
  const handleResendOtp = async () => {
    const data = {
      country_code: number.slice(1, 3),
      phone_number: number.slice(3, 20),
    };
    setLoader(true);
    await props.sendOtp(data);
    setLoader(false);
    setOtp("");
    setTimer(40);
  };

  // Function to verify the OTP entered by user
  const handleVerifyOtp = async () => {
    try {
      await otpSchema.validate(otp);
      const data = {
        country_code: number.slice(1, 3),
        phone_number: number.slice(3, 20),
        otp: otp,
      };
      setLoader(true);
      const resp: any = await props.verifyOtp(data);
      setLoader(false);
      if (resp?.data?.success!) {
        dispatch({ type: ACTIONS.NEW_PSWD_MODAL, status: true });
        dispatch({ type: ACTIONS.OTP_MODAL, status: false });
      }
    } catch (err: any) {
      setOtpError(err.message);
    }
  };

  const handleBack = () => {
    dispatch({ type: ACTIONS.FORGOT_PSWD_MODAL, status: true });
    dispatch({ type: ACTIONS.OTP_MODAL, status: false });
  };

  // Function to handle Enter key press
  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleVerifyOtp();
    }
  };

  return (
    <div className={`modal login-modal ${otpModal ? "d-block" : ""}`}>
      {loader && <Loader />}
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content Edit_your_info">
          <div className="modal-header">
            <div className="logo-modal">
              <img className="me-2" src={neuDaddyLogo} alt="" />
              <FontAwesomeIcon
                className="cursor-pointer close"
                size="lg"
                icon={faClose}
                onClick={() => {
                  localStorage.removeItem(Constants.forgotPasswordNumber);
                  dispatch({ type: ACTIONS.OTP_MODAL, status: false });
                }}
              />
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="">
                <div className="otp-inputs">
                  {/* OTP Input */}
                  <div className="ott-input">
                    <OTPInput
                      value={otp}
                      onChange={(e) => {
                        setOtp(e);
                        setOtpError("");
                      }}
                      numInputs={4}
                      renderInput={(props, index) => (
                        <input
                          {...props}
                          autoFocus={index === 0}
                          onKeyPress={handleKeyPress}
                        />
                      )}
                    />
                    {otpError && <p className={styles.error}>{otpError}</p>}
                  </div>
                  {/* Show resend OTP button if timer is 0 else show timer decreasing after every 1 second */}
                  <div className="lg:mt-[48px]">
                    <button
                      type="button"
                      className="btn-verify"
                      onClick={() => {
                        handleVerifyOtp();
                      }}
                    >
                      Verify OTP
                    </button>
                  </div>

                  <p className="resend-otps">
                    {timer === 0 ? (
                      <>
                        <button
                          className=""
                          onClick={() => {
                            handleResendOtp();
                          }}
                        >
                          Resend OTP
                        </button>
                      </>
                    ) : (
                      `Resend Otp in ${timer} seconds`
                    )}
                  </p>
                  <button className="btn_back" onClick={handleBack}>
                    <i className="fas fa-arrow-left"></i> Back
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  verifyOtp: (payload: VerifyOtpForgotPasswordPayload) =>
    dispatch(AuthAction.verifyOtpForgotPassword(payload)),
  sendOtp: (payload: SendOtpForgotPasswordPayload) =>
    dispatch(AuthAction.sendOtpForgotPassword(payload)),
});

interface MapDispatchToProps {
  verifyOtp: (payload: VerifyOtpForgotPasswordPayload) => void;
  sendOtp: (payload: SendOtpForgotPasswordPayload) => void;
}

export default connect(null, mapDispatchToProps)(OtpModal);
