import axios from "axios";
import { useNavigate } from "react-router-dom";

import ROUTES from "../constants/routes";

const apiAxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    Accepted: "application/json",
    "Content-Type": "application/json",
  },
});

apiAxiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token && config.headers) {
      config.headers.authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export const setupResponseInterceptor = (
  navigate: ReturnType<typeof useNavigate>
) => {
  apiAxiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },

    (error) => {
      if (error.response.status === 401) {
        localStorage.removeItem("token");
        navigate(ROUTES.HOME);
      }

      throw error;
    }
  );
};

export { apiAxiosInstance };
