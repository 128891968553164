import { Fragment, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import SubscriptionPlans from "./SubscriptionPlans";
import {
  getSubscriptionExpirationDetail,
  getSubscriptions,
} from "../../redux/thunk/subscription";
import "./style.css";
import Loader from "../../components/Loader/Spinner";
import CurrentPlan from "../../components/Subscription/CurrentPlan";

const Subscription = () => {
  const dispatch = useDispatch();
  const [subscriptionBuy, setSubscriptionBuy] = useState(false);
  const [showBuyBtn, setShowBuyBtn] = useState(false);
  const [subsData, setSubsData] = useState([]);
  const [selectedData, setSelectedData] = useState({});
  const [selectedDataId, setSelectedDataId] = useState(null);
  const [loading, setLoading] = useState(false); // State to manage loading
  // const handlePackClick = (data) => {
  //   setSelectedData(data);
  //   setSelectedDataId(data.id);
  // };
  const [subscriptionExpDetail, setSubscriptionExpDetail] = useState();

  const getSubscriptionsData = async () => {
    const data = await dispatch(getSubscriptionExpirationDetail());
    setSubscriptionExpDetail(data);
    setLoading(true); // Set loading to true when fetching starts
    getSubscriptions(
      (data) => {
        setSubsData(data);
        setSelectedData(data[0]);
        setLoading(false); // Set loading to false after fetching completes
      },
      () => {
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    getSubscriptionsData();
  }, []);

  return (
    <Fragment>
      <Header />
      <section className="main-sec search_page">
        <div className="container">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 mx-auto d-block subscriptionHolder">
                <div className="listing-sec message_tabs subscriptionHolder pt-3">
                  {loading ? (
                    <Loader /> // Display the loader while loading is true
                  ) : subscriptionExpDetail?.subscriptionDaysLeft ? (
                    <CurrentPlan
                      subscriptionExpDetail={subscriptionExpDetail}
                    />
                  ) : (
                    <>
                      <ul className="interesttabs nav nav-sub-data">
                        {subsData.map((item, index) => {
                          return (
                            <li
                              key={index}
                              className={`text-uppercase heading-holder ${
                                selectedDataId === item.id ? "selectedTab" : ""
                              }`}
                            >
                              <span
                                className={`col-12 font-weight-bold ${
                                  selectedDataId === item.id ? "" : "titleSpan"
                                }`}
                                // onClick={() => handlePackClick(item)}
                              >
                                {item?.title}
                              </span>
                            </li>
                          );
                        })}
                      </ul>
                      <div>
                        <SubscriptionPlans
                          activePack={selectedData}
                          setShowBuyBtn={setShowBuyBtn}
                          showBuyBtn={showBuyBtn}
                          subscriptionBuy={subscriptionBuy}
                          setSubscriptionBuy={setSubscriptionBuy}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Fragment>
  );
};

const mapDispatchToProps = () => ({});

export default connect(null, mapDispatchToProps)(Subscription);
