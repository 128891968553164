import { toast } from "react-toastify";
import { apiAxiosInstance } from "../../config/axios";
import {
  ChangePasswordPayload,
  ForgotPasswordPayload,
  LoginPayload,
  SendOtpForgotPasswordPayload,
  SendOtpPayload,
  VerifyOtpForgotPasswordPayload,
  VerifyOtpPayload,
} from "../../interfaces/auth";
import { ACCESS_TOKEN, Constants, SIGNUP_STEPS } from "../../utils/constants";
import { AppDispatch } from "../store";
import { ACTIONS } from "../actions";
import { GoogleSignup, SignupValues } from "../../interfaces/reducer";
import { CommonAction } from ".";
import { getProfileDetails } from "./user";
import { ApiUrl } from "../../utils/ApiUrl";

const login = (payload: LoginPayload) => async (dispatch: AppDispatch) => {
  try {
    const {
      data: { data, message },
    } = await apiAxiosInstance.post(`/login`, payload);
    toast.success(message);
    localStorage.setItem(ACCESS_TOKEN, data.token);
    dispatch({
      type: ACTIONS.SET_AUTHENTICATED_USER,
      authenticated: true,
      fullName: data.user_name,
    });
    dispatch({ type: ACTIONS.SET_LOGIN_MODAL, status: false, resetForm: true });
    dispatch({ type: ACTIONS.SET_REDIRECTION_TO_HOME, status: true });
    return data;
  } catch (err: any) {
    if (err.response && err.response.data) {
      if (Object.keys(err?.response?.data?.data)?.length > 0) {
        return err?.response?.data?.data;
      } else toast.error(err.response.data.message);
      // if (
      //   err.response.data.message !=
      //   "Country code is required with phone number."
      // ) {
      //   dispatch({
      //     type: ACTIONS.SET_SIGNUP_MODAL,
      //     status: true,
      //     prefilledEmail: payload.emailOrPhone,
      //     types: payload.emailOrPhoneType,
      //     countryCode: payload.country_code,
      //     password: payload.password,
      //   });
      //   dispatch({
      //     type: ACTIONS.SET_LOGIN_MODAL,
      //     status: false,
      //     resetForm: true,
      //   });
      // }
    }
  }
};

const changePassword =
  (payload: ChangePasswordPayload) => async (dispatch: AppDispatch) => {
    try {
      const data = await apiAxiosInstance.post(`/change-password`, payload, {
        headers: {
          "Content-Type": "multipart/form-data", // Use multipart/form-data for file uploads
        },
      });

      toast.success(data.data.message);
      dispatch({ type: ACTIONS.SET_PASSWORD_MODAL, status: false });
    } catch (err: any) {
      if (err.response && err.response.data) {
        toast.error(err.response.data.message);
      }
    }
  };

const sendOtp = (payload: SendOtpPayload) => async (dispatch: AppDispatch) => {
  let data: {
    email: string;
    country_code: string;
    phone_number: string;
    user_id?: number | null;
  } = {
    email: payload?.email!,
    country_code: payload?.countryCode!,
    phone_number: payload.mobileNumber!.startsWith("+")
      ? payload.mobileNumber!.substring(3)!
      : payload.mobileNumber!,
  };
  if (payload?.user_id) {
    data.user_id = payload.user_id;
  }
  try {
    const response = await apiAxiosInstance.post(`/send-otp`, data);
    if (!payload?.resend) {
      dispatch({ type: ACTIONS.SET_SIGNUP_VALUES, values: payload });
      dispatch({
        type: ACTIONS.SET_SIGNUP_STEP,
        step: SIGNUP_STEPS.VERIFY_OTP,
      });
    }
    return response;
  } catch (err: any) {
    if (err.response && err.response.data && err.response.data.message) {
      toast.error(err.response.data.message);
    }
  }
};

const verifyOtp =
  (payload: VerifyOtpPayload) => async (dispatch: AppDispatch) => {
    try {
      const resp = await apiAxiosInstance.post(`/verify-otp`, {
        otp: payload.otp,
        country_code: payload.countryCode,
        phone_number: payload.mobileNumber!.startsWith("+")
          ? payload.mobileNumber!.substring(3)
          : payload.mobileNumber,
      });
      if (payload.nextTab)
        dispatch({
          type: ACTIONS.SET_SIGNUP_STEP,
          step: SIGNUP_STEPS.CHOOSE_USERNAME,
        });
      return resp;
    } catch (err: any) {
      if (err.response && err.response.data && err.response.data.message) {
        toast.error(err.response.data.message);
      }
    }
  };

const sendOtpForgotPassword =
  (data: SendOtpForgotPasswordPayload) => async () => {
    try {
      const resp = await apiAxiosInstance.post(
        ApiUrl.forgotPasswordSendOtp,
        data
      );
      return resp;
    } catch (err: any) {
      if (err.response && err.response.data && err.response.data.message) {
        toast.error(err.response.data.message);
      }
    }
  };

const verifyOtpForgotPassword =
  (data: VerifyOtpForgotPasswordPayload) => async () => {
    try {
      const resp = await apiAxiosInstance.post(
        ApiUrl.forgotPasswordVerifyOtp,
        data
      );
      return resp;
    } catch (err: any) {
      if (err.response && err.response.data && err.response.data.message) {
        toast.error(err.response.data.message);
      }
    }
  };

const forgotPassword = (data: ForgotPasswordPayload) => async () => {
  try {
    const resp = await apiAxiosInstance.post(ApiUrl.forgotPassword, data);
    return resp;
  } catch (err: any) {
    if (err.response && err.response.data && err.response.data.message) {
      toast.error(err.response.data.message);
    }
  }
};

const logout = () => async (dispatch: AppDispatch) => {
  try {
    const { data } = await apiAxiosInstance.post(`/logout`, {});
    toast.success(data.message);
    dispatch({ type: ACTIONS.SET_AUTHENTICATED_USER, authenticated: false });
    dispatch({ type: ACTIONS.RESET_SIGNUP_VALUES });
    localStorage.removeItem(ACCESS_TOKEN);
    window.location.reload();
  } catch (err: any) {
    if (err.response && err.response.data && err.response.data.message) {
      toast.error(err.response.data.message);
    }
  }
};

const signup = (payload: SignupValues) => async (dispatch: AppDispatch) => {
  try {
    const newPayload = {
      user_name: payload.displayName || payload.username,
      email: payload.email,
      dob: payload.dob,
      gender: payload.gender,
      country_flag: payload.countryFlag,
      country_code: payload.countryCode,
      is_profile_image_public:
        payload.is_profile_image_public === "public" ? 1 : 0,
      phone_number: payload.mobileNumber!.startsWith("+")
        ? payload.mobileNumber!.substring(3)
        : payload.mobileNumber,
      location: payload.location,
      latitude: payload.latitude.toString(),
      longitude: payload.longitude.toString(),
      maximum_height: payload.height,
      body_type: payload.bodyType,
      hair: payload.hair,
      ethnicity: payload.ethnicity,
      education: payload.educationId,
      relationship_status: payload.relationshipId,
      is_children: payload.childrenId,
      is_smoker: payload.smokerId,
      photos: payload.photos,
      is_drinker: payload.drinkerId,
      from_age: payload.fromAge,
      to_age: payload.toAge,
      net_worth: payload.netWorth,
      annual_income: payload.annualIncome,
      tags: payload.interestsId,
      password: payload.password,
      headline: payload.heading,
      bio: payload.bio,
      profile_image: null,
      is_phone_verified: 1,
    };
    if (payload.croppedImage || payload.image) {
      const formData = new FormData();
      formData.set(
        "image",
        payload.croppedImage
          ? (payload.croppedImage as Blob)
          : (payload.image as Blob)
      );

      dispatch(CommonAction.uploadProfileImage(formData));

      const {
        data: { data },
      } = await apiAxiosInstance.post(`/upload/image`, formData, {
        headers: {
          "Content-Type": "multipart/form-data", // Use multipart/form-data for file uploads
        },
      });
      newPayload.profile_image = data[0].orig_path_url;
    }
    const {
      data: { message, data },
    } = await apiAxiosInstance.post(`/create-profile`, newPayload);
    toast.success(message);
    dispatch({ type: ACTIONS.SET_SIGNUP_MODAL, status: false });
    dispatch({ type: ACTIONS.SET_LOGIN_MODAL, status: false });
    localStorage.setItem(ACCESS_TOKEN, data.token);
    dispatch(getProfileDetails());
    // dispatch({
    //   type: ACTIONS.SET_SIGNUP_STEP,
    //   step: SIGNUP_STEPS.PREVIEW_CROPPED_PROFILE_PHOTO,
    //   generalStep: GENERAL_DETAILS_STEP.ADD_VERIFY_EMAIL,
    // });
  } catch (err: any) {
    if (err.response && err.response.data) {
      toast.error(err.response.data.message);
    }
  }
};

const signupWithGoogle =
  (
    payload: SignupValues,
    googlePayload: GoogleSignup,
    navigate: (path: string) => void
  ) =>
  async (dispatch: AppDispatch) => {
    try {
      const newPayload = {
        email: payload.email,
        user_name: payload.displayName || payload.username,
        dob: payload.dob,
        google_id: payload.google_id || null,
        gender: payload.gender,
        country_flag: payload.countryFlag,
        country_code: payload.countryCode,
        is_profile_image_public:
          payload.is_profile_image_public === "public" ? 1 : 0,
        phone_number: payload.mobileNumber!.startsWith("+")
          ? payload.mobileNumber!.substring(3)
          : payload.mobileNumber,
        location: payload.location,
        latitude: payload.latitude.toString(),
        longitude: payload.longitude.toString(),
        maximum_height: payload.height,
        body_type: payload.bodyType,
        hair: payload.hair,
        ethnicity: payload.ethnicity,
        education: payload.educationId,
        relationship_status: payload.relationshipId,
        is_children: payload.childrenId,
        is_smoker: payload.smokerId,
        photos: payload.photos,
        is_drinker: payload.drinkerId,
        from_age: payload.fromAge,
        to_age: payload.toAge,
        net_worth: payload.netWorth,
        annual_income: payload.annualIncome,
        tags: payload.interestsId,
        headline: payload.heading,
        bio: payload.bio,
        profile_image: null,
      };
      const response: any = await apiAxiosInstance.post(
        `/authorized/google/sign-up`,
        googlePayload
      );
      if (response.data.success) {
        localStorage.setItem(ACCESS_TOKEN, response.data.data.token);
        if (payload.croppedImage || payload.image) {
          const formData = new FormData();
          formData.set(
            "image",
            payload.croppedImage
              ? (payload.croppedImage as Blob)
              : (payload.image as Blob)
          );
          dispatch(CommonAction.uploadProfileImage(formData));
          const {
            data: { data },
          } = await apiAxiosInstance.post(`/upload/image`, formData, {
            headers: {
              "Content-Type": "multipart/form-data", // Use multipart/form-data for file uploads
            },
          });
          newPayload.profile_image = data[0].orig_path_url;
        }
        // const { data } = await apiAxiosInstance.post(
        //   `/edit-profile`,
        //   newPayload
        // );
        // if (data.success) {
        //   toast.success("Profile Created successfully");
        //   dispatch(getProfileDetails());
        //   dispatch({ type: ACTIONS.SET_SIGNUP_MODAL, status: false });
        //   dispatch({
        //     type: ACTIONS.SET_SIGNUP_STEP,
        //     step: SIGNUP_STEPS.PERSONAL_DETAILS,
        //   });
        //   dispatch({ type: ACTIONS.RESET_SIGNUP_VALUES });
        // }
        const { data } = await apiAxiosInstance.post(
          `/create-profile`,
          newPayload
        );
        toast.success(data.message);
        dispatch({ type: ACTIONS.SET_SIGNUP_MODAL, status: false });
        dispatch({ type: ACTIONS.SET_LOGIN_MODAL, status: false });
        localStorage.setItem(ACCESS_TOKEN, data.data.token);
        dispatch(getProfileDetails());
      }
    } catch (err: any) {
      if (err.response && err.response.data) {
        toast.error(err.response.data.message);
      }
    }
  };

const existingGoogleProfile =
  (googlePayload: GoogleSignup) => async (dispatch: AppDispatch) => {
    try {
      await apiAxiosInstance.post(`/check-email`, {
        email: googlePayload.email,
      });
      dispatch({
        type: ACTIONS.SET_GOOGLE_PAYLOAD,
        id: googlePayload.id,
        email: googlePayload.email,
        name: googlePayload.name,
        google_id: googlePayload.google_id,
      });
      dispatch({
        type: ACTIONS.SET_SIGNUP_MODAL,
        status: true,
        prefilledEmail: googlePayload.email,
        displayName: googlePayload.name,
        google_id: googlePayload.google_id,
        types: "email",
        countryCode: "",
        password: "",
      });
    } catch (err: any) {
      if (err.response && err.response.data && err.response.data.message) {
        const response: any = await apiAxiosInstance.post(
          `/authorized/google/sign-up`,
          googlePayload
        );
        if (response.data.success) {
          localStorage.setItem(ACCESS_TOKEN, response.data.data.token);
          dispatch(getProfileDetails());
          //     //  const { data: { message } } = await apiAxiosInstance.post(`/create-profile`, newPayload);
          //     //  toast.success(message);
          //     //  dispatch({
          //     //     type: ACTIONS.SET_SIGNUP_STEP,
          //     //     step: SIGNUP_STEPS.PREVIEW_CROPPED_PROFILE_PHOTO,
          //     //     generalStep: GENERAL_DETAILS_STEP.ADD_VERIFY_EMAIL,
          //     // });
        }
        return response?.data;
      }
    }
  };

export {
  login,
  logout,
  signup,
  sendOtp,
  verifyOtp,
  changePassword,
  signupWithGoogle,
  existingGoogleProfile,
  sendOtpForgotPassword,
  verifyOtpForgotPassword,
  forgotPassword,
};
