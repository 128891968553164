import { connect, useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { useFormik } from "formik";
import { useEffect, useState } from "react";

import "./style.css";
import { RootReducer } from "../../../interfaces/reducer";
import { EDIT_PROFILE_MODAL, THEME } from "../../../utils/constants";
import { ACTIONS } from "../../../redux/actions";
import { UpdateProfilePayload } from "../../../interfaces/user";
import { AppDispatch } from "../../../redux/store";
import { UserAction } from "../../../redux/thunk";
import MultiRangeSlider from "../../MultiRangeSlider";

interface Props extends DispatchToProps {}

interface OpenDropdowns {
  bodyType: boolean;
  height: boolean;
  ethnicity: boolean;
  relationship_status: boolean;
  education: boolean;
  is_smoker: boolean;
  is_drinker: boolean;
  maximum_height: boolean;
  hair: boolean;
  gender: boolean;
  looking_for: boolean;
}

const EditOtherInfo = (props: Props) => {
  const dispatch = useDispatch();

  const { editProfileModal, profile } = useSelector(
    (state: RootReducer) => state.user
  );
  const {
    bodyType,
    theme,
    ethnicity,
    relationshipStatus,
    education,
    smokerStatus,
    drinkerStatus,
    hair,
  } = useSelector((state: RootReducer) => state.common);
  const [fromAge, setFromAge] = useState<Number>(18);
  const [toAge, setToAge] = useState<Number>(60);
  const [openDropdowns, setOpenDropdowns] = useState<OpenDropdowns>({
    bodyType: false,
    height: false,
    ethnicity: false,
    relationship_status: false,
    education: false,
    is_smoker: false,
    is_drinker: false,
    maximum_height: false,
    hair: false,
    gender: false,
    looking_for: false,
  });

  const closeModal = () => {
    dispatch({ type: ACTIONS.SET_PROFILE_MODAL, profileModal: "" });
  };

  const toggleDropdown = (dropdown: keyof OpenDropdowns) => {
    setOpenDropdowns((prev) => ({
      ...prev,
      [dropdown]: !prev[dropdown],
    }));
  };

  const handleItemClick = (
    dropdown: keyof OpenDropdowns,
    field: string,
    id: number,
    value: string
  ) => {
    editOtherInfoFormik.setFieldValue(field + "Id", id);
    editOtherInfoFormik.setFieldValue(field, value);
    setOpenDropdowns((prev) => ({
      ...prev,
      [dropdown]: false,
    }));
  };

  const editOtherInfoFormik = useFormik({
    initialValues: {
      networth: "",
      annualIncome: "",
      ethnicity: "",
      relationship_status: "",
      maximum_height: 0,
      education: "",
      is_smoker: "",
      is_drinker: "",
      bodyType: "",
      bodyTypeId: 0,
      ethnicityId: 0,
      hair: "",
      gender: 1,
      hairId: 0,
      relationship_statusId: 0,
      networthId: 0,
      annualIncomeId: 0,
      is_smokerId: 0,
      is_drinkerId: 0,
      educationId: 0,
      fromAge: 18,
      toAge: 60,
    },
    onSubmit: (values) => {
      const payload = {
        net_worth: values.networthId,
        annual_income: values.annualIncomeId,
        ethnicity: values.ethnicityId,
        body_type: values.bodyTypeId,
        relationship_status: values.relationship_statusId,
        educationId: values.educationId,
        maximum_height: values.maximum_height,
        from_age: values.fromAge,
        to_age: values.toAge,
        is_smoker: values.is_smokerId,
        is_drinker: values.is_drinkerId,
      };
      props.updateProfile(payload);
    },
  });

  useEffect(() => {
    if (profile && Object.keys(profile).length) {
      setFromAge(profile?.filters?.from_age || 18);
      setToAge(profile?.filters?.to_age || 60);

      editOtherInfoFormik.setValues({
        networth: profile.filters?.net_worth?.name || "",
        ethnicity: profile.filters?.ethnicity?.name || "",
        relationship_status: profile.filters?.relationship_status?.name || "",
        education: profile.filters?.education?.name || "",
        is_smoker: profile.filters?.is_smoker?.name || "",
        is_drinker: profile.filters?.is_drinker?.name || "",
        maximum_height: profile.filters?.maximum_height!,
        annualIncome: profile.filters?.annual_income?.name || "",
        annualIncomeId: profile.filters?.annual_income?.id || 0,
        gender: profile.gender || 0,
        hair: profile.filters?.hair?.name || "",
        hairId: profile.filters?.hair?.id || 0,
        relationship_statusId: profile.filters?.relationship_status?.id || 0,
        is_smokerId: profile.filters?.is_smoker?.id || 0,
        is_drinkerId: profile.filters?.is_drinker?.id || 0,
        educationId: profile.filters?.education?.id || 0,
        ethnicityId: profile.filters?.ethnicity?.id || 0,
        bodyType: profile.filters?.body_type?.name || "",
        bodyTypeId: profile.filters?.body_type?.id || 0,
        networthId: profile.filters?.net_worth?.id || 0,
        fromAge: profile?.from_age || 18,
        toAge: profile?.to_age || 60,
      });
    }
  }, [profile]);

  const handleAgeChange = (ages: [Number, Number]) => {
    editOtherInfoFormik.setFieldValue("fromAge", ages[0]);
    editOtherInfoFormik.setFieldValue("toAge", ages[1]);
    setFromAge(ages[0]);
    setToAge(ages[1]);
  };

  return (
    <div
      className={`modal login-modal ${
        editProfileModal === EDIT_PROFILE_MODAL.EDIT_OTHER_INFO ? "d-block" : ""
      }`}
    >
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content Edit_your_info">
          <button
            type="button"
            className={`btn-close ${
              theme === THEME.DARK ? "btn-close-white" : ""
            } close`}
            onClick={closeModal}
          ></button>
          <div className="modal-header">
            <h1>Edit info</h1>
          </div>

          <div className="modal-body px-0 pb-0 collapse-edit">
            <div className="form-sec">
              <div className="row">
                <div className="edi_info_user">
                  <div className="row">
                    {/* <div className="col-md-12">
                      <div className="mb-3">
                        <label className="form-label">Networth</label>
                        <h2 className="accordion-header" id="headingOne">
                          <button
                            className="info-accordion-button justify-content-between"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            {editOtherInfoFormik.values.networth}
                            <FontAwesomeIcon icon={faAngleDown} />
                          </button>
                        </h2>
                        <div
                          id="collapseOne"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingOne"
                          data-bs-parent="#accordionExample"
                        >
                          {netWorth?.map((item) => (
                            <div className="accordion-body" key={item.id}>
                              <label
                                className="cursor-pointer"
                                onClick={() => {
                                  editOtherInfoFormik.setFieldValue(
                                    "networthId",
                                    item.id
                                  );
                                  editOtherInfoFormik.setFieldValue(
                                    "networth",
                                    item.name
                                  );
                                }}
                              >
                                {item.name}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div> */}

                    {/* <div className="col-md-12">
                                            <div className="mb-3">
                                                <label className="form-label">Annual Income</label>
                                                <h2 className="accordion-header" id="headingOne">
                                                    <button className="info-accordion-button justify-content-between" type="button" data-bs-toggle="collapse" data-bs-target="#collapsetwo" aria-expanded="true" aria-controls="collapseOne">
                                                        {editOtherInfoFormik.values.annualIncome}
                                                        <FontAwesomeIcon icon={faAngleDown} />
                                                    </button>

                                                </h2>
                                                <div id="collapsetwo" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                    {annualIncome.map(item =>
                                                        <div className="accordion-body" key={item.id}>
                                                            <label

                                                                onClick={() => {
                                                                    editOtherInfoFormik.setFieldValue('annualIncomeId', item.id);
                                                                    editOtherInfoFormik.setFieldValue('annualIncome', item.name);
                                                                }}
                                                            >
                                                                {item.name}
                                                            </label>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div> */}

                    {/* <div className="col-md-12">
                      <div className="mb-3">
                        <label className="form-label">Looking for</label>

                        <div className="looking_check">
                          {gender.map((item) => (
                            <label key={item.id} className="user_checkprofile">
                              {item.name}
                              <input
                                type="checkbox"
                                checked={
                                  editOtherInfoFormik.values.genderId ===
                                  item.id
                                }
                                onChange={() => {
                                  editOtherInfoFormik.setFieldValue(
                                    "genderId",
                                    item.id
                                  );
                                  editOtherInfoFormik.setFieldValue(
                                    "gender",
                                    item.name
                                  );
                                }}
                              />
                              <span className="checkmark"></span>
                            </label>
                          ))}
                        </div>
                      </div>
                    </div> */}

                    {/* <div className="col-md-12">
                      <div className="mb-3">
                        <label className="form-label">Height</label>
                        <div className="distance my-1">
                          <span className="d-flex align-items-center my-3">
                            <h6>
                              <span>
                                {editOtherInfoFormik.values.maximum_height ===
                                213
                                  ? "213+"
                                  : editOtherInfoFormik.values.maximum_height}
                                cm(s)
                              </span>
                            </h6>
                          </span>
                          <input
                            className="w-100"
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              editOtherInfoFormik.setFieldValue(
                                "maximum_height",
                                e.target.value
                              );
                            }}
                            defaultValue={
                              editOtherInfoFormik.values.maximum_height
                            }
                            min={134}
                            max={213}
                            type="range"
                          />
                        </div>
                      </div>
                    </div> */}

                    <div className="col-md-12">
                      <div className="mb-3">
                        <label className="form-label">Preferred Ages</label>
                        <h5 className="age">
                          {String(fromAge)} - {String(toAge)}
                        </h5>
                        <MultiRangeSlider
                          min={18}
                          max={60}
                          handleSlideChange={handleAgeChange}
                          defaultMax={Number(toAge)}
                          defaultMin={Number(fromAge)}
                          step={1}
                        />
                        <h3>
                          <span>Optional.</span> Your preferred ages will appear
                          on your profile, but users outside of your range can
                          still message you.
                        </h3>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="mb-3">
                        <label className="form-label">Gender</label>
                        <h2 className="accordion-header" id="headingOne">
                          <button
                            className="info-accordion-button justify-content-between"
                            type="button"
                            aria-expanded={openDropdowns.gender}
                            aria-controls="collapsethree"
                            disabled
                          >
                            {editOtherInfoFormik.values.gender === 1
                              ? "Woman"
                              : editOtherInfoFormik.values.gender === 2
                              ? "Man"
                              : "Other"}
                            <FontAwesomeIcon icon={faAngleDown} />
                          </button>
                        </h2>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="mb-3">
                        <label className="form-label">Looking for</label>
                        <h2 className="accordion-header" id="headingOne">
                          <button
                            className="info-accordion-button justify-content-between"
                            type="button"
                            aria-expanded={openDropdowns.looking_for}
                            aria-controls="collapsethree"
                            disabled
                          >
                            {editOtherInfoFormik.values.gender === 1
                              ? "Man"
                              : editOtherInfoFormik.values.gender === 2
                              ? "Woman"
                              : "Other"}
                            <FontAwesomeIcon icon={faAngleDown} />
                          </button>
                        </h2>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="mb-3">
                        <label className="form-label">Ethnicity</label>
                        <h2 className="accordion-header" id="headingOne">
                          <button
                            className="info-accordion-button justify-content-between"
                            type="button"
                            aria-expanded={openDropdowns.ethnicity}
                            aria-controls="collapsethree"
                            onClick={() => toggleDropdown("ethnicity")}
                          >
                            {editOtherInfoFormik.values.ethnicity}
                            <FontAwesomeIcon icon={faAngleDown} />
                          </button>
                        </h2>
                        <div
                          id="collapsethree"
                          className={`accordion-collapse collapse ${
                            openDropdowns.ethnicity ? "show" : ""
                          }`}
                          aria-labelledby="headingOne"
                          data-bs-parent="#accordionExample"
                        >
                          {ethnicity.map((item) => (
                            <div className="accordion-body" key={item.id}>
                              <label
                                onClick={() =>
                                  handleItemClick(
                                    "ethnicity",
                                    "ethnicity",
                                    item.id,
                                    item.name
                                  )
                                }
                              >
                                {item.name}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="mb-3">
                        <label className="form-label">Relationship</label>
                        <h2 className="accordion-header" id="headingOne">
                          <button
                            className="info-accordion-button justify-content-between"
                            type="button"
                            aria-expanded={openDropdowns.relationship_status}
                            aria-controls="collapsethree"
                            onClick={() =>
                              toggleDropdown("relationship_status")
                            }
                          >
                            {editOtherInfoFormik.values.relationship_status}
                            <FontAwesomeIcon icon={faAngleDown} />
                          </button>
                        </h2>
                        <div
                          id="collapsethree"
                          className={`accordion-collapse collapse ${
                            openDropdowns.relationship_status ? "show" : ""
                          }`}
                          aria-labelledby="headingOne"
                          data-bs-parent="#accordionExample"
                        >
                          {relationshipStatus.map((item) => (
                            <div className="accordion-body" key={item.id}>
                              <label
                                onClick={() =>
                                  handleItemClick(
                                    "relationship_status",
                                    "relationship_status",
                                    item.id,
                                    item.name
                                  )
                                }
                              >
                                {item.name}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="mb-3">
                        <label className="form-label">Body Type</label>
                        <h2 className="accordion-header" id="headingOne">
                          <button
                            className="info-accordion-button justify-content-between"
                            type="button"
                            aria-expanded={openDropdowns.bodyType}
                            aria-controls="collapsethree"
                            onClick={() => toggleDropdown("bodyType")}
                          >
                            {editOtherInfoFormik.values.bodyType}
                            <FontAwesomeIcon icon={faAngleDown} />
                          </button>
                        </h2>
                        <div
                          id="collapsethree"
                          className={`accordion-collapse collapse ${
                            openDropdowns.bodyType ? "show" : ""
                          }`}
                          aria-labelledby="headingOne"
                          data-bs-parent="#accordionExample"
                        >
                          {bodyType.map((item) => (
                            <div className="accordion-body" key={item.id}>
                              <label
                                onClick={() =>
                                  handleItemClick(
                                    "bodyType",
                                    "bodyType",
                                    item.id,
                                    item.name
                                  )
                                }
                              >
                                {item.name}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="mb-3">
                        <label className="form-label">Education</label>
                        <h2 className="accordion-header" id="headingOne">
                          <button
                            className="info-accordion-button justify-content-between"
                            type="button"
                            aria-expanded={openDropdowns.education}
                            aria-controls="collapsethree"
                            onClick={() => toggleDropdown("education")}
                          >
                            {editOtherInfoFormik.values.education}
                            <FontAwesomeIcon icon={faAngleDown} />
                          </button>
                        </h2>
                        <div
                          id="collapsethree"
                          className={`accordion-collapse collapse ${
                            openDropdowns.education ? "show" : ""
                          }`}
                          aria-labelledby="headingOne"
                          data-bs-parent="#accordionExample"
                        >
                          {education.map((item) => (
                            <div className="accordion-body" key={item.id}>
                              <label
                                onClick={() =>
                                  handleItemClick(
                                    "education",
                                    "education",
                                    item.id,
                                    item.name
                                  )
                                }
                              >
                                {item.name}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="mb-3">
                        <label className="form-label">Hair Color</label>
                        <h2 className="accordion-header" id="headingOne">
                          <button
                            className="info-accordion-button justify-content-between"
                            type="button"
                            aria-expanded={openDropdowns.hair}
                            aria-controls="collapsethree"
                            onClick={() => toggleDropdown("hair")}
                          >
                            {editOtherInfoFormik.values.hair}
                            <FontAwesomeIcon icon={faAngleDown} />
                          </button>
                        </h2>
                        <div
                          id="collapsethree"
                          className={`accordion-collapse collapse ${
                            openDropdowns.hair ? "show" : ""
                          }`}
                          aria-labelledby="headingOne"
                          data-bs-parent="#accordionExample"
                        >
                          {hair.map((item) => (
                            <div className="accordion-body" key={item.id}>
                              <label
                                onClick={() =>
                                  handleItemClick(
                                    "hair",
                                    "hair",
                                    item.id,
                                    item.name
                                  )
                                }
                              >
                                {item.name}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="mb-3">
                        <label className="form-label">Smoking</label>
                        <h2 className="accordion-header" id="headingOne">
                          <button
                            className="info-accordion-button justify-content-between"
                            type="button"
                            aria-expanded={openDropdowns.is_smoker}
                            aria-controls="collapsethree"
                            onClick={() => toggleDropdown("is_smoker")}
                          >
                            {editOtherInfoFormik.values.is_smoker}
                            <FontAwesomeIcon icon={faAngleDown} />
                          </button>
                        </h2>
                        <div
                          id="collapsethree"
                          className={`accordion-collapse collapse ${
                            openDropdowns.is_smoker ? "show" : ""
                          }`}
                          aria-labelledby="headingOne"
                          data-bs-parent="#accordionExample"
                        >
                          {smokerStatus.map((item) => (
                            <div className="accordion-body" key={item.id}>
                              <label
                                onClick={() =>
                                  handleItemClick(
                                    "is_smoker",
                                    "is_smoker",
                                    item.id,
                                    item.name
                                  )
                                }
                              >
                                {item.name}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="mb-3">
                        <label className="form-label">Drinking</label>
                        <h2 className="accordion-header" id="headingOne">
                          <button
                            className="info-accordion-button justify-content-between"
                            type="button"
                            aria-expanded={openDropdowns.is_drinker}
                            aria-controls="collapsethree"
                            onClick={() => toggleDropdown("is_drinker")}
                          >
                            {editOtherInfoFormik.values.is_drinker}
                            <FontAwesomeIcon icon={faAngleDown} />
                          </button>
                        </h2>
                        <div
                          id="collapsethree"
                          className={`accordion-collapse collapse ${
                            openDropdowns.is_drinker ? "show" : ""
                          }`}
                          aria-labelledby="headingOne"
                          data-bs-parent="#accordionExample"
                        >
                          {drinkerStatus.map((item) => (
                            <div className="accordion-body" key={item.id}>
                              <label
                                onClick={() =>
                                  handleItemClick(
                                    "is_drinker",
                                    "is_drinker",
                                    item.id,
                                    item.name
                                  )
                                }
                              >
                                {item.name}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>

                    {/* <div className="col-md-12">
                      <div className="mb-3">
                        <label className="form-label">Height</label>
                        <h2 className="accordion-header" id="headingOne">
                          <button
                            className="info-accordion-button justify-content-between"
                            type="button"
                            aria-expanded={openDropdowns.height}
                            aria-controls="collapsefour"
                            onClick={() => toggleDropdown("height")}
                          >
                            {editOtherInfoFormik.values.height}
                            <FontAwesomeIcon icon={faAngleDown} />
                          </button>
                        </h2>
                        <div
                          id="collapsefour"
                          className={`accordion-collapse collapse ${
                            openDropdowns.height ? "show" : ""
                          }`}
                          aria-labelledby="headingOne"
                          data-bs-parent="#accordionExample"
                        >
                          {height.map((item) => (
                            <div key={item.id} className="accordion-body">
                              <label
                                onClick={() =>
                                  handleItemClick(
                                    "height",
                                    "height",
                                    item.id,
                                    item.inch
                                  )
                                }
                              >
                                {item.inch}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="cancel_save d-flex justify-content-end m-0 w-100">
            <button type="button" onClick={closeModal}>
              Cancel
            </button>
            <button type="button" onClick={editOtherInfoFormik.submitForm}>
              Save Info
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  updateProfile: (payload: UpdateProfilePayload) =>
    dispatch(UserAction.updateProfileDetails(payload)),
});

interface DispatchToProps {
  updateProfile: (payload: UpdateProfilePayload) => void;
}

export default connect(null, mapDispatchToProps)(EditOtherInfo);
