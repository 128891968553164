import { Field, Form, Formik, FormikHelpers } from "formik";
import React from "react";
import { toast } from "react-toastify";
import { apiAxiosInstance } from "../../../config/axios";

interface ReportUserProps {
  closeModalReport: any;
  otherMemberProfile: any;
  refech?: () => void;
}

const ReportUser: React.FC<ReportUserProps> = ({
  closeModalReport,
  otherMemberProfile,
  refech,
}) => {
  const initialValues = {
    reason: "",
    image: "",
  };

  const handleReportUser = async (values: any) => {
    if (!values.reason || !values.image) {
      toast.error("All fields are required.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    try {
      const payload = {
        user_id: otherMemberProfile.id,
        reason: values.reason,
        image: values.image,
      };
      const res = await apiAxiosInstance.post("/report-user", payload);
      toast.success("This member reported successfully.", {
        position: toast.POSITION.TOP_RIGHT,
      });

      // Close the modal after successful report
      closeModalReport();
    } catch (error) {
      console.error("Error reporting user:", error);
      toast.error("There was an error reporting the user.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleImageUpload = async (
    event: any,
    setFieldValue: FormikHelpers<any>["setFieldValue"],
    key: string
  ) => {
    if (event?.currentTarget?.files && event.currentTarget?.files?.length > 0) {
      const uploadedFile = event.currentTarget?.files[0];
      const formData = new FormData();
      formData.append("image", uploadedFile);
      try {
        // Dispatch action to upload image
        const response = await apiAxiosInstance.post("upload/image", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        // Update the Formik field value with the response data
        setFieldValue(key, response?.data?.data[0]?.orig_path_url);
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    }
  };

  return (
    <div
      className="modal edit-modal"
      tabIndex={-1}
      style={{ display: "block" }}
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Report User</h5>
            <button
              type="button"
              className="btn-close"
              onClick={closeModalReport}
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <Formik
              initialValues={initialValues}
              onSubmit={(values, actions) => {
                handleReportUser(values);
                actions.setSubmitting(false);
                if (refech) refech();
              }}
            >
              {({ setFieldValue }) => (
                <Form>
                  <Field
                    as="textarea"
                    name="reason"
                    placeholder="Reason"
                    className="form-control mb-2 h-auto"
                  />
                  <label className="mt-2" htmlFor="imageProof">
                    Image Proof
                  </label>
                  <input
                    type="file"
                    className="form-control file-pos2 proof-input"
                    // id="imageProof"
                    name="image"
                    accept=".png, .jpg, .jpeg"
                    onChange={async (event) => {
                      if (
                        event.currentTarget.files &&
                        event.currentTarget.files.length > 0
                      ) {
                        handleImageUpload(event, setFieldValue, "image");
                      }
                    }}
                  />
                  <div className="modal-footer justify-center">
                    <div className="footer-btn">
                      <button type="submit" className="btn btn-primary mt-0">
                        Report
                      </button>

                      <button
                        type="button"
                        className="btn btn-gry btn-primary mt-0"
                        onClick={closeModalReport}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportUser;
