import { toast } from "react-toastify";

import { apiAxiosInstance } from "../../config/axios";
import { AppDispatch } from "../store";
import { ACTIONS } from "../actions";

const getStaticList = () => async (dispatch: AppDispatch) => {
  try {
    const {
      data: { data },
    } = await apiAxiosInstance.get(`/get-static-data`);

    dispatch({ type: ACTIONS.SET_STATIC_DATA, data });
  } catch (err: any) {
    if (err.response && err.response.data) {
      toast.error(err.response.data.message);
    }
  }
};

const getDatingTags = () => async (dispatch: AppDispatch) => {
  try {
    const {
      data: { data },
    } = await apiAxiosInstance.get(`/dating-tags`);

    dispatch({ type: ACTIONS.SET_DATING_TAGS, data });
  } catch (err: any) {
    if (err.response && err.response.data) {
      toast.error(err.response.data.message);
    }
  }
};
const getPrivacyPolicy = () => async (dispatch: AppDispatch) => {
  const payload = { type: 2 };
  try {
    const {
      data: { data },
    } = await apiAxiosInstance.post(
      `/get-terms-conditions-and-policy`,
      payload
    );
    dispatch({ type: ACTIONS.SET_PRIVACYPOLICY, text: data.privacy_policy });
  } catch (err: any) {
    if (err.response && err.response.data) {
      toast.error(err.response.data.message);
    }
  }
};

const getTermsAndCondition = () => async (dispatch: AppDispatch) => {
  const payload = { type: 1 };
  try {
    const {
      data: { data },
    } = await apiAxiosInstance.post(
      `/get-terms-conditions-and-policy`,
      payload
    );
    dispatch({
      type: ACTIONS.SET_TERMSANDCONDITION,
      text: data.terms_and_conditions,
    });
  } catch (err: any) {
    if (err.response && err.response.data) {
      toast.error(err.response.data.message);
    }
  }
};

const getAboutUs = () => async (dispatch: AppDispatch) => {
  const payload = { type: 1 };
  try {
    const {
      data: { data },
    } = await apiAxiosInstance.post(
      `/get-terms-conditions-and-policy`,
      payload
    );
    dispatch({
      type: ACTIONS.SET_ABOUT_US,
      text: data?.about_us,
    });
  } catch (err: any) {
    if (err.response && err.response.data) {
      toast.error(err.response.data.message);
    }
  }
};

const uploadProfileImage =
  (formData: FormData) => async (dispatch: AppDispatch) => {
    try {
      apiAxiosInstance.interceptors.request.use();

      const {
        data: { data },
      } = await apiAxiosInstance.post(`/upload/image`, formData, {
        headers: {
          "Content-Type": "multipart/form-data", // Use multipart/form-data for file uploads
        },
      });
    } catch (err: any) {
      if (err.response && err.response.data) {
        toast.error(err.response.data.message);
      }
    }
  };

const uploadUserImages =
  (formData: FormData) => async (dispatch: AppDispatch) => {
    try {
      apiAxiosInstance.interceptors.request.use();

      const {
        data: { data },
      } = await apiAxiosInstance.post(`/upload/user/images`, formData, {
        headers: {
          "Content-Type": "multipart/form-data", // Use multipart/form-data for file uploads
        },
      });
    } catch (err: any) {
      if (err.response && err.response.data) {
        toast.error(err.response.data.message);
      }
    }
  };

export {
  getStaticList,
  getDatingTags,
  uploadProfileImage,
  uploadUserImages,
  getPrivacyPolicy,
  getTermsAndCondition,
  getAboutUs,
};
