//@ts-ignore
import  io from "socket.io-client";
import { SOCKET } from "../utils/constants";

export const initializeSocket=(senderId: Number)=>{
    const socket = io.connect(`${process.env.REACT_APP_SOCKET_BASE_URL}?user_id=${senderId}`, {
     
      transports: [SOCKET.WEBSOCKET],
    });

    return socket;

}


