import { toast } from "react-toastify";
import { apiAxiosInstance } from "../../config/axios";
import { ACTIONS } from "../actions";
import { AppDispatch } from "../store";
import {
  MemberListPayload,
  getOtherMemberProfilePayload,
} from "../../interfaces/members";

const getMembers =
  (payload: MemberListPayload) => async (dispatch: AppDispatch) => {
    try {
      dispatch({ type: ACTIONS.SET_MEMBER_LOADING, payload: true });
      const {
        data: { data },
      } = await apiAxiosInstance.post(`/filterSearchUserList`, payload);
      if (payload.sort_by === "created_by") {
        dispatch({ type: ACTIONS.SET_NEWEST_MEMBERS, members: data });
        dispatch({ type: ACTIONS.SET_MEMBER_LOADING, payload: false });
      }
      if (payload.sort_by === "distance") {
        dispatch({ type: ACTIONS.SET_NEAREST_MEMBERS, members: data });
        dispatch({ type: ACTIONS.SET_MEMBER_LOADING, payload: false });
      }
      if (payload.sort_by === "online_status") {
        dispatch({ type: ACTIONS.SET_RECENT_ACTIVE_MEMBERS, members: data });
        dispatch({ type: ACTIONS.SET_MEMBER_LOADING, payload: false });
      }
    } catch (err: any) {
      dispatch({ type: ACTIONS.SET_MEMBER_LOADING, payload: false });
      if (err.response && err.response.data) {
        toast.error(err.response.data.message);
      }
    }
  };

const getFilteredMembers = (payload: any) => async (dispatch: AppDispatch) => {
  try {
    dispatch({ type: ACTIONS.SET_MEMBER_LOADING, payload: true });
    const {
      data: { data },
    } = await apiAxiosInstance.post(`/filterSearchUserList`, payload);
    dispatch({
      type: ACTIONS.SET_FILTERED_MEMBERS,
      members: data.data,
      limit: data.per_page,
      lastPage: data.last_page,
    });
    dispatch({ type: ACTIONS.SET_MEMBER_LOADING, payload: false });
  } catch (err: any) {
    dispatch({ type: ACTIONS.SET_MEMBER_LOADING, payload: false });
    if (err.response && err.response.data) {
      toast.error(err.response.data.message);
    }
  }
};

const getViewedMembers = (page: number) => async (dispatch: AppDispatch) => {
  try {
    dispatch({ type: ACTIONS.SET_MEMBER_LOADING, status: true });
    const {
      data: { data },
    } = await apiAxiosInstance.get(`/get-users-viewed?page=${page}`);
    dispatch({ type: ACTIONS.SET_VIEWED_MEMBERS, members: data });
    dispatch({ type: ACTIONS.SET_MEMBER_LOADING, status: false });
  } catch (err: any) {
    if (err.response && err.response.data) {
      toast.error(err.response.data.message);
    }
  }
};

const getFavouriteMembers = (page: number) => async (dispatch: AppDispatch) => {
  try {
    dispatch({ type: ACTIONS.SET_MEMBER_LOADING, status: true });
    const {
      data: { data },
    } = await apiAxiosInstance.get(`/get-favourite-users?page=${page}`);

    dispatch({ type: ACTIONS.SET_FAVOURITE_MEMBERS, members: data });
    dispatch({ type: ACTIONS.SET_MEMBER_LOADING, status: false });
  } catch (err: any) {
    if (err.response && err.response.data) {
      toast.error(err.response.data.message);
    }
  }
};

const getFavouriteMeMembers =
  (page: number) => async (dispatch: AppDispatch) => {
    try {
      dispatch({ type: ACTIONS.SET_MEMBER_LOADING, status: true });
      const {
        data: { data },
      } = await apiAxiosInstance.get(`/get-who-favourite-me?page=${page}`);
      dispatch({ type: ACTIONS.SET_FAVOURITEME_MEMBERS, members: data });
      dispatch({ type: ACTIONS.SET_MEMBER_LOADING, status: false });
    } catch (err: any) {
      if (err.response && err.response.data) {
        toast.error(err.response.data.message);
      }
    }
  };

const getMemberProfile =
  (payload: getOtherMemberProfilePayload) => async (dispatch: AppDispatch) => {
    try {
      const {
        data: { data },
      } = await apiAxiosInstance.post(`/get-other-profile`, payload);
      dispatch({ type: ACTIONS.SET_OTHERMEMBER_PROFILE, member: data });
    } catch (err: any) {
      if (err.response && err.response.data) {
        toast.error(err.response.data.message);
      }
    }
  };

const addToFavourite = (userId: number) => async (dispatch: AppDispatch) => {
  try {
    const { data } = await apiAxiosInstance.post(`/add-to-favourite`, {
      user_id: userId,
    });
    dispatch(getFavouriteMeMembers(1));
    dispatch(getViewedMembers(1));
    dispatch(getFavouriteMembers(1));
    return data;
  } catch (err: any) {
    if (err.response && err.response.data) {
      toast.error(err.response.data.message);
    }
  }
};

export {
  getFilteredMembers,
  getMembers,
  getViewedMembers,
  getFavouriteMembers,
  getFavouriteMeMembers,
  getMemberProfile,
  addToFavourite,
};
