import React,{useEffect, useState} from 'react'

import { AppDispatch } from '../../redux/store';
import { CommonAction } from '../../redux/thunk';
import { connect, useSelector } from 'react-redux';
import { RootReducer } from '../../interfaces/reducer';
import Header from '../../components/Header';
// import './style.css'
import Footer from '../../components/Footer';
import Loader from '../Loader/Spinner';

interface Props extends DispatchToProps { };
const PrivacyPolicy = (props:Props) => {
    
    const  privacyPolicy : String = useSelector((state: RootReducer) => state.common.privacyPolicy);

    const [loading, setLoading] = useState(true);


    useEffect(() => {
      const fetchPrivacyPolicy = async () => {
        await props.getPrivacyPolicy();
        setLoading(false); // Set loading to false once data is fetched
      };
  
      fetchPrivacyPolicy();
    }, [props]);
    

  return (
    <div className='space-top'>
           
        <div className="privacypolicy policy-design">
       <div className='container'> {loading ? (
          <Loader /> 
        ) : (   privacyPolicy !== '' && (
          <div dangerouslySetInnerHTML={{ __html: privacyPolicy as string }} />
        )
      )}</div>
     </div>
     <Footer/>
    </div>
  )
}

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    getPrivacyPolicy: () => dispatch(CommonAction.getPrivacyPolicy()),
    
 });
 
 interface DispatchToProps {
    
     getPrivacyPolicy: () => void;
 };
 
 export default connect(null, mapDispatchToProps)(PrivacyPolicy);  