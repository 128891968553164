import ReactPaginate from "react-paginate";
import "./style.css";

const ReactPagination = (props) => {
  // Function to handle page click
  const handlePageClick = (event) => {
    if (props?.page) {
      props?.page(event?.selected);
    }
    // Calculate the new offset based on the selected page
    const newOffset = event?.selected + 1;
    props.newOffset(newOffset);
  };

  return (
    <div className="react-pagination-btm">
      <ReactPaginate
        breakLabel="..."
        nextLabel=">"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={props?.totalPage || 1}
        previousLabel="<"
        renderOnZeroPageCount={null}
      />
    </div>
  );
};

export default ReactPagination;
