/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useRef, useState, RefObject } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Header from "../../components/Header";
import "./style.css";
import {
  Clock,
  IDVerfication,
  LocUser,
  UploadPro,
  UserProfile,
} from "../../assets/images";
import Footer from "../../components/Footer";
import { AppDispatch } from "../../redux/store";
import {
  AuthAction,
  CommonAction,
  MemberAction,
  UserAction,
} from "../../redux/thunk";
import { GalleryImages, RootReducer } from "../../interfaces/reducer";
import { ACTIONS } from "../../redux/actions";
import { Constants, EDIT_PROFILE_MODAL } from "../../utils/constants";
import { apiAxiosInstance } from "../../config/axios";
import {
  DeleteUserImageIn,
  UpdateProfilePayload,
  VerifyEmailIn,
} from "../../interfaces/user";
import { getOtherMemberProfilePayload } from "../../interfaces/members";
import UploadModal from "../../components/Modals/Upload";
import ImageGallery from "../../components/PrivateImage";
import OptionsMenu from "../../components/Modals/Profile/OptionMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import Women from "./../../assets/images/femaleProfile@3x.png";
import Men from "./../../assets/images/maleProfile@3x.png";
import Common from "./../../assets/images/dummyProfile.png";
import { Modal } from "react-bootstrap";
import EditPhone from "./EditPhone";
import { SendOtpPayload } from "../../interfaces/auth";
import Loader from "../../components/Loader/Spinner";
import EditEmail from "./EditEmail";

interface Props extends DispatchToProps {}

const Profile = (props: Props) => {
  const location = useLocation();
  const [userId, setuserId] = useState("");
  const [validator] = useState(true);
  const [visible, setVisible] = useState(false);
  const [memberProfile, setmemberProfile] = useState<any>();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const inputFileRef = useRef<HTMLInputElement>();
  const inputProfileRef = useRef<HTMLInputElement>();
  const [showModal, setShowModal] = useState(false);
  const [imagePicker, setImagePicker] = useState("");
  const [privacySetting, setPrivacySetting] = useState<"private" | "public">(
    "public"
  );
  const [requestedPhotoId] = useState<number | null>(null);
  const { socket } = useSelector((state: RootReducer) => state.common);
  const { profile } = useSelector((state: RootReducer) => state.user);
  const otherMemberProfile = useSelector(
    (state: RootReducer) => state.members.otherMemberProfile
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dob, setDob] = useState("");
  const [loader, setLoader] = useState(false);
  const [emailOpen, setEmailOpen] = useState(false);

  const handleEditClick = () => {
    setIsModalOpen(true);
  };

  const handleEditEmail = () => {
    setEmailOpen(true);
  };

  useEffect(() => {
    const getProfileDetail = async () => {
      setLoader(true);
      if (userId) {
        await props.getOtherMemberProfile({ user_id: +userId! });
      }
      setLoader(false);
    };
    getProfileDetail();
  }, [userId]);

  useEffect(() => {
    if (profile?.id) {
      dispatch({
        type: ACTIONS.INITIALIZE_SOCKET,
        senderId: Number(profile.id),
      });
    }
    if (profile?.id || memberProfile?.id) {
      setDob(moment(profile?.dob || memberProfile?.dob).format("YYYY-MM-DD"));
    }
  }, [profile, memberProfile, dispatch]);

  const handleOptionSelect = (option: "private" | "public") => {
    setShowModal(false);
    setPrivacySetting(option);
    if (imagePicker === "profile") {
      if (inputProfileRef.current) {
        inputProfileRef.current.click();
      }
    } else {
      if (inputFileRef.current) {
        inputFileRef.current.click();
      }
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && profile?.id) {
      let formData = new FormData();
      let imageURLS = [];
      for (let i = 0; i <= files.length; i++) {
        const file = files[i];
        formData.append("image", file);
        if (!file) break;
        const {
          data: { data },
        } = await apiAxiosInstance.post(`/upload/image`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        const isPrivate = privacySetting === "private" ? 1 : 0;
        const isPublic = privacySetting === "public" ? 1 : 0;
        imageURLS.push({
          ...data[0],
          is_private: isPrivate,
          is_profile_image_public: isPublic,
        });
      }
      const payload = {
        photos: imageURLS,
      };
      try {
        setLoader(true);
        await props.updateProfile(payload);
        setLoader(false);
        setShowModal(false); // Close the modal after uploading
        if (inputFileRef.current) {
          inputFileRef.current.value = ""; // Reset the file input to allow re-uploading
        }
      } catch (error) {
        console.error("Profile update failed:", error);
      }
    }
  };

  useEffect(() => {
    const getDetails = async () => {
      setLoader(true);
      await props.getProfileDetails();
      await props.staticList();
      setLoader(false);
    };
    window.scrollTo(0, 0);
    if (!location.pathname.split("/")[2]) {
      setVisible(true);
    } else {
      setuserId(location.pathname.split("/")[2]);
    }
    getDetails();
  }, []);

  useEffect(() => {
    const getProfile = async () => {
      setLoader(true);
      await props.getOtherMemberProfile({ user_id: Number(userId) });
      setLoader(false);
    };
    const id = profile?.filters?.user_id;
    if (userId !== "" && Number(id) === Number(userId)) {
      setVisible(true);
    } else if (userId !== "") {
      getProfile();
    }
  }, [profile]);

  useEffect(() => {
    setmemberProfile(otherMemberProfile);
  }, [otherMemberProfile]);

  const handleSendMessageClick = () => {
    // Redirect to chat message page when "Message" button is clicked
    navigate(`/chat/${userId}`);
  };

  const handleAddToFavorites = async (userId: number) => {
    setLoader(true);
    await props.addToFavourite(userId);
    await props.getOtherMemberProfile({ user_id: Number(userId) });
    setLoader(false);
    const message =
      otherMemberProfile?.is_favourite === 1
        ? "Unfavourite Successfully."
        : "Favourite Successfully.";
    toast.success(message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const _onPressRequest = (photoId: number) => {
    let socketMessage = {
      sender_id: profile?.id,
      user_id: otherMemberProfile?.id,
      receiver_id: otherMemberProfile?.id,
      _id: profile?.id,
      text: `${profile?.user_name} request for a private photos`,
      message: `${profile?.user_name} request for a private photos`,
      type: "text",
      is_request: 1,
      createdAt: String(new Date()),
      user: {
        _id: profile?.id,
        name: profile?.user_name,
        avatar: profile?.profile_image,
      },
    };
    socket.emit("sendMessage", socketMessage, (response: any, err: any) => {
      toast.success("Request Sent Successfully");
    });
  };

  const handleRefetch = React.useCallback(async () => {
    setLoader(true);
    await props.getOtherMemberProfile({ user_id: otherMemberProfile.id! });
    setLoader(false);
  }, [otherMemberProfile.id, props]);

  const sendVerifyEmailRequest = async () => {
    setLoader(true);
    const data = { email: profile?.email! };
    props.sendEmailRequest(data);
    setLoader(false);
  };

  const removeImage = (img: GalleryImages) => {
    const data = { image_ids: img?.id?.toString()! };
    setLoader(true);
    props.removeUserImage(data);
    setLoader(false);
  };

  const handleProfileChange = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = e.target.files;
    if (files && profile?.id) {
      let formData = new FormData();
      let imageURLS = [];
      for (let i = 0; i <= files.length; i++) {
        const file = files[i];
        formData.append("image", file);
        if (!file) break;
        const {
          data: { data },
        } = await apiAxiosInstance.post(`/upload/image`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        imageURLS.push({
          ...data[0],
        });
      }
      setShowModal(false);
      const payload = {
        is_profile_image_public: privacySetting === "private" ? 0 : 1,
        profile_image: imageURLS?.[0]?.thumb_path_url,
        profile_image_thumb: imageURLS?.[0]?.orig_path_url,
      };
      try {
        setLoader(true);
        await props.updateProfile(payload);
        setLoader(false);
        if (inputProfileRef.current) {
          inputProfileRef.current.value = ""; // Reset the file input to allow re-uploading
        }
        if (inputFileRef.current) {
          inputFileRef.current.value = ""; // Reset the file input to allow re-uploading
        }
      } catch (error) {
        console.error("Profile update failed:", error);
      }
    }
  };

  const handleDobChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setDob(e.target.value);
    setLoader(true);
    await props.updateProfile({ dob: e.target.value });
    setLoader(false);
  };

  return (
    <Fragment>
      <Header />
      {loader && <Loader />}
      <div className="container-fluid top-space p-0">
        {validator ? (
          <section className=" main-sec user_profile">
            <div className="bg_banner"> </div>
            <div className="container">
              <div className="row mt-130">
                <div className="col-lg-3 col-md-3 col-sm-12">
                  <div className="img-profile-sec position-relative">
                    <img
                      className="user_imgpro img-fluid"
                      src={
                        visible
                          ? profile?.profile_image
                            ? profile?.profile_image
                            : memberProfile?.gender === 1
                            ? Women
                            : memberProfile?.gender === 2
                            ? Men
                            : Common
                          : memberProfile?.is_profile_image_approved
                          ? memberProfile.profile_image
                          : memberProfile?.gender === 1
                          ? Women
                          : memberProfile?.gender === 2
                          ? Men
                          : Common
                      }
                      alt="Profile"
                      onError={(e) => {
                        const target = e.target as HTMLImageElement;
                        target.onerror = null;
                        target.src = UserProfile;
                      }}
                    />
                    {visible && (
                      <span
                        className="add-photos cursor-pointer text_change"
                        onClick={() => {
                          setShowModal(true);
                          setImagePicker("profile");
                        }}
                        style={{
                          display: "inline-block",
                          verticalAlign: "top",
                        }}
                      >
                        Change image
                        <input
                          type="file"
                          ref={inputProfileRef as RefObject<HTMLInputElement>}
                          className="d-none"
                          accept="image/*"
                          onChangeCapture={handleProfileChange}
                          multiple={true}
                        />
                      </span>
                    )}
                    {!visible && (
                      <>
                        <button
                          type="button"
                          className="btn btn-chat user-list"
                          onClick={() =>
                            navigate(`/chat/${otherMemberProfile?.id}`)
                          }
                        >
                          <i className="far fa-comment-dots"></i>
                        </button>
                        <button
                          className="btn btn-heart"
                          onClick={() => handleAddToFavorites(Number(userId))}
                        >
                          {otherMemberProfile?.is_favourite === 1 ? (
                            <FontAwesomeIcon
                              icon={faHeart}
                              style={{
                                color: "red",
                                width: "30px",
                                height: "30px",
                              }}
                            />
                          ) : (
                            <FontAwesomeIcon
                              icon={faHeart}
                              style={{
                                color: "white",
                                width: "30px",
                                height: "30px",
                              }}
                            />
                          )}
                        </button>
                      </>
                    )}
                  </div>
                  <div className="side_useprofile">
                    <span>
                      <img src={Clock} alt="" className="img-fluid" />
                      Member Since
                    </span>
                    <span>
                      {visible
                        ? moment(profile?.created_at).format("MMM DD, YYYY")
                        : moment(memberProfile?.created_at).format(
                            "MMM DD, YYYY"
                          )}
                    </span>
                  </div>
                  {visible && (
                    <div className="side_verified bg_bordered">
                      <span>
                        <img src={IDVerfication} alt="" className="img-fluid" />
                        {profile?.email}
                      </span>
                      <div>
                        {profile?.is_email_verified === 1 ? (
                          <span className="verify_b">Verified</span>
                        ) : (
                          <button
                            type="button"
                            className="btn btn-verify"
                            onClick={() => sendVerifyEmailRequest()}
                          >
                            Please Verify
                          </button>
                        )}
                        <button
                          type="button"
                          className="btn btn-verify"
                          onClick={() => handleEditEmail()}
                        >
                          Edit
                        </button>
                        <Modal
                          show={emailOpen}
                          onHide={() => setEmailOpen(false)}
                        >
                          <EditEmail
                            props={props}
                            onClose={() => setEmailOpen(false)}
                          />
                        </Modal>
                      </div>
                    </div>
                  )}
                  {visible && (
                    <div>
                      {profile?.phone_number && (
                        <div className="p_edit bg_bordered">
                          <div>{`+${profile?.country_code}${profile?.phone_number}`}</div>
                          <span
                            style={{ marginLeft: "10px", cursor: "pointer" }}
                            onClick={handleEditClick}
                          >
                            Edit
                          </span>
                        </div>
                      )}
                      <Modal
                        show={isModalOpen}
                        onHide={() => setIsModalOpen(false)}
                      >
                        <EditPhone
                          props={props}
                          onClose={() => setIsModalOpen(false)}
                        />
                      </Modal>
                    </div>
                  )}
                  {visible && (
                    <div>
                      {profile?.dob && (
                        <div className="col-md-12">
                          <div className="date_b bg_bordered">
                            <label>DOB : </label>
                            <input
                              className="dobInput form-control"
                              type="date"
                              name="dob"
                              placeholder="Enter your birth date"
                              onChange={handleDobChange}
                              value={dob}
                              max={
                                moment(new Date())
                                  .subtract(18, "y")
                                  .toJSON()
                                  .split("T")[0]
                              }
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <div className="col-lg-9 col-md-9 col-sm-12">
                  <div className="right_profilename drop-ic">
                    <span>
                      <h3 className="text-white mb-1">
                        {visible
                          ? profile?.user_name
                          : memberProfile?.is_name_approved
                          ? memberProfile?.user_name
                          : Constants.newUser}
                      </h3>
                      <h5 className="text-white mb-1">
                        {visible ? profile?.headline : memberProfile?.headline}
                      </h5>
                      <h5 className="text-white">
                        <img src={LocUser} alt="" className="img-fluid" />
                        {visible ? profile?.location : memberProfile?.location}.
                        {visible ? profile?.age : memberProfile?.age}
                        {`(${
                          (visible && profile?.gender === 1) ||
                          (!visible && memberProfile?.gender === 1)
                            ? "F"
                            : (visible && profile?.gender === 2) ||
                              (!visible && memberProfile?.gender === 2)
                            ? "M"
                            : "O"
                        })`}
                      </h5>
                    </span>
                    {!visible && (
                      <OptionsMenu
                        otherMemberProfile={otherMemberProfile}
                        refech={handleRefetch}
                      />
                    )}
                    {visible ? (
                      <p
                        className="text-white cursor-pointer text-decoration-none"
                        onClick={() =>
                          dispatch({
                            type: ACTIONS.SET_PROFILE_MODAL,
                            profileModal:
                              EDIT_PROFILE_MODAL.EDIT_PERSONAL_DETAILS,
                          })
                        }
                      >
                        Edit
                      </p>
                    ) : (
                      <></>
                    )}
                  </div>
                  {/* {!visible && (
                    <div
                      className="container-fluid p-95  p-4 rounded"
                      style={{
                        backgroundColor: "#F2F2F2",
                        marginBottom: "0px",
                      }}
                    >
                      <div className="row">
                        <div className="col">
                          <div className="form-group">
                            <textarea
                              className="form-control mb-2"
                              placeholder={`Message ${
                                memberProfile?.is_name_approved
                                  ? memberProfile?.user_name
                                  : Constants.newUser
                              }`}
                              disabled
                              style={{
                                resize: "none",
                                overflowY: "hidden",
                                minHeight: "100px",
                              }}
                            />
                          </div>
                          <div className="d-flex justify-content-start">
                            <button
                              className="btn btn-primary mr-2 mt-2 btn-meassage"
                              onClick={handleSendMessageClick}
                            >
                              Message
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )} */}
                  {((!visible && memberProfile?.is_photo_gallery_approved) ||
                    visible) && (
                    <div
                      className="upload_profile"
                      style={{ overflowX: "auto", whiteSpace: "nowrap" }}
                    >
                      <ImageGallery
                        photos={
                          visible ? profile?.photos : memberProfile?.photos
                        }
                        onRequest={_onPressRequest}
                        isOwner={visible}
                        removeImage={removeImage}
                        imageAccess={!!memberProfile?.is_access_given}
                      />
                      {requestedPhotoId && (
                        <p>Request sent for photo ID: {requestedPhotoId}</p>
                      )}
                      {visible && (
                        <span
                          className="add-photos cursor-pointer"
                          onClick={() => {
                            setShowModal(true);
                            setImagePicker("file");
                          }}
                          style={{
                            display: "inline-block",
                            verticalAlign: "top",
                          }}
                        >
                          <img src={UploadPro} alt="" className="img-fluid" />
                          <h6>Add more photos</h6>
                          <input
                            type="file"
                            ref={inputFileRef as RefObject<HTMLInputElement>}
                            className="d-none"
                            accept="image/*"
                            onChangeCapture={handleFileChange}
                            multiple={true}
                          />
                        </span>
                      )}
                    </div>
                  )}
                  {showModal && (
                    <UploadModal
                      onOptionSelect={handleOptionSelect}
                      onClose={handleCloseModal}
                    />
                  )}
                  <div className="nameproifle">
                    <div className="name_proifle">
                      <span>
                        {visible
                          ? profile?.user_name
                          : memberProfile?.is_name_approved
                          ? memberProfile?.user_name
                          : Constants.newUser}
                        ’s Info
                      </span>
                      {visible ? (
                        <p
                          className="text-white text-decoration-none cursor-pointer"
                          onClick={() =>
                            dispatch({
                              type: ACTIONS.SET_PROFILE_MODAL,
                              profileModal: EDIT_PROFILE_MODAL.EDIT_OTHER_INFO,
                            })
                          }
                        >
                          Edit
                        </p>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="list_nameprofile">
                      <table>
                        <tbody>
                          <tr>
                            <td>Height</td>
                            <td>
                              {visible
                                ? profile?.filters?.maximum_height + " cms"
                                : memberProfile?.filters?.maximum_height +
                                  " cms"}
                            </td>
                          </tr>
                          {/* <tr>
                            <td>Gender</td>
                            <td>
                              {(visible && profile?.gender === 1) ||
                              (!visible && memberProfile?.gender === 1)
                                ? "Woman"
                                : (visible && profile?.gender === 2) ||
                                  (!visible && memberProfile?.gender === 2)
                                ? "Man"
                                : "Others"}
                            </td>
                          </tr>
                          <tr>
                            <td>Looking for</td>
                            <td>
                              {(visible && profile?.gender === 1) ||
                              (!visible && memberProfile?.gender === 1)
                                ? "Man"
                                : (visible && profile?.gender === 2) ||
                                  (!visible && memberProfile?.gender === 2)
                                ? "Woman"
                                : "Others"}
                            </td>
                          </tr> */}
                          <tr>
                            <td>Ethnicity</td>
                            <td>
                              {visible
                                ? profile?.filters?.ethnicity?.name
                                : memberProfile?.filters?.ethnicity?.name}
                            </td>
                          </tr>
                          {/* {visible && (
                            <tr>
                              <td>Net worth</td>
                              <td>
                                {visible
                                  ? profile?.filters &&
                                    profile?.filters.net_worth &&
                                    profile?.filters.net_worth.name
                                  : memberProfile?.filters &&
                                    memberProfile?.filters.net_worth &&
                                    memberProfile?.filters.net_worth.name}
                              </td>
                            </tr>
                          )}
                          {visible && (
                            <tr>
                              <td>Annual Income</td>
                              <td>
                                {visible
                                  ? profile?.filters &&
                                    profile?.filters.annual_income &&
                                    profile?.filters.annual_income.name
                                  : memberProfile?.filters &&
                                    memberProfile?.filters.annual_income &&
                                    memberProfile?.filters.annual_income.name}
                              </td>
                            </tr>
                          )} */}
                          <tr>
                            <td>Relationship</td>
                            <td>
                              {visible
                                ? profile?.filters &&
                                  profile?.filters.relationship_status &&
                                  profile?.filters.relationship_status.name
                                : memberProfile?.filters &&
                                  memberProfile?.filters.relationship_status &&
                                  memberProfile?.filters.relationship_status
                                    .name}
                            </td>
                          </tr>
                          {/* {visible && (
                            <tr>
                              <td>Children</td>
                              <td>
                                {visible
                                  ? profile?.filters &&
                                    profile?.filters.is_children &&
                                    profile?.filters.is_children.name
                                  : memberProfile?.filters &&
                                    memberProfile?.filters.is_children &&
                                    memberProfile?.filters.is_children.name}
                              </td>
                            </tr>
                          )} */}
                          <tr>
                            <td>Body Type</td>
                            <td>
                              {visible
                                ? profile?.filters &&
                                  profile?.filters.body_type &&
                                  profile?.filters.body_type.name
                                : memberProfile?.filters &&
                                  memberProfile?.filters.body_type &&
                                  memberProfile?.filters.body_type.name}
                            </td>
                          </tr>
                          <tr>
                            <td>Education</td>
                            <td>
                              {visible
                                ? profile?.filters &&
                                  profile?.filters.education &&
                                  profile?.filters.education.name
                                : memberProfile?.filters &&
                                  memberProfile?.filters.education &&
                                  memberProfile?.filters.education.name}
                            </td>
                          </tr>
                          <tr>
                            <td>Hair Color</td>
                            <td>
                              {visible
                                ? profile?.filters?.hair?.name
                                : memberProfile?.filters?.hair?.name}
                            </td>
                          </tr>
                          {/* {visible && (
                            <tr>
                              <td>Occupation</td>
                              <td>
                                {visible
                                  ? profile?.occupation &&
                                    profile.occupation.name
                                  : memberProfile?.occupation &&
                                    memberProfile?.occupation.name}
                              </td>
                            </tr>
                          )} */}
                          <tr>
                            <td>Smoking</td>
                            <td>
                              {visible
                                ? profile?.filters &&
                                  profile?.filters.is_smoker &&
                                  profile?.filters.is_smoker.name
                                : memberProfile?.filters &&
                                  memberProfile?.filters.is_smoker &&
                                  memberProfile?.filters.is_smoker.name}
                            </td>
                          </tr>
                          <tr className="drinlk">
                            <td>Drinking</td>
                            <td>
                              {visible
                                ? profile?.filters &&
                                  profile?.filters.is_drinker &&
                                  profile?.filters.is_drinker.name
                                : memberProfile?.filters &&
                                  memberProfile?.filters.is_drinker &&
                                  memberProfile?.filters.is_drinker.name}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="me_about">
                    {((!visible && memberProfile?.is_bio_approved) ||
                      visible) && <span>About Me</span>}
                    {visible && (
                      <p
                        className="text-white cursor-pointer"
                        onClick={() =>
                          dispatch({
                            type: ACTIONS.SET_PROFILE_MODAL,
                            profileModal: EDIT_PROFILE_MODAL.EDIT_ABOUT_ME,
                          })
                        }
                      >
                        Edit
                      </p>
                    )}
                  </div>
                  {((!visible && memberProfile?.is_bio_approved) ||
                    visible) && (
                    <>
                      <h4>{visible ? profile?.bio : memberProfile?.bio}</h4>
                    </>
                  )}
                  <div className="me_about">
                    <span>Interested In</span>
                    {visible ? (
                      <p
                        className="text-white cursor-pointer"
                        onClick={() =>
                          dispatch({
                            type: ACTIONS.SET_PROFILE_MODAL,
                            profileModal: EDIT_PROFILE_MODAL.EDIT_INTERESTS,
                          })
                        }
                      >
                        Edit
                      </p>
                    ) : (
                      <></>
                    )}
                  </div>
                  <ul className="Looking_for">
                    {visible
                      ? profile?.user_tags?.map((tag) => (
                          <li className="" key={tag?.tags?.id}>
                            <span>{tag?.tags?.tag_name}</span>
                          </li>
                        ))
                      : memberProfile?.user_tags?.map((tag: any) => (
                          <li className="" key={tag?.tags?.id}>
                            <span>{tag?.tags?.tag_name}</span>
                          </li>
                        ))}
                  </ul>
                </div>
              </div>
            </div>
          </section>
        ) : (
          <></>
        )}
      </div>
      <Footer />
    </Fragment>
  );
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getProfileDetails: () => dispatch(UserAction.getProfileDetails()),
  removeUserImage: (payload: DeleteUserImageIn) =>
    dispatch(UserAction.removeUserImage(payload)),
  sendEmailRequest: (data: VerifyEmailIn) =>
    dispatch(UserAction.verifyEmail(data)),
  staticList: () => dispatch(CommonAction.getStaticList()),
  uploadImages: (formData: FormData) =>
    dispatch(CommonAction.uploadProfileImage(formData)),
  updateProfile: (payload: UpdateProfilePayload) =>
    dispatch(UserAction.updateProfileDetails(payload)),
  getOtherMemberProfile: (payload: getOtherMemberProfilePayload) =>
    dispatch(MemberAction.getMemberProfile(payload)),
  addToFavourite: (userId: number) =>
    dispatch(MemberAction.addToFavourite(userId)),
  sendOtp: (payload: SendOtpPayload) => dispatch(AuthAction.sendOtp(payload)),
  editPhone: (payload: { phone_number: string }) =>
    dispatch(UserAction.updateMobileNumber(payload)),
});

interface DispatchToProps {
  staticList: () => void;
  removeUserImage: (payload: DeleteUserImageIn) => void;
  getProfileDetails: () => void;
  uploadImages: (formData: FormData) => void;
  updateProfile: (payload: UpdateProfilePayload) => void;
  getOtherMemberProfile: (payload: getOtherMemberProfilePayload) => void;
  addToFavourite: (userId: number) => void;
  sendEmailRequest: (data: VerifyEmailIn) => void;
  sendOtp: (payload: SendOtpPayload) => void;
  editPhone: (payload: { phone_number: string }) => void;
}

export default connect(null, mapDispatchToProps)(Profile);
