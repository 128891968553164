/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "react-redux";
import { useFormik } from "formik";
import Autocomplete from "react-google-autocomplete";
import { toast } from "react-toastify";
import "./style.css";
import { MemberFilters, Members, RootReducer } from "../../interfaces/reducer";
import { ACTIONS } from "../../redux/actions";
import { AppDispatch } from "../../redux/store";
import { CommonAction } from "../../redux/thunk";
import { MemberAction } from "../../redux/thunk";
import MultiRangeSlider from "../MultiRangeSlider";
import { Tab, Tabs } from "react-bootstrap";
import MemberListing from "./MemberListing";

interface Props extends MapDispatchToProps {}

const SearchSidebar = (props: Props) => {
  const statics = useSelector((state: RootReducer) => state.common);
  const { profile } = useSelector((state: RootReducer) => state.user);
  const [locationSearchActive, setLocationSearchActive] = useState(false);
  const [fromAge, setFromAge] = useState("18");
  const [search, setSearch] = useState<string>("");
  const [toAge, setToAge] = useState("59");
  const dispatch = useDispatch();
  const [distance, setDistance] = useState("1000");
  const [showToggle, setShowToggle] = useState(false);
  const [activeKey, setActiveKey] = useState<string>("created_at");

  const initValues = {
    latitude: profile?.latitude,
    search: "",
    longitude: profile?.longitude,
    sort_by: "created_at",
    looking_for: [],
    distance: "1000",
    from_age: "",
    to_age: "",
    from_height: 0,
    to_height: 0,
    weight: 0,
    body_type: [],
    hair: [],
    ethnicity: [],
    is_smoker: [],
    is_drinker: [],
    is_children: [],
    education: [],
    dating_tags_to_show: [],
    dating_tags_not_to_show: [],
    relationship_status: [],
    net_worth: [],
    annual_income: [],
    has_photos: 0,
    viewed_me: 0,
    viewed: 0,
    unviewed_me: 0,
    is_recent_active: 0,
    favourited: 0,
    favourited_me: 0,
  };

  useEffect(() => {
    if (activeKey && profile?.id) {
      if (activeKey) {
        searchFormik.values.sort_by = activeKey;
      }
      getUserListing(searchFormik.values);
    }
  }, [activeKey, profile]);

  useEffect(() => {
    props.staticList();
  }, []);

  const handleAge = (ages: any) => {
    searchFormik.values.from_age = String(ages[0]);
    searchFormik.values.to_age = String(ages[1]);
    setFromAge(ages[0]);
    setToAge(ages[1]);
  };

  const searchFormik = useFormik({
    initialValues: initValues,
    onSubmit: (values) => getUserListing(values),
  });

  const getUserListing = async (values: any) => {
    let payload: any = {
      latitude: values?.latitude || profile?.latitude,
      longitude: values?.longitude || profile?.longitude,
      page: values?.page || 1,
      sort_by: values.sort_by,
    };
    if (values.looking_for && values.looking_for.length) {
      payload.looking_for = values.looking_for;
    }
    if (values.body_type && values.body_type.length) {
      payload.body_type = values.body_type;
    }
    if (values.hair && values.hair.length) {
      payload.hair = values.hair;
    }
    if (values.ethnicity && values.ethnicity.length) {
      payload.ethnicity = values.ethnicity;
    }
    if (values.is_smoker && values.is_smoker.length) {
      payload.is_smoker = values.is_smoker;
    }
    if (values.is_drinker && values.is_drinker.length) {
      payload.is_drinker = values.is_drinker;
    }
    if (values.relationship_status && values.relationship_status.length) {
      payload.relationship_status = values.relationship_status;
    }
    if (values.education && values.education.length) {
      payload.education = values.education;
    }
    if (values.dating_tags_to_show && values.dating_tags_to_show.length) {
      payload.dating_tags_to_show = values.dating_tags_to_show;
    }
    if (
      values.dating_tags_not_to_show &&
      values.dating_tags_not_to_show.length
    ) {
      payload.dating_tags_not_to_show = values.dating_tags_not_to_show;
    }
    if (values.is_children && values.is_children.length) {
      payload.is_children = values.is_children;
    }
    if (values.annual_income && values.annual_income.length) {
      payload.annual_income = values.annual_income;
    }
    if (values.net_worth && values.net_worth.length) {
      payload.net_worth = values.net_worth;
    }
    if (values.search && values.search.length) {
      payload.search = values.search;
    }
    if (values.distance && values.distance.length) {
      payload.distance = values.distance;
    }
    if (values.has_photos && values.has_photos === 1) {
      payload.has_photos = values.has_photos;
    }
    if (values.viewed_me && values.viewed_me === 1) {
      payload.viewed_me = values.viewed_me;
    }
    if (values.favourited && values.favourited === 1) {
      payload.favourited = values.favourited;
    }
    if (values.unviewed_me && values.unviewed_me === 1) {
      payload.unviewed_me = values.unviewed_me;
    }
    if (values.is_recent_active && values.is_recent_active === 1) {
      payload.is_recent_active = values.is_recent_active;
    }
    if (values.favourited_me && values.favourited_me === 1) {
      payload.favourited_me = values.favourited_me;
    }
    if (values.from_age && values.from_age.length) {
      payload.from_age = values.from_age;
    }
    if (values.to_age && values.to_age.length) {
      payload.to_age = values.to_age;
    }
    if (values.from_height && values.from_height !== 0) {
      payload.from_height = values.from_height;
    }
    if (values.to_height && values.to_height !== 0) {
      payload.to_height = values.to_height;
    }
    dispatch({ type: ACTIONS.SET_FILTERS_OPTIONS, filter: payload });
    props.membersList(payload);
  };

  const [openAccordion, setOpenAccordion] = useState("collapseTwo");

  const handleAccordionClick = (id: any) => {
    setOpenAccordion(openAccordion === id ? "" : id);
  };

  const handleResetAll = () => {
    searchFormik.resetForm({
      values: initValues,
    });
    setFromAge("18");
    setToAge("59");
    setSearch("");
    setActiveKey("created_at");
    setDistance("1000");
    window.location.reload();
  };

  const handleFavourite = async (user: Members) => {
    const resp = await props.addToFavourite(user?.id!);
    getUserListing(searchFormik?.values);
    if (resp?.success) {
      toast.success(resp.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  return (
    <>
      <div className="col-lg-3 col-md-12 col-sm-12 mx-auto d-block">
        <button
          type="button"
          onClick={() => setShowToggle((prev) => !prev)}
          className="btn btn-filter"
        >
          <i className="fas fa-sort-amount-down "></i>Filters
        </button>
        <div className={`search_top ${showToggle ? "filter-toggle" : ""}`}>
          <div className="reset_search p-1 d-flex justify-content-between align-items-center">
            <span onClick={() => setShowToggle(false)} className="btn-cross">
              <i className="fa fa-times" aria-hidden="true"></i>
            </span>

            <button
              onClick={() => {
                searchFormik.submitForm();
                dispatch({ type: ACTIONS.SET_FILTERED, filtered: true });
              }}
              className="border-0 text-white"
            >
              Search
            </button>
            <h6 onClick={handleResetAll}>Reset All</h6>
          </div>
          <hr />
          <div className="overflow_top pb-4" id="style-2">
            <h5>Profile Text</h5>
            <input
              onChange={(e) => {
                setSearch(e.target.value);
                searchFormik.setFieldValue("search", e.target.value);
              }}
              type="search"
              value={search}
              className="mt-2 mb-3 form-control"
              placeholder="e.g., hiking, shopping... etc"
            />
            <hr />
            <h5>Location</h5>
            <div className="location_set mt-2 mb-4">
              <label className="search_location">
                {profile?.location?.split(",")?.[0]}
                <input
                  defaultChecked
                  onChange={(e) => {
                    e.target.value === "on"
                      ? setLocationSearchActive(false)
                      : setLocationSearchActive(true);
                    searchFormik.setFieldValue("longitude", profile?.longitude);
                    searchFormik.setFieldValue("latitude", profile?.latitude);
                  }}
                  type="radio"
                  name="location"
                />
                <span className="checkmark"></span>
              </label>
              <label className="search_location">
                Live Location
                <input
                  onChange={(e) => {
                    if ("geolocation" in navigator && navigator.geolocation) {
                      navigator.geolocation.getCurrentPosition(
                        (position) => {
                          e.target.value === "on"
                            ? setLocationSearchActive(false)
                            : setLocationSearchActive(true);

                          const { latitude, longitude } = position.coords;
                          searchFormik.setFieldValue(
                            "longitude",
                            String(longitude)
                          );
                          searchFormik.setFieldValue(
                            "latitude",
                            String(latitude)
                          );
                        },
                        (error) => {
                          // Handle the error if no location access or any other error
                          toast.error("Please provide location access.");
                          e.preventDefault();
                        }
                      );
                    } else {
                      toast.error(
                        "Geolocation is not supported or not available."
                      );
                      e.preventDefault();
                    }
                  }}
                  type="radio"
                  name="location"
                />
                <span className="checkmark"></span>
              </label>
              <label className="search_location">
                Other Locations
                <input
                  onChange={(e) => {
                    e.target.value === "on"
                      ? setLocationSearchActive(true)
                      : setLocationSearchActive(false);
                  }}
                  type="radio"
                  name="location"
                />
                <span className="checkmark"></span>
              </label>
              {locationSearchActive ? (
                <Autocomplete
                  apiKey={process.env.REACT_APP_GOOGLE_MAPS_KEY}
                  onPlaceSelected={(place) => {
                    const {
                      geometry: { location },
                    } = place;
                    const latitude = location.lat();
                    const longitude = location.lng();
                    searchFormik.setFieldValue("longitude", longitude);
                    searchFormik.setFieldValue("latitude", latitude);
                  }}
                  style={{
                    height: "50px",
                    borderRadius: "10px",
                    backgroundColor: "#f2f2f2",
                    borderColor: "#f4f4f4",
                    borderWidth: "2px",
                  }}
                />
              ) : (
                <></>
              )}
            </div>

            <h5>Distance</h5>
            <div className="distance mt-2 mb-4">
              <span className="d-flex align-items-center my-3">
                <h6>
                  0 <span>-</span>
                  <span>{+distance === 1000 ? "1000+" : distance} Km</span>
                </h6>
              </span>
              <input
                className="w-100"
                onChange={(e) => {
                  setDistance(e.target.value);
                  searchFormik.setFieldValue("distance", e.target.value);
                }}
                defaultValue={1000}
                min={0}
                max={1000}
                type="range"
              />
            </div>
            <hr />

            <h5>Options</h5>
            <div className="option_location mt-2 mb-4">
              <label className="search_location">
                Online now
                <input
                  onChange={(e) => {
                    if (e.target.checked) {
                      searchFormik.setFieldValue("is_recent_active", 1);
                    } else searchFormik.setFieldValue("is_recent_active", 0);
                  }}
                  type="checkbox"
                  name="Photos"
                  checked={searchFormik.values.is_recent_active === 1}
                />
                <span className="checkmark"></span>
              </label>
              <label className="search_location">
                Photos
                <input
                  onChange={(e) => {
                    if (e.target.checked)
                      searchFormik.setFieldValue("has_photos", 1);
                    else searchFormik.setFieldValue("has_photos", 0);
                  }}
                  type="checkbox"
                  name="Photos"
                  checked={searchFormik.values.has_photos === 1}
                />
                <span className="checkmark"></span>
              </label>
              <label className="search_location">
                Viewed Me
                <input
                  onChange={(e) => {
                    if (e.target.checked)
                      searchFormik.setFieldValue("viewed_me", 1);
                    else searchFormik.setFieldValue("viewed_me", 0);
                  }}
                  type="checkbox"
                  name="Viewed"
                  checked={searchFormik.values.viewed_me === 1}
                />
                <span className="checkmark"></span>
              </label>
              <label className="search_location">
                Unviewed
                <input
                  onChange={(e) => {
                    if (e.target.checked) {
                      searchFormik.setFieldValue("unviewed_me", 1);
                      searchFormik.setFieldValue("viewed", 0);
                    } else searchFormik.setFieldValue("unviewed_me", 0);
                  }}
                  type="checkbox"
                  name="Viewed"
                  checked={searchFormik.values.unviewed_me === 1}
                />
                <span className="checkmark"></span>
              </label>
              <label className="search_location">
                Viewed
                <input
                  onChange={(e) => {
                    if (e.target.checked) {
                      searchFormik.setFieldValue("viewed", 1);
                    } else {
                      searchFormik.setFieldValue("viewed", 0);
                    }
                    if (!searchFormik.values.viewed) {
                      searchFormik.setFieldValue("unviewed_me", 0);
                    }
                  }}
                  type="checkbox"
                  name="Viewed"
                  checked={!!searchFormik.values.viewed}
                />
                <span className="checkmark"></span>
              </label>
              <label className="search_location">
                My Favourite
                <input
                  onChange={(e) => {
                    if (e.target.checked) {
                      searchFormik.setFieldValue("favourited", 1);
                    } else {
                      searchFormik.setFieldValue("favourited", 0);
                    }
                  }}
                  type="checkbox"
                  name="Favourite"
                  checked={searchFormik.values.favourited === 1}
                />
                <span className="checkmark"></span>
              </label>
              <label className="search_location">
                Favourited me
                <input
                  onChange={(e) => {
                    if (e.target.checked) {
                      searchFormik.setFieldValue("favourited_me", 1);
                    } else {
                      searchFormik.setFieldValue("favourited_me", 0);
                    }
                  }}
                  type="checkbox"
                  name="Favourite"
                  checked={searchFormik.values.favourited_me === 1}
                />
                <span className="checkmark"></span>
              </label>
            </div>
            <hr />

            <div className="accordion" id="accordion">
              {/* Body Type */}
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingTwo">
                  <button
                    className={`accordion-button ${
                      openAccordion === "collapseTwo" ? "" : "collapsed"
                    }`}
                    type="button"
                    onClick={() => handleAccordionClick("collapseTwo")}
                    aria-expanded={openAccordion === "collapseTwo"}
                    aria-controls="collapseTwo"
                  >
                    Body Type
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  className={`accordion-collapse collapse ${
                    openAccordion === "collapseTwo" ? "show" : ""
                  }`}
                  aria-labelledby="headingTwo"
                >
                  <div className="accordion-body">
                    <div className="option_location mt-2 mb-4">
                      {statics.bodyType.map((bt) => (
                        <label key={bt.id} className="search_location">
                          {bt.name}
                          <input
                            onChange={(e) => {
                              const updatedValues = e.target.checked
                                ? [...searchFormik.values.body_type, bt.id]
                                : searchFormik.values.body_type.filter(
                                    (id) => id !== bt.id
                                  );
                              searchFormik.setFieldValue(
                                "body_type",
                                updatedValues
                              );
                            }}
                            type="checkbox"
                            name={bt.name}
                            checked={(
                              searchFormik.values.body_type as any
                            ).includes(bt.id)}
                          />
                          <span className="checkmark"></span>
                        </label>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              {/* Age */}
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingThree">
                  <button
                    className={`accordion-button ${
                      openAccordion === "collapseThree" ? "" : "collapsed"
                    }`}
                    type="button"
                    onClick={() => handleAccordionClick("collapseThree")}
                    aria-expanded={openAccordion === "collapseThree"}
                    aria-controls="collapseThree"
                  >
                    Age
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  className={`accordion-collapse collapse ${
                    openAccordion === "collapseThree" ? "show" : ""
                  }`}
                  aria-labelledby="headingThree"
                >
                  <div className="accordion-body">
                    <span className="d-flex align-items-center m-3">
                      {fromAge}-<h6>{+toAge === 60 ? "60+" : toAge}</h6>
                    </span>
                    <MultiRangeSlider
                      min={18}
                      max={60}
                      defaultMin={18}
                      defaultMax={60}
                      step={1}
                      handleSlideChange={handleAge}
                    />
                  </div>
                </div>
              </div>
              <hr />
              {/* Hair Color */}
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingFour">
                  <button
                    className={`accordion-button ${
                      openAccordion === "collapseFour" ? "" : "collapsed"
                    }`}
                    type="button"
                    onClick={() => handleAccordionClick("collapseFour")}
                    aria-expanded={openAccordion === "collapseFour"}
                    aria-controls="collapseFour"
                  >
                    Hair color
                  </button>
                </h2>
                <div
                  id="collapseFour"
                  className={`accordion-collapse collapse ${
                    openAccordion === "collapseFour" ? "show" : ""
                  }`}
                  aria-labelledby="headingFour"
                >
                  <div className="accordion-body">
                    <div className="option_location mt-2 mb-4">
                      {statics.hair.map((hair) => (
                        <label key={hair.id} className="search_location">
                          {hair.name}
                          <input
                            onChange={(e) => {
                              const updatedValues = e.target.checked
                                ? [...searchFormik.values.hair, hair.id]
                                : searchFormik.values.hair.filter(
                                    (id) => id !== hair.id
                                  );
                              searchFormik.setFieldValue("hair", updatedValues);
                            }}
                            type="checkbox"
                            name={hair.name}
                            checked={(searchFormik.values.hair as any).includes(
                              hair.id
                            )}
                          />
                          <span className="checkmark"></span>
                        </label>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              {/* Smoking */}
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingSmoking">
                  <button
                    className={`accordion-button ${
                      openAccordion === "collapseSmoking" ? "" : "collapsed"
                    }`}
                    type="button"
                    onClick={() => handleAccordionClick("collapseSmoking")}
                    aria-expanded={openAccordion === "collapseSmoking"}
                    aria-controls="collapseSmoking"
                  >
                    Smoking
                  </button>
                </h2>
                <div
                  id="collapseSmoking"
                  className={`accordion-collapse collapse ${
                    openAccordion === "collapseSmoking" ? "show" : ""
                  }`}
                  aria-labelledby="headingSmoking"
                >
                  <div className="accordion-body">
                    <div className="option_location mt-4 mb-4">
                      {statics.smokerStatus.map((sm) => {
                        return (
                          <label key={sm.id} className="search_location">
                            {sm.name}
                            <input
                              onChange={(e) => {
                                if (e.target.checked) {
                                  searchFormik.setFieldValue("is_smoker", [
                                    ...searchFormik.values.is_smoker,
                                    sm.id,
                                  ]);
                                } else {
                                  const id: any = [
                                    ...searchFormik.values.is_smoker,
                                  ];
                                  const index = id.indexOf(sm.id);
                                  if (index > -1) {
                                    id.splice(index, 1);
                                  }

                                  searchFormik.setFieldValue("is_smoker", id);
                                }
                              }}
                              type="checkbox"
                              name={sm.name}
                              checked={(
                                searchFormik.values.is_smoker as any
                              ).includes(sm.id)}
                            />
                            <span className="checkmark"></span>
                          </label>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              {/* Drinking */}
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingDrinking">
                  <button
                    className={`accordion-button ${
                      openAccordion === "collapseDrinking" ? "" : "collapsed"
                    }`}
                    type="button"
                    onClick={() => handleAccordionClick("collapseDrinking")}
                    aria-expanded={openAccordion === "collapseDrinking"}
                    aria-controls="collapseDrinking"
                  >
                    Drinking
                  </button>
                </h2>
                <div
                  id="collapseDrinking"
                  className={`accordion-collapse collapse ${
                    openAccordion === "collapseDrinking" ? "show" : ""
                  }`}
                  aria-labelledby="headingDrinking"
                >
                  <div className="accordion-body">
                    <div className="option_location mt-2 mb-4">
                      {statics.drinkerStatus.map((drink) => {
                        return (
                          <label key={drink.id} className="search_location">
                            {drink.name}
                            <input
                              onChange={(e) => {
                                if (e.target.checked) {
                                  searchFormik.setFieldValue("is_drinker", [
                                    ...searchFormik.values.is_drinker,
                                    drink.id,
                                  ]);
                                } else {
                                  const id: any = [
                                    ...searchFormik.values.is_drinker,
                                  ];
                                  const index = id.indexOf(drink.id);
                                  if (index > -1) {
                                    id.splice(index, 1);
                                  }

                                  searchFormik.setFieldValue("is_drinker", id);
                                }
                              }}
                              type="checkbox"
                              name={drink.name}
                              checked={(
                                searchFormik.values.is_drinker as any
                              ).includes(drink.id)}
                            />
                            <span className="checkmark"></span>
                          </label>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              {/* Relationship Status */}
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingRelationshipStatus">
                  <button
                    className={`accordion-button ${
                      openAccordion === "collapseRelationshipStatus"
                        ? ""
                        : "collapsed"
                    }`}
                    type="button"
                    onClick={() =>
                      handleAccordionClick("collapseRelationshipStatus")
                    }
                    aria-expanded={
                      openAccordion === "collapseRelationshipStatus"
                    }
                    aria-controls="collapseRelationshipStatus"
                  >
                    Relationship Status
                  </button>
                </h2>
                <div
                  id="collapseRelationshipStatus"
                  className={`accordion-collapse collapse ${
                    openAccordion === "collapseRelationshipStatus" ? "show" : ""
                  }`}
                  aria-labelledby="headingRelationshipStatus"
                >
                  <div className="accordion-body">
                    <div className="option_location mt-4 mb-4">
                      {statics.relationshipStatus.map((rs) => {
                        return (
                          <label key={rs.id} className="search_location">
                            {rs.name}
                            <input
                              onChange={(e) => {
                                if (e.target.checked) {
                                  searchFormik.setFieldValue(
                                    "relationship_status",
                                    [
                                      ...searchFormik.values
                                        .relationship_status,
                                      rs.id,
                                    ]
                                  );
                                } else {
                                  const id: any = [
                                    ...searchFormik.values.relationship_status,
                                  ];
                                  const index = id.indexOf(rs.id);
                                  if (index > -1) {
                                    id.splice(index, 1);
                                  }

                                  searchFormik.setFieldValue(
                                    "relationship_status",
                                    id
                                  );
                                }
                              }}
                              type="checkbox"
                              name={rs.name}
                              checked={(
                                searchFormik.values.relationship_status as any
                              ).includes(rs.id)}
                            />
                            <span className="checkmark"></span>
                          </label>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              {/* Education */}
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingEducation">
                  <button
                    className={`accordion-button ${
                      openAccordion === "collapseEducation" ? "" : "collapsed"
                    }`}
                    type="button"
                    onClick={() => handleAccordionClick("collapseEducation")}
                    aria-expanded={openAccordion === "collapseEducation"}
                    aria-controls="collapseEducation"
                  >
                    Education
                  </button>
                </h2>
                <div
                  id="collapseEducation"
                  className={`accordion-collapse collapse ${
                    openAccordion === "collapseEducation" ? "show" : ""
                  }`}
                  aria-labelledby="headingEducation"
                >
                  <div className="accordion-body">
                    <div className="option_location mt-2 mb-4">
                      {statics.education.map((edu) => {
                        return (
                          <label key={edu.id} className="search_location">
                            {edu.name}
                            <input
                              onChange={(e) => {
                                if (e.target.checked) {
                                  searchFormik.setFieldValue("education", [
                                    ...searchFormik.values.education,
                                    edu.id,
                                  ]);
                                } else {
                                  const id: any = [
                                    ...searchFormik.values.education,
                                  ];
                                  const index = id.indexOf(edu.id);
                                  if (index > -1) {
                                    id.splice(index, 1);
                                  }

                                  searchFormik.setFieldValue("education", id);
                                }
                              }}
                              type="checkbox"
                              name={edu.name}
                              checked={(
                                searchFormik.values.education as any
                              ).includes(edu.id)}
                            />
                            <span className="checkmark"></span>
                          </label>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              {/* Ethnicity */}
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingEthnicity">
                  <button
                    className={`accordion-button ${
                      openAccordion === "collapseEthnicity" ? "" : "collapsed"
                    }`}
                    type="button"
                    onClick={() => handleAccordionClick("collapseEthnicity")}
                    aria-expanded={openAccordion === "collapseEthnicity"}
                    aria-controls="collapseEthnicity"
                  >
                    Ethnicity
                  </button>
                </h2>
                <div
                  id="collapseEthnicity"
                  className={`accordion-collapse collapse ${
                    openAccordion === "collapseEthnicity" ? "show" : ""
                  }`}
                  aria-labelledby="headingEthnicity"
                >
                  <div className="accordion-body">
                    <div className="option_location mt-2 mb-4">
                      {statics.ethnicity.map((bt) => (
                        <label key={bt.id} className="search_location">
                          {bt.name}
                          <input
                            onChange={(e) => {
                              const updatedValues = e.target.checked
                                ? [...searchFormik.values.ethnicity, bt.id]
                                : searchFormik.values.ethnicity.filter(
                                    (id) => id !== bt.id
                                  );
                              searchFormik.setFieldValue(
                                "ethnicity",
                                updatedValues
                              );
                            }}
                            type="checkbox"
                            name={bt.name}
                            checked={(
                              searchFormik.values.ethnicity as any
                            ).includes(bt.id)}
                          />
                          <span className="checkmark"></span>
                        </label>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              {/* Looking for tags */}
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingLookingFor">
                  <button
                    className={`accordion-button ${
                      openAccordion === "collapseLookingFor" ? "" : "collapsed"
                    }`}
                    type="button"
                    onClick={() => handleAccordionClick("collapseLookingFor")}
                    aria-expanded={openAccordion === "collapseLookingFor"}
                    aria-controls="collapseLookingFor"
                  >
                    Looking for tags
                  </button>
                </h2>
                <div
                  id="collapseLookingFor"
                  className={`accordion-collapse collapse ${
                    openAccordion === "collapseLookingFor" ? "show" : ""
                  }`}
                  aria-labelledby="headingLookingFor"
                >
                  <div className="accordion-body">
                    <div className="option_location mt-2 mb-4">
                      {statics?.datingTags?.map((bt) => (
                        <label key={bt.id} className="search_location">
                          {bt.tag_name}
                          <input
                            onChange={(e) => {
                              const updatedValues = e.target.checked
                                ? [
                                    ...searchFormik.values.dating_tags_to_show,
                                    bt.id,
                                  ]
                                : searchFormik.values.dating_tags_to_show.filter(
                                    (id) => id !== bt.id
                                  );
                              searchFormik.setFieldValue(
                                "dating_tags_to_show",
                                updatedValues
                              );
                            }}
                            type="checkbox"
                            name={bt.tag_name}
                            checked={(
                              searchFormik.values.dating_tags_to_show as any
                            ).includes(bt.id)}
                            disabled={(
                              searchFormik.values.dating_tags_not_to_show as any
                            ).includes(bt.id)}
                          />
                          <span className="checkmark"></span>
                        </label>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              {/* Not Looking for tags */}
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingNotLookingFor">
                  <button
                    className={`accordion-button ${
                      openAccordion === "collapseNotLookingFor"
                        ? ""
                        : "collapsed"
                    }`}
                    type="button"
                    onClick={() =>
                      handleAccordionClick("collapseNotLookingFor")
                    }
                    aria-expanded={openAccordion === "collapseNotLookingFor"}
                    aria-controls="collapseNotLookingFor"
                  >
                    Not Looking for tags
                  </button>
                </h2>
                <div
                  id="collapseNotLookingFor"
                  className={`accordion-collapse collapse ${
                    openAccordion === "collapseNotLookingFor" ? "show" : ""
                  }`}
                  aria-labelledby="headingNotLookingFor"
                >
                  <div className="accordion-body">
                    <div className="option_location mt-2 mb-4">
                      {statics?.datingTags?.map((bt) => (
                        <label key={bt.id} className="search_location">
                          {bt.tag_name}
                          <input
                            onChange={(e) => {
                              const updatedValues = e.target.checked
                                ? [
                                    ...searchFormik.values
                                      .dating_tags_not_to_show,
                                    bt.id,
                                  ]
                                : searchFormik.values.dating_tags_not_to_show.filter(
                                    (id) => id !== bt.id
                                  );
                              searchFormik.setFieldValue(
                                "dating_tags_not_to_show",
                                updatedValues
                              );
                            }}
                            type="checkbox"
                            name={bt.tag_name}
                            checked={(
                              searchFormik.values.dating_tags_not_to_show as any
                            ).includes(bt.id)}
                            disabled={(
                              searchFormik.values.dating_tags_to_show as any
                            ).includes(bt.id)}
                          />
                          <span className="checkmark"></span>
                        </label>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              {/* Children */}
              {/* <div className="accordion-item">
            <h2 className="accordion-header" id="headingChildren">
              <button
                className={`accordion-button ${
                  openAccordion === "collapseChildren" ? "" : "collapsed"
                }`}
                type="button"
                onClick={() => handleAccordionClick("collapseChildren")}
                aria-expanded={openAccordion === "collapseChildren"}
                aria-controls="collapseChildren"
              >
                Children
              </button>
            </h2>
            <div
              id="collapseChildren"
              className={`accordion-collapse collapse ${
                openAccordion === "collapseChildren" ? "show" : ""
              }`}
              aria-labelledby="headingChildren"
            >
              <div className="accordion-body">
                <div className="option_location mt-4 mb-4">
                  {statics.childrenStatus.map((cs) => {
                    return (
                      <label key={cs.id} className="search_location">
                        {cs.name}
                        <input
                          onChange={(e) => {
                            if (e.target.checked) {
                              searchFormik.setFieldValue("is_children", [
                                ...searchFormik.values.is_children,
                                cs.id,
                              ]);
                            } else {
                              const id: any = [
                                ...searchFormik.values.is_children,
                              ];
                              const index = id.indexOf(cs.id);

                              if (index > -1) id.splice(index, 1);
                              searchFormik.setFieldValue("is_children", id);
                            }
                          }}
                          type="checkbox"
                          name={cs.name}
                          checked={(
                            searchFormik.values.is_children as any
                          ).includes(cs.id)}
                        />
                        <span className="checkmark"></span>
                      </label>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <hr /> */}
              {/* Annual Income */}
              {/* <div className="accordion-item">
            <h2 className="accordion-header" id="headingAnnualIncome">
              <button
                className={`accordion-button ${
                  openAccordion === "collapseAnnualIncome" ? "" : "collapsed"
                }`}
                type="button"
                onClick={() => handleAccordionClick("collapseAnnualIncome")}
                aria-expanded={openAccordion === "collapseAnnualIncome"}
                aria-controls="collapseAnnualIncome"
              >
                Annual Income
              </button>
            </h2>
            <div
              id="collapseAnnualIncome"
              className={`accordion-collapse collapse ${
                openAccordion === "collapseAnnualIncome" ? "show" : ""
              }`}
              aria-labelledby="headingAnnualIncome"
            >
              <div className="accordion-body">
                <div className="option_location mt-4 mb-4">
                  {statics.annualIncome.map((ai) => {
                    return (
                      <label key={ai.id} className="search_location">
                        {ai.name}
                        <input
                          onChange={(e) => {
                            if (e.target.checked) {
                              searchFormik.setFieldValue("annual_income", [
                                ...searchFormik.values.annual_income,
                                ai.id,
                              ]);
                            } else {
                              const id: any = [
                                ...searchFormik.values.annual_income,
                              ];
                              const index = id.indexOf(ai.id);
                              if (index > -1) {
                                id.splice(index, 1);
                              }

                              searchFormik.setFieldValue("annual_income", id);
                            }
                          }}
                          type="checkbox"
                          name={ai.name}
                          checked={(
                            searchFormik.values.annual_income as any
                          ).includes(ai.id)}
                        />
                        <span className="checkmark"></span>
                      </label>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <hr /> */}
              {/* Net Worth */}
              {/* <div className="accordion-item mb-4">
            <h2 className="accordion-header" id="headingNetWorth">
              <button
                className={`accordion-button ${
                  openAccordion === "collapseNetWorth" ? "" : "collapsed"
                }`}
                type="button"
                onClick={() => handleAccordionClick("collapseNetWorth")}
                aria-expanded={openAccordion === "collapseNetWorth"}
                aria-controls="collapseNetWorth"
              >
                Net Worth
              </button>
            </h2>
            <div
              id="collapseNetWorth"
              className={`accordion-collapse collapse ${
                openAccordion === "collapseNetWorth" ? "show" : ""
              }`}
              aria-labelledby="headingNetWorth"
            >
              <div className="accordion-body">
                <div className="option_location mt-4 mb-4">
                  {statics.netWorth.map((nw) => {
                    return (
                      <label key={nw.id} className="search_location">
                        {nw.name}
                        <input
                          onChange={(e) => {
                            if (e.target.checked) {
                              searchFormik.setFieldValue("net_worth", [
                                ...searchFormik.values.net_worth,
                                nw.id,
                              ]);
                            } else {
                              const id: any = [
                                ...searchFormik.values.net_worth,
                              ];
                              const index = id.indexOf(nw.id);
                              if (index > -1) {
                                id.splice(index, 1);
                              }

                              searchFormik.setFieldValue("net_worth", id);
                            }
                          }}
                          type="checkbox"
                          name={nw.name}
                          checked={(
                            searchFormik.values.net_worth as any
                          ).includes(nw.id)}
                        />
                        <span className="checkmark"></span>
                      </label>
                    );
                  })}
                </div>
              </div>
            </div>
          </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-9 col-md-12 col-sm-12 mx-auto d-block">
        <Tabs
          id="member-tabs"
          className="tabs_member"
          activeKey={activeKey}
          onSelect={(k) => {
            setActiveKey(k!);
          }}
        >
          <Tab eventKey="created_at" title="Newest Members">
            <MemberListing
              values={searchFormik.values}
              getUsers={getUserListing}
              handleFvt={handleFavourite}
            />
          </Tab>
          <Tab eventKey="distance" title="Nearest Members">
            <MemberListing
              values={searchFormik.values}
              getUsers={getUserListing}
              handleFvt={handleFavourite}
            />
          </Tab>
          <Tab eventKey="online_status" title="Members Recently Active">
            <MemberListing
              values={searchFormik.values}
              getUsers={getUserListing}
              handleFvt={handleFavourite}
            />
          </Tab>
        </Tabs>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  staticList: () => dispatch(CommonAction.getStaticList()),
  membersList: (params: MemberFilters) =>
    dispatch(MemberAction.getFilteredMembers(params)),
  addToFavourite: (userId: number) =>
    dispatch(MemberAction.addToFavourite(userId)),
});

interface MapDispatchToProps {
  staticList: () => void;
  membersList: (params: any) => void;
  addToFavourite: (userId: number) => any;
}

export default connect(null, mapDispatchToProps)(SearchSidebar);
