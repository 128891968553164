import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { apiAxiosInstance } from "../../../config/axios";
import { useNavigate } from "react-router-dom";
import ROUTES from "../../../constants/routes";

interface DeleteAccountComponentProps {
  isOpen: boolean;
  onClose: () => void;
}

const DeleteAccountComponent = ({
  isOpen,
  onClose,
}: DeleteAccountComponentProps) => {
  const navigate = useNavigate();
  const [reason, setReason] = useState("");

  const confirmDeleteAccount = async () => {
    try {
      const response = await apiAxiosInstance.post("delete-account", {
        reason,
      });
      if (response?.data?.success) {
        navigate(ROUTES.HOME);
      }
    } catch (error) {
      console.error("Error deleting account:", error);
    }
  };

  const handleReasonChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setReason(e.target.value);
  };

  return (
    <div>
      <Modal show={isOpen} onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Account</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="deletionReason">
              <Form.Label>Reason for deletion</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                // height={5000}
                value={reason}
                onChange={handleReasonChange}
                placeholder="Please provide a reason for deleting your account"
                required
              />
            </Form.Group>
          </Form>
          Are you sure you want to delete your account? This action cannot be
          undone.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={confirmDeleteAccount}
            disabled={!reason}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default DeleteAccountComponent;
