import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import "./style.css";
import { useSelector } from "react-redux";
import { getSubscriptionsById } from "../../redux/thunk/subscription";
import Loader from "../../components/Loader/Spinner";

const SubscriptionList = () => {
  const { profile } = useSelector((state) => state.user);
  const [userSubsData, setUserSubsData] = useState(null);
  const [loading, setLoading] = useState(false); // State to manage loading
  const [error, setError] = useState(null); // State to manage error

  useEffect(() => {
    setLoading(true); // Set loading state to true before API call
    getSubscriptionsById(
      profile.id,
      (data) => {
        setUserSubsData(data);
        setLoading(false); // Set loading to false after fetching completes
        setError(null); // Reset error state on successful fetch
      },
      (error) => {
        setError(error); // Set error state
        setLoading(false); // Set loading to false if there is an error
      }
    );
  }, [getSubscriptionsById, profile.id]);

  return (
    <Fragment>
      <Header />
      <section className="main-sec search_page">
        <div className="container">
          {loading && <Loader />}

          {error ? (
            <div className="alert alert-danger" role="alert">
              There was an error fetching the subscription data. Please try
              again later.
            </div>
          ) : (
            <>
              {userSubsData && userSubsData.subsData && (
                <>
                  <h4 className="mb-3">Purchased Subscription</h4>
                  <div className="border p-3 mb-3 col-6">
                    <div className="row">
                      <label className="col-4">
                        <b>Name: </b>
                      </label>
                      <div className="col-8">
                        {userSubsData?.subsData?.subscription_type[0]?.title}
                      </div>
                    </div>
                    <div className="row">
                      <label className="col-4">
                        <b>Duration: </b>
                      </label>
                      <div className="col-8">
                        {userSubsData?.subsData?.subscription?.duration}
                      </div>
                    </div>
                    <div className="row">
                      <label className="col-4">
                        <b>Transaction Id:</b>
                      </label>
                      <div className="col-8">
                        {userSubsData?.subsData?.transection_id}
                      </div>
                    </div>
                    <div className="row">
                      <label className="col-4">
                        <b>Amount: </b>
                      </label>
                      <div className="col-8">
                        {userSubsData?.subsData?.amount}
                      </div>
                    </div>
                  </div>
                </>
              )}

              <h4 className="mb-3">Subscription List</h4>
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Duration</th>
                    <th scope="col">Transaction Id</th>
                    <th scope="col">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {userSubsData &&
                    userSubsData?.data &&
                    userSubsData?.data?.map((userData, index) => (
                      <tr key={index}>
                        <td>{userData.subscription_type[0].title}</td>
                        <td>{userData.subscription.duration} months</td>
                        <td>{userData.transection_id}</td>
                        <td>₹ {userData.amount}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </>
          )}
        </div>
      </section>
      <Footer />
    </Fragment>
  );
};

const mapDispatchToProps = (dispatch) => ({});

//  interface DispatchToProps {

//  };

export default connect(null, mapDispatchToProps)(SubscriptionList);
