import { toast } from "react-toastify";
import { apiAxiosInstance } from "../../config/axios";
import { AppDispatch } from "../store";
import { ACTIONS } from "../actions";
import {
  DeleteUserImageIn,
  UpdateProfilePayload,
  VerifyEmailIn,
} from "../../interfaces/user";

const getProfileDetails = () => async (dispatch: AppDispatch) => {
  try {
    const {
      data: { data },
    } = await apiAxiosInstance.get(`/get-profile`);
    dispatch({ type: ACTIONS.SET_PROFILE_DETAILS, profile: data });
    dispatch({
      type: ACTIONS.SET_AUTHENTICATED_USER,
      authenticated: true,
      fullName: data?.user_name,
    });
  } catch (err: any) {
    dispatch({ type: ACTIONS.SET_AUTHENTICATED_USER, authenticated: false });
    if (err.response && err.response.data) {
      dispatch({ type: ACTIONS.SET_AUTHENTICATED_USER, authenticated: false });
    }
    localStorage.clear();
  }
};

const updateProfileDetails =
  (payload: UpdateProfilePayload) => async (dispatch: AppDispatch) => {
    try {
      const { data } = await apiAxiosInstance.post(`/edit-profile`, payload);
      if (data.success) {
        toast.success("Profile updated successfully");
        dispatch({ type: ACTIONS.SET_PROFILE_MODAL, profileModal: "" });
        dispatch(getProfileDetails());
      }
    } catch (err: any) {
      if (err.response && err.response.data) {
        toast.error(err.response.data.message);
      }
    }
  };

const updateMobileNumber =
  (payload: { phone_number: string }) => async (dispatch: AppDispatch) => {
    try {
      const { data } = await apiAxiosInstance.post(
        `/update-phone-number`,
        payload
      );
      if (data.success) {
        toast.success("Number updated successfully");
        dispatch(getProfileDetails());
      }
      return data;
    } catch (err: any) {
      if (err.response && err.response.data) {
        toast.error(err.response.data.message);
      }
    }
  };

const verifyEmail =
  (payload: VerifyEmailIn) => async (dispatch: AppDispatch) => {
    try {
      const { data } = await apiAxiosInstance.post(
        `/send-verification-email`,
        payload
      );
      if (data.success) {
        toast.success(data.message);
        dispatch(getProfileDetails());
      }
    } catch (err: any) {
      if (err.response && err.response.data) {
        toast.error(err.response.data.message);
      }
    }
  };

const removeUserImage =
  (payload: DeleteUserImageIn) => async (dispatch: AppDispatch) => {
    try {
      const { data } = await apiAxiosInstance.post(
        `/delete/user/images`,
        payload
      );
      if (data.success) {
        toast.success(data.message);
        dispatch(getProfileDetails());
      }
    } catch (err: any) {
      if (err.response && err.response.data) {
        toast.error(err.response.data.message);
      }
    }
  };

export {
  getProfileDetails,
  updateProfileDetails,
  verifyEmail,
  removeUserImage,
  updateMobileNumber,
};
