import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "react-phone-number-input/style.css";
import { apiAxiosInstance } from "../../config/axios";
import Loader from "../Loader/Spinner";
import { RootReducer } from "../../interfaces/reducer";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import InputStyles from "../../components/Input/style.module.css";
import Flag from "../../assets/images/india.png";

const ContactUsSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  email: Yup.string().required("Email is required"),
  mobileNumber: Yup.string()
    .test("is-valid-phone", "Invalid", function (value) {
      return isValidPhoneNumber(value || "");
    })
    .required("Number is required"),
  message: Yup.string().required("Message is required"),
});

const ContactUs: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const { profile } = useSelector((state: RootReducer) => state.user);
  const { authenticated } = useSelector((state: RootReducer) => state.auth);

  const initialValues = {
    name: profile?.user_name || "",
    email: profile?.email || "",
    mobileNumber: `${
      profile?.country_code
        ? `+${profile?.country_code}${profile?.phone_number}`
        : ""
    }`,
    country_code: "",
    message: "",
  };

  const handleSubmit = async (values: any, { resetForm }: any) => {
    const payload = {
      name: values?.name,
      email: values?.email,
      phone: profile?.phone_number || values.mobileNumber.slice(1, 3),
      country_code: profile?.country_code || values.mobileNumber.slice(3, 20),
      message: values.message,
    };
    try {
      const { data } = await apiAxiosInstance.post(
        "/report-suggestion",
        payload
      );
      // if (!profile?.country_code) window.location.reload();
      toast.success(data.message);
      resetForm();
    } catch (error) {
      console.error("Error submitting contact form:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mt-3">
      <h2 className="head_1">Contact Us</h2>
      <div className="row">
        <div
          className={authenticated ? "col-md-12 mx-auto" : "col-md-6 mx-auto"}
        >
          <div className="card-contact">
            {loading ? (
              <Loader />
            ) : (
              <Formik
                initialValues={initialValues}
                validationSchema={ContactUsSchema}
                onSubmit={handleSubmit}
              >
                {({ errors, touched, setFieldValue, values }) => (
                  <Form>
                    <div className="mb-3 height-textarea">
                      <label htmlFor="name" className="form-label">
                        Name
                      </label>
                      <Field
                        as="input" // Changed 'as' attribute from "text" to "input"
                        name="name"
                        className={`form-control h-auto ${
                          errors.name && touched.name && "is-invalid"
                        }`}
                        disabled={profile?.user_name}
                        style={{ resize: "none", height: "auto" }} // Removed !important since it's not valid in inline styles
                      />
                      <ErrorMessage
                        name="name"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="mb-3 height-textarea">
                      <label htmlFor="email" className="form-label">
                        Email
                      </label>
                      <Field
                        as="input" // Changed 'as' attribute from "text" to "input"
                        name="email"
                        className={`form-control h-auto ${
                          errors.email && touched.email && "is-invalid"
                        }`}
                        disabled={profile?.email}
                        style={{ resize: "none", height: "auto" }} // Removed !important since it's not valid in inline styles
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="mb-3 ph-input">
                      <label className="form-label">Mobile Number</label>
                      <div className="position-relative lost_flag">
                      <PhoneInput
                        placeholder="Enter phone number"
                        international
                        onChange={(value) => {
                          setFieldValue("mobileNumber", value);
                        }}
                        disabled={!!profile?.country_code}
                        className="phone-number"
                        value={
                          `${
                            profile?.country_code
                              ? `+${profile?.country_code}${profile?.phone_number}`
                              : values.mobileNumber
                          }` as any
                        }
                        defaultCountry="IN"
                      />

                    <img className="flag_pos"src={Flag} alt="flag"/>
                      </div>

                      {touched?.mobileNumber && errors?.mobileNumber ? (
                        <div className={InputStyles.error}>
                          {errors?.mobileNumber}
                        </div>
                      ) : null}
                    </div>
                    <div className="mb-3 height-textarea">
                      <label htmlFor="message" className="form-label">
                        Message
                      </label>
                      <Field
                        as="textarea"
                        name="message"
                        className={`form-control h-auto ${
                          errors.message && touched.message && "is-invalid"
                        }`}
                        style={{ resize: "none", height: "auto !important" }}
                      />
                      <ErrorMessage
                        name="message"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    <button
                      type="submit"
                      className="btn btn-primary float-none"
                    >
                      Submit
                    </button>
                  </Form>
                )}
              </Formik>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
