export const ACTIONS = {
  SET_LOGIN_MODAL: "SET_LOGIN_MODAL",
  SET_AUTHENTICATED_USER: "SET_AUTHENTICATED_USER",
  SET_SIGNUP_MODAL: "SET_SIGNUP_MODAL",
  SET_SIGNUP_STEP: "SET_SIGNUP_STEP",
  SET_SKIPPABLE_PROFILE: "SET_SKIPPABLE_PROFILE",
  SET_SIGNUP_VALUES: "SET_SIGNUP_VALUES",
  SET_STATIC_DATA: "SET_STATIC_DATA",
  SET_DATING_TAGS: "SET_DATING_TAGS",
  SET_NEWEST_MEMBERS: "SET_NEWEST_MEMBERS",
  SET_NEAREST_MEMBERS: "SET_NEAREST_MEMBERS",
  SET_RECENT_ACTIVE_MEMBERS: "SET_RECENT_ACTIVE_MEMBERS",
  SET_PROFILE_DETAILS: "SET_PROFILE_DETAILS",
  SET_PROFILE_MODAL: "SET_PROFILE_MODAL",
  SET_REDIRECTION_TO_HOME: "SET_REDIRECTION_TO_HOME",
  SET_SEARCH: "SET_SEARCH",
  SET_FILTERED: "SET_FILTERED",
  SET_FILTERED_MEMBERS: "SET_FILTERED_MEMBERS",
  SET_VIEWED_MEMBERS: "SET_VIEWED_MEMBERS",
  SET_FAVOURITE_MEMBERS: "SET_FAVOURITE_MEMBERS",
  SET_FAVOURITEME_MEMBERS: "SET_FAVOURITEME_MEMBERS",
  SET_FILTERS_OPTIONS: "SET_FILTERS_OPTIONS",
  SET_MEMBER_LOADING: "SET_MEMBER_LOADING",
  INITIALIZE_SOCKET: "INITIALIZE_SOCKET",
  SET_OTHERMEMBER_PROFILE: "SET_OTHERMEMBER_PROFILE",
  SET_HEADER_OPTION: "SET_HEADER_OPTION",
  SET_ALL_MESSAGES: "SET_ALL_MESSAGES",
  SET_SENT_MESSAGES: "SET_SENT_MESSAGES",
  SET_OTHER_MESSAGES: "SET_OTHER_MESSAGES",
  SET_ARCHIVE_MESSAGES: "SET_ARCHIVE_MESSAGES",
  SET_FILTER_MESSAGES: "SET_FILTER_MESSAGES",
  SET_PASSWORD_MODAL: "SET_PASSWORD_MODAL",
  SET_TERMSANDCONDITION: "SET_TERMSANDCONDITION",
  SET_ABOUT_US: "SET_ABOUT_US",
  SET_PRIVACYPOLICY: "SET_PRIVACYPOLICY",
  RESET_SIGNUP_VALUES: "RESET_SIGNUP_VALUES",
  SET_THEME: "SET_THEME",
  SET_USERINFO_MODAL: "SET_USERINFO_MODAL",
  SET_GOOGLE_LOGIN: "SET_GOOGLE_LOGIN",
  SET_GOOGLE_PAYLOAD: "SET_GOOGLE_PAYLOAD",
  GET_SUBSCRIPTION_DATA: "GET_SUBSCRIPTION_DATA",
  FORGOT_PSWD_MODAL: "FORGOT_PSWD_MODAL",
  OTP_MODAL: "OTP_MODAL",
  NEW_PSWD_MODAL: "NEW_PSWD_MODAL",
};
