import React from "react";
import { SidebarItems } from "./SideBaritems";
import { Constants } from "../../utils/constants";
import { useDispatch } from "react-redux";
import { apiAxiosInstance } from "../../config/axios";
interface SidebarProps {
  activeTab: string;
  changeActiveTab: (_tab: string) => void;
}
const Sidebar: React.FC<SidebarProps> = ({ activeTab, changeActiveTab }) => {
  

  return (

    <div
      className="sidebar sidebar-inner "
      style={{
        backgroundColor: "#F2F2F2",
        color: "#fff",
        height: "100%",
        paddingTop: "10px",
      }}
    >
      <ul className="nav flex-column">
        {SidebarItems.map((item) => (
          <li key={item.name} className="nav-item">
            <button
              className={`nav-link btn ${
                activeTab === item.name ? "active" : ""
              }`}
              style={{
                backgroundColor:
                  activeTab === item.name ? "#343a40" : "transparent",
                border: "none",
                borderRadius: "0",
                color: activeTab === item.name ? "#fff" : "#adb5bd",
              }}
              onClick={() => {
                changeActiveTab(item.name);
              }}
            >
              <h6>{item.name}</h6>
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Sidebar;
