import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector, useDispatch, connect } from "react-redux";
import { useEffect } from "react";
import { useFormik } from "formik";
import "./style.css";
import { RootReducer } from "../../../interfaces/reducer";
import { EDIT_PROFILE_MODAL } from "../../../utils/constants";
import { ACTIONS } from "../../../redux/actions";
import { UpdateProfilePayload } from "../../../interfaces/user";
import { UserAction } from "../../../redux/thunk";
import { AppDispatch } from "../../../redux/store";
import { Interests } from "../../../interfaces/common";

interface Props extends DispatchToProps {}

const EditInterests = (props: Props) => {
  const dispatch = useDispatch();
  const { editProfileModal, profile } = useSelector(
    (state: RootReducer) => state.user
  );
  const { datingTags } = useSelector((state: RootReducer) => state.common);

  const closeModal = () => {
    dispatch({ type: ACTIONS.SET_PROFILE_MODAL, profileModal: "" });
  };

  const editLookingForFormik = useFormik({
    initialValues: {
      datingTags: [{ id: 0, name: "", status: false }],
    },
    onSubmit: (values) => {
      const payload = {
        tags: values.datingTags
          .filter((item) => item.status)
          .map((item) => item.id),
      };
      props.updateProfile(payload);
    },
  });

  const handleLookingForChange = (id: number) => {
    let values = editLookingForFormik.values.datingTags;
    const selectedTags = values.filter((item) => item.status);

    if (
      selectedTags.length >= 8 &&
      !selectedTags.some((item) => item.id === id)
    ) {
      toast.error("You can select up to 8 tags only.");
      return;
    }

    const index = values.findIndex((item) => item.id === id);
    values[index].status = !values[index].status;
    editLookingForFormik.setFieldValue("datingTags", values);
  };

  useEffect(() => {
    if (profile) {
      if (Object.keys(profile).length === 0) {
        return;
      }
      if (Object.keys(profile).length && datingTags.length) {
        const finalLookingFor = [];
        for (const item of datingTags) {
          const data = profile.user_tags?.find((it) => item.id === it.tag_id);
          let status = false;
          if (data) {
            status = true;
          }
          finalLookingFor.push({ ...item, status });
        }
        editLookingForFormik.setFieldValue("datingTags", finalLookingFor);
      }
    }
  }, [profile, datingTags]);

  return (
    <div
      className={`modal login-modal ${
        editProfileModal === EDIT_PROFILE_MODAL.EDIT_INTERESTS ? "d-block" : ""
      }`}
    >
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content Edit_your_info true_love Looking_userFor">
          <button
            type="button"
            className="btn-close close"
            onClick={closeModal}
          ></button>
          <div className="modal-header">
            <h1>What are you interested in?</h1>
          </div>
          <div className="modal-body px-0 pb-0">
            <div className="form-sec">
              <form>
                <div className="row">
                  <div className="step_form">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="mb-3">
                          <h3>
                            Choose at least one tag that will help to define
                            what are you looking in a relationship. (8 max)
                          </h3>
                          <div className="five_true">
                            {editLookingForFormik.values.datingTags.map(
                              (item: Interests) => (
                                <button
                                  key={item.id}
                                  type="button"
                                  className={`btn ${
                                    item.status ? "selected-interest" : ""
                                  }`}
                                  onClick={() =>
                                    handleLookingForChange(item.id)
                                  }
                                >
                                  {item.tag_name}
                                </button>
                              )
                            )}
                          </div>
                          {/* <div className="mt-3">
                            <label
                              htmlFor="exampleFormControlTextarea1"
                              className="form-label"
                            >
                              Describe what you’re seeking?
                            </label>
                            <textarea
                              className="form-control"
                              id="exampleFormControlTextarea1"
                            ></textarea>
                          </div> */}
                          <div className="cancel_save d-flex justify-content-end mt-4 mb-0 w-100">
                            <button type="button" onClick={closeModal}>
                              Cancel
                            </button>
                            <button
                              type="button"
                              onClick={editLookingForFormik.submitForm}
                            >
                              Save Info
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  updateProfile: (payload: UpdateProfilePayload) =>
    dispatch(UserAction.updateProfileDetails(payload)),
});

interface DispatchToProps {
  updateProfile: (payload: UpdateProfilePayload) => void;
}

export default connect(null, mapDispatchToProps)(EditInterests);
