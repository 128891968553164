import { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUndo, faRedo } from "@fortawesome/free-solid-svg-icons";
import ReactCrop, { type Crop, PixelCrop } from "react-image-crop";
import "../style.css";
import { RootReducer } from "../../../../interfaces/reducer";
import { useDebounceEffect } from "../../../../hooks/debounce";
import { ACTIONS } from "../../../../redux/actions";
import {
  GENERAL_DETAILS_STEP,
  SIGNUP_STEPS,
} from "../../../../utils/constants";

const CropProfilePhoto = () => {
  const dispatch = useDispatch();
  const canvasRef = useRef(null);
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const imgRef = useRef<HTMLImageElement>(null);
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const [angle, setAngle] = useState<any>(0);
  const {
    signupValues: { image, is_profile_image_public },
  } = useSelector((state: RootReducer) => state.auth);

  const canvasPreview = (
    image: HTMLImageElement,
    canvas: HTMLCanvasElement,
    crop: PixelCrop,
    scale = 1,
    rotate = 0
  ) => {
    const TO_RADIANS = Math.PI / 180;
    const ctx = canvas.getContext("2d");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const pixelRatio = window.devicePixelRatio;
    canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
    canvas.height = Math.floor(crop.height * scaleY * pixelRatio);
    if (ctx) {
      ctx.scale(pixelRatio, pixelRatio);
      ctx.imageSmoothingQuality = "high";
      const cropX = crop.x * scaleX;
      const cropY = crop.y * scaleY;
      const rotateRads = rotate * TO_RADIANS;
      const centerX = image.naturalWidth / 2;
      const centerY = image.naturalHeight / 2;
      ctx.save();
      ctx.translate(-cropX, -cropY);
      ctx.translate(centerX, centerY);
      ctx.rotate(rotateRads);
      ctx.scale(scale, scale);
      ctx.translate(-centerX, -centerY);
      ctx.drawImage(
        image,
        0,
        0,
        image.naturalWidth,
        image.naturalHeight,
        0,
        0,
        image.naturalWidth,
        image.naturalHeight
      );
      ctx.restore();
    }
  };

  const rotate = (degree: any) => {
    if (canvasRef.current) {
      const canvas: HTMLCanvasElement = canvasRef.current;
      const context = canvas?.getContext("2d");
      context?.clearRect(0, 0, canvas.width, canvas.height);
      // Rotate the canvas by 90 degrees
      context?.save();
      context?.translate(canvas.width / 2, canvas.height / 2);
      context?.rotate((degree * Math.PI) / 180);
      const img = new Image();
      const fr = new FileReader();
      fr.readAsDataURL(image as Blob);
      fr.onload = (e) => {
        if (e?.target?.result) img.src = e?.target?.result as string;
        let scale_factor = Math.min(
          canvas.width / img.width,
          canvas.height / img.height
        );
        // Lets get the new width and height based on the scale factor
        let newWidth = img.width * scale_factor;
        let newHeight = img.height * scale_factor;
        // get the top left position of the image
        // in order to center the image within the canvas
        let x = canvas.width / 2 - newWidth / 2;
        let y = canvas.height / 2 - newHeight / 2;
        // When drawing the image, we have to scale down the image
        // width and height in order to fit within the canvas
        //context?.drawImage(image, x, y, newWidth, newHeight);
        context?.drawImage(
          img,
          -canvas.height / 2,
          -canvas.width / 2,
          canvas.height,
          canvas.width
        );
        setAngle(degree);
        if (imgRef.current?.src) {
          imgRef.current.src = canvas.toDataURL();
        }
        context?.restore();
      };
    }
  };

  const handleRotateLeft = () => {
    rotate(angle + 90);
  };
  const handleRotateRight = () => {
    rotate(angle - 90);
  };
  const updateProfilePhoto = () => {
    if (
      completedCrop?.width &&
      completedCrop?.height &&
      imgRef.current &&
      previewCanvasRef.current
    ) {
      canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop);
      if (previewCanvasRef.current) {
        previewCanvasRef.current.toBlob((blob) => {
          dispatch({
            type: ACTIONS.SET_SIGNUP_VALUES,
            values: { croppedImage: blob, is_profile_image_public },
          });
          dispatch({
            type: ACTIONS.SET_SIGNUP_STEP,
            step: SIGNUP_STEPS.PREVIEW_CROPPED_PROFILE_PHOTO,
          });
        });
      }
    } else {
      dispatch({
        type: ACTIONS.SET_SIGNUP_STEP,
        step: SIGNUP_STEPS.GALLERY_PHOTOS,
      });
      // dispatch({
      //   type: ACTIONS.SET_SIGNUP_STEP,
      //   step: SIGNUP_STEPS.PREVIEW_CROPPED_PROFILE_PHOTO,
      //   generalStep: GENERAL_DETAILS_STEP.ADD_LOCATION,
      // });
    }
  };

  useEffect(() => {
    if (canvasRef.current) {
      const canvas: HTMLCanvasElement = canvasRef.current;
      const ctx = canvas?.getContext("2d");
      if (ctx) {
        // Clear previous content on the canvas
        ctx.clearRect(0, 0, 300, 200);
        const img = new Image();
        const fr = new FileReader();
        fr.readAsDataURL(image as Blob);
        fr.onload = (e) => {
          if (e?.target?.result) img.src = e?.target?.result as string;
          img.onload = () => {
            // Once the image is loaded, we will get the width & height of the image
            let loadedImageWidth = img.width;
            let loadedImageHeight = img.height;
            // get the scale
            // it is the min of the 2 ratios
            let scale_factor = Math.min(
              canvas.width / img.width,
              canvas.height / img.height
            );
            // Lets get the new width and height based on the scale factor
            let newWidth = img.width * scale_factor;
            let newHeight = img.height * scale_factor;
            // get the top left position of the image
            // in order to center the image within the canvas
            let x = canvas.width / 2 - newWidth / 2;
            let y = canvas.height / 2 - newHeight / 2;
            // When drawing the image, we have to scale down the image
            // width and height in order to fit within the canvas
            ctx.drawImage(img, x, y, newWidth, newHeight);
            if (imgRef.current?.src) {
              imgRef.current.src = canvas.toDataURL();
            }
          };
        };
      }
    }
  }, []);

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop);
      }
    },
    100,
    [completedCrop]
  );

  return (
    <div className="image-upload upload_acc">
      <div className="d-flex justify-content-center">
        <div className="accordian-page">
          <div className="accordion" id="accordionExample">
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingZerosg">
                <button
                  className="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOnee"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  Rotate
                </button>
              </h2>
              <div
                id="collapseOnee"
                className="accordion-collapse collapse show "
                aria-labelledby="headingZerog"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  <canvas className="profile-photo" ref={canvasRef} />
                  <div className="rotate">
                    <h3>Rotate Photo</h3>
                    <div className="left-rotare">
                      <div className="left-rotare-in">
                        <span>
                          <FontAwesomeIcon
                            onClick={() => {
                              handleRotateRight();
                            }}
                            icon={faUndo}
                          />
                        </span>
                        <span>
                          <FontAwesomeIcon
                            onClick={() => {
                              handleRotateLeft();
                            }}
                            icon={faRedo}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  className="left-rotare-btn show-btns btn mx-auto btn-public"
                  onClick={() =>
                    dispatch({
                      type: ACTIONS.SET_SIGNUP_STEP,
                      step: SIGNUP_STEPS.CHOOSE_USERNAME,
                    })
                  }
                >
                  Change
                </button>
              </div>
            </div>
            <div className="accordion-item ">
              <h2 className="accordion-header" id="headingZerof">
                <button
                  className="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="true"
                  aria-controls="collapseTwo"
                >
                  Crop
                </button>
              </h2>
              <div
                id="collapseTwo"
                className="accordion-collapse collapse"
                aria-labelledby="headingZerof"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  <ReactCrop
                    onComplete={(c) => setCompletedCrop(c)}
                    crop={crop}
                    onChange={(c) => setCrop(c)}
                  >
                    <img ref={imgRef} src="" alt="" className="profile-photo" />
                  </ReactCrop>
                </div>
              </div>
            </div>
          </div>
        </div>

        {!!completedCrop && (
          <canvas
            ref={previewCanvasRef}
            style={{
              border: "1px solid black",
              width: completedCrop.height,
              height: completedCrop.height,
              display: "none",
            }}
          />
        )}
      </div>
      <div className="left-rotare-btn">
        <button onClick={updateProfilePhoto}>Next</button>
        {/* <button data-bs-toggle="modal" data-bs-target="#photo-show">Add Photo</button> */}
      </div>
      <p>
        Once uploaded, photos can take up to 48 hours to be reviewed before they
        are visible on your profile.
      </p>
    </div>
  );
};

export default CropProfilePhoto;
