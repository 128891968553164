import { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";

import { RootReducer } from "../../../interfaces/reducer";
import "./style.css";
import { neuDaddyLogo } from "../../../assets/images";
import { ACTIONS } from "../../../redux/actions";
import { SIGNUP_STEPS } from "../../../utils/constants";
import PersonalDetails from "./Steps/PersonalDetails";
import ChooseUsername from "./Steps/ChooseUsername";
import CropProfilePhoto from "./Steps/CropProfilePhoto";
import PreviewCroppedFile from "./Steps/PreviewCroppedFIle";
import { AppDispatch } from "../../../redux/store";
import { CommonAction } from "../../../redux/thunk";
import Otp from "./Steps/Otp";
import GalleryPhotos from "./Steps/GalleryPhotos";

interface Props extends MapDispatchToProps {}

const SignupModal = (props: Props) => {
  const dispatch = useDispatch();
  const { signupModal, signupStep } = useSelector(
    (state: RootReducer) => state.auth
  );

  const handleSignupModal = () => {
    dispatch({ type: ACTIONS.SET_SIGNUP_MODAL, status: false });
    dispatch({
      type: ACTIONS.SET_SIGNUP_STEP,
      step: SIGNUP_STEPS.PERSONAL_DETAILS,
    });
    dispatch({ type: ACTIONS.RESET_SIGNUP_VALUES });
  };

  const handleSteps = () => {
    switch (signupStep) {
      case SIGNUP_STEPS.PERSONAL_DETAILS:
        return <PersonalDetails />;
      case SIGNUP_STEPS.VERIFY_OTP:
        return <Otp back={true} />;
      case SIGNUP_STEPS.CHOOSE_USERNAME:
        return <ChooseUsername />;
      case SIGNUP_STEPS.CROP_PROFILE_PHOTO:
        return <CropProfilePhoto />;
      case SIGNUP_STEPS.PREVIEW_CROPPED_PROFILE_PHOTO:
        return <PreviewCroppedFile />;
      case SIGNUP_STEPS.GALLERY_PHOTOS:
        return <GalleryPhotos />;
    }
  };

  useEffect(() => {
    if (signupModal) {
      props.staticList();
      props.datingTagsList();
    }
  }, [signupModal]);

  return (
    <div className={`modal login-modal ${signupModal ? "d-block" : ""}`}>
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content Edit_your_info">
          <div className="modal-header">
            <div className="logo-modal">
              <img className="me-2" src={neuDaddyLogo} alt="" />
              <FontAwesomeIcon
                className="cursor-pointer close"
                size="lg"
                icon={faClose}
                onClick={handleSignupModal}
              />
            </div>
          </div>
          <div className="modal-body px-0 pb-0">
            <div className="form-sec">{handleSteps()}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  staticList: () => dispatch(CommonAction.getStaticList()),
  datingTagsList: () => dispatch(CommonAction.getDatingTags()),
});

interface MapDispatchToProps {
  staticList: () => void;
  datingTagsList: () => void;
}

export default connect(null, mapDispatchToProps)(SignupModal);
