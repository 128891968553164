import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";

import { UserAction } from "../../redux/thunk";
import { AppDispatch } from "../../redux/store";
import { RootReducer } from "../../interfaces/reducer";
import { ACTIONS } from "../../redux/actions";
import { ACCESS_TOKEN } from "../../utils/constants";
import ROUTES from "../../constants/routes";

interface childComponentProps extends DispatchToProps {
  childComponent: React.ReactNode;
}

const Protected: React.FC<childComponentProps> = (prop) => {
  const { authenticated } = useSelector((state: RootReducer) => state.auth);

  const dispatch = useDispatch();
  const { profile } = useSelector((state: RootReducer) => state.user);
  const navigate = useNavigate();
  const [validate, setValidate] = useState<Boolean>(false);

  useEffect(() => {
    if (
      !localStorage.getItem(ACCESS_TOKEN) ||
      localStorage.getItem(ACCESS_TOKEN) === ""
    ) {
      dispatch({ type: ACTIONS.SET_AUTHENTICATED_USER, authenticated: false });

      navigate(ROUTES.HOME);
    }
    prop.getProfileDetails();
  }, []);

  useEffect(() => {
    if (authenticated === true) {
      setValidate(true);
    } else {
      setValidate(false);
      dispatch({ type: ACTIONS.SET_AUTHENTICATED_USER, authenticated: false });
    }
  }, [profile, authenticated]);

  const { childComponent } = prop;

  return <>{validate ? childComponent : <></>}</>;
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getProfileDetails: () => dispatch(UserAction.getProfileDetails()),
});

interface DispatchToProps {
  getProfileDetails: () => void;
}

export default connect(null, mapDispatchToProps)(Protected);
