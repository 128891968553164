import { formatDistanceToNow } from "date-fns";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ROUTES from "../../constants/routes";
import Women from "../../assets/images/femaleProfile@3x.png";
import Men from "../../assets/images/maleProfile@3x.png";
import Common from "../../assets/images/dummyProfile.png";
import { Members, UserProfile } from "../../interfaces/reducer";

interface MessageLayoutProps {
  profile: UserProfile;
  otherUserProfile: Members;
  myProfile: any;
  message: any;
  grantAccess: any;
}

const MessageLayout: React.FC<MessageLayoutProps> = ({
  profile,
  otherUserProfile,
  myProfile,
  message,
  grantAccess,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <>
      <img
        src={
          message.sender_id === +location.pathname.split("/")[2]
            ? otherUserProfile?.is_profile_image_approved
              ? otherUserProfile?.profile_image
              : otherUserProfile?.gender === 1
              ? Women
              : otherUserProfile?.gender === 2
              ? Men
              : Common
            : myProfile?.profile_image
        }
        alt="img-chat"
      />
      <span>
        {!profile?.is_subscribed &&
        profile?.gender === 2 &&
        message.sender_id === +location.pathname.split("/")[2] ? (
          <p
            className="cursor-pointer"
            onClick={() => navigate(ROUTES.SUBSCRIPTION)}
          >
            Upgrade to read
          </p>
        ) : (
          <>
            {message.type === "image" ? (
              <p>
                <img src={message.filename} alt="text-img" />
              </p>
            ) : (
              <p>
                {message.message ===
                `${otherUserProfile?.user_name} request for a private photos` ? (
                  <span
                    onClick={grantAccess}
                    className="cursor-pointer"
                  >{`${message?.message} Click to give access`}</span>
                ) : (
                  message?.message
                )}
              </p>
            )}
          </>
        )}
        <h6>
          {formatDistanceToNow(new Date(message.created_at), {
            addSuffix: true,
          })}
        </h6>
      </span>
    </>
  );
};

export default MessageLayout;
