import { MessagesReducer } from "../../interfaces/reducer";
import { ACTIONS } from "../actions";

const initialState: MessagesReducer = {
  allMessages: {},
  sentMessages: {},
  otherMessages: {},
  archiveMessages: {},
  filterMessages: {},
};

const messagesReducer = (
  state = initialState,
  action: { type: string } & any
) => {
  if (action.type === ACTIONS.SET_ALL_MESSAGES) {
    return {
      ...state,
      allMessages: action.messages,
    };
  } else if (action.type === ACTIONS.SET_SENT_MESSAGES) {
    return {
      ...state,
      sentMessages: action.messages,
    };
  } else if (action.type === ACTIONS.SET_OTHER_MESSAGES) {
    return {
      ...state,
      otherMessages: action.messages,
    };
  } else if (action.type === ACTIONS.SET_ARCHIVE_MESSAGES) {
    return {
      ...state,
      archiveMessages: action.messages,
    };
  } else if (action.type === ACTIONS.SET_FILTER_MESSAGES) {
    return {
      ...state,
      filterMessages: action.messages,
    };
  }

  return state;
};

export default messagesReducer;
