import * as Yup from 'yup';

const LoginSchema = Yup.object().shape({
    emailOrPhone: Yup.string().required('Required'),
    password: Yup.string().required('Required'),
    // recaptcha: Yup.boolean()
    // .oneOf([true], 'Please complete the reCAPTCHA verification.')
    // .required('Please complete the reCAPTCHA verification.'),
});

export default LoginSchema;