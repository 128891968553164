import { useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { RootReducer } from "../../../interfaces/reducer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { neuDaddyLogo } from "../../../assets/images";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { useFormik } from "formik";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import Input from "../../Input";
import { ACTIONS } from "../../../redux/actions";
import { AppDispatch } from "../../../redux/store";
import { AuthAction } from "../../../redux/thunk";
import { ChangePasswordPayload } from "../../../interfaces/auth";

interface Props extends MapDispatchToProps {}

const EditPassword = (props: Props) => {
  const dispatch = useDispatch();
  const [showPassword, setPasswordText] = useState<boolean>(false);
  const [showConfirmPassword, setConfirmPasswordText] =
    useState<boolean>(false);
  const { passwordModal } = useSelector((state: RootReducer) => state.auth);

  const loginFormik = useFormik({
    initialValues: { oldPassword: "", newPassword: "" },
    onSubmit: (values) => {
      const payload = {
        old_password: values.oldPassword,
        password: values.newPassword,
      };
      props.changePassword(payload);
      loginFormik.resetForm();
    },
  });

  return (
    <div className={`modal login-modal ${passwordModal ? "d-block" : ""}`}>
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content Edit_your_info">
          <div className="modal-header">
            <div
              className="logo-modal"
              onClick={() => {
                dispatch({ type: ACTIONS.SET_PASSWORD_MODAL, status: false });
              }}
            >
              <img className="me-2" src={neuDaddyLogo} alt="" />
              <FontAwesomeIcon
                className="cursor-pointer close"
                size="lg"
                icon={faClose}
              />
            </div>
          </div>
          <div className="modal-body px-0 pb-0">
            <div className="form-sec">
              <form onSubmit={loginFormik.handleSubmit}>
                <div className="row">
                  <div className="Choose_Username about_useredit login-form">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="mb-3">
                          <div className="d-flex justify-content-between">
                            <label>Old Password</label>
                          </div>
                          <div className="position-relative">
                            <Input
                              type={showPassword ? "text" : "password"}
                              name="oldPassword"
                              placeHolder="Enter your password"
                              handleChange={loginFormik.handleChange}
                              value={loginFormik.values.oldPassword}
                              error={
                                loginFormik.touched.oldPassword &&
                                loginFormik.errors.oldPassword
                                  ? loginFormik.errors.oldPassword
                                  : ""
                              }
                            />
                            <span
                              className="position-absolute top-50 translate-middle-y password-toggle-btn cursor-pointer"
                              style={{ right: "25px" }}
                              onClick={() => setPasswordText(!showPassword)}
                            >
                              <FontAwesomeIcon
                                icon={!showPassword ? faEyeSlash : faEye}
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="mb-3">
                          <div className="d-flex justify-content-between">
                            <label>Confirm Password</label>
                          </div>
                          <div className="position-relative">
                            <Input
                              type={showConfirmPassword ? "text" : "password"}
                              name="newPassword"
                              placeHolder="Enter your password"
                              handleChange={loginFormik.handleChange}
                              value={loginFormik.values.newPassword}
                              error={
                                loginFormik.touched.newPassword &&
                                loginFormik.errors.newPassword
                                  ? loginFormik.errors.newPassword
                                  : ""
                              }
                            />
                            <span
                              className="position-absolute top-50 translate-middle-y password-toggle-btn cursor-pointer"
                              style={{ right: "25px" }}
                              onClick={() =>
                                setConfirmPasswordText((prev) => !prev)
                              }
                            >
                              <FontAwesomeIcon
                                icon={!showConfirmPassword ? faEyeSlash : faEye}
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="mb-3">
                          <button type="submit">Change Password</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  changePassword: (payload: ChangePasswordPayload) =>
    dispatch(AuthAction.changePassword(payload)),
});

interface MapDispatchToProps {
  changePassword: (payload: ChangePasswordPayload) => void;
}

export default connect(null, mapDispatchToProps)(EditPassword);
