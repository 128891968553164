import { toast } from "react-toastify";

import { apiAxiosInstance } from "../../config/axios";
import { AppDispatch } from "../store";
import { ACTIONS } from "../actions";
import { MessageRequestPayload } from "../../interfaces/reducer";

const getAllMessages =
  (payload: MessageRequestPayload) => async (dispatch: AppDispatch) => {
    try {
      const {
        data: { data },
      } = await apiAxiosInstance.get(
        `/chat/conversations?page=${payload?.page || 1}&q=${
          payload?.search || ""
        }`
      );
      dispatch({ type: ACTIONS.SET_ALL_MESSAGES, messages: data });
    } catch (err: any) {
      if (err.response && err.response.data) {
        toast.error(err.response.data.message);
      }
    }
  };

const getMessageOther =
  (userId: Number, page: Number) => async (dispatch: AppDispatch) => {
    try {
      const {
        data: { data },
      } = await apiAxiosInstance.get(
        `/chat/messages?receiver_id=${userId}&page=${page}`
      );
      dispatch({ type: ACTIONS.SET_OTHER_MESSAGES, messages: data });
    } catch (err: any) {
      if (err.response && err.response.data) {
        toast.error(err.response.data.message);
      }
    }
  };

const getSentMessage = () => async (dispatch: AppDispatch) => {
  try {
    const {
      data: { data },
    } = await apiAxiosInstance.get(`/chat/sent-conversations`);
    dispatch({ type: ACTIONS.SET_SENT_MESSAGES, messages: data });
  } catch (err: any) {
    if (err.response && err.response.data) {
      toast.error(err.response.data.message);
    }
  }
};

const grantPhotoAccess = (payload: any) => async (dispatch: AppDispatch) => {
  try {
    const { data } = await apiAxiosInstance.post(
      `/grant-photos-access`,
      payload
    );
    return data;
  } catch (err: any) {
    if (err.response && err.response.data) {
      toast.error(err.response.data.message);
    }
  }
};

const archiveChat = (payload: any) => async (dispatch: AppDispatch) => {
  try {
    const { data } = await apiAxiosInstance.post(`/chat/archive`, payload);
    if (data?.success) toast.success(data?.message);
  } catch (err: any) {
    if (err.response && err.response.data) {
      toast.error(err.response.data.message);
    }
  }
};

const getArchivedMessage =
  (payload: MessageRequestPayload) => async (dispatch: AppDispatch) => {
    try {
      const {
        data: { data },
      } = await apiAxiosInstance.get(
        `/chat/archived-conversations?page=${payload?.page || 1}&q=${
          payload?.search || ""
        }`
      );
      dispatch({ type: ACTIONS.SET_ARCHIVE_MESSAGES, messages: data });
    } catch (err: any) {
      if (err.response && err.response.data) {
        toast.error(err.response.data.message);
      }
    }
  };

const getFilterMessages =
  (payload: MessageRequestPayload) => async (dispatch: AppDispatch) => {
    try {
      const {
        data: { data },
      } = await apiAxiosInstance.get(
        `/chat/filter-out-conversations?page=${payload?.page || 1}&q=${
          payload?.search || ""
        }`
      );
      dispatch({ type: ACTIONS.SET_FILTER_MESSAGES, messages: data });
    } catch (err: any) {
      if (err.response && err.response.data) {
        toast.error(err.response.data.message);
      }
    }
  };

const addFilterForMessages = (payload: any) => async () => {
  try {
    await apiAxiosInstance.post(`/set-chat-filter-out`, payload);
  } catch (err: any) {
    if (err.response && err.response.data) {
      toast.error(err.response.data.message);
    }
  }
};

const getTemplateMessages = () => async () => {
  try {
    const {
      data: { data },
    } = await apiAxiosInstance.get(`/get-template-message`);
    return data;
  } catch (err: any) {
    if (err.response && err.response.data) {
      toast.error(err.response.data.message);
    }
  }
};

export {
  getAllMessages,
  getMessageOther,
  getSentMessage,
  getArchivedMessage,
  getTemplateMessages,
  getFilterMessages,
  addFilterForMessages,
  grantPhotoAccess,
  archiveChat,
};
