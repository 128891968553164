/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { RootReducer, SignupValues } from "../../../../../interfaces/reducer";
import { AuthAction } from "../../../../../redux/thunk";
import { AppDispatch } from "../../../../../redux/store";
import { connect, useSelector } from "react-redux";
import { ACCESS_TOKEN } from "../../../../../utils/constants";
import ROUTES from "../../../../../constants/routes";
import { useNavigate } from "react-router-dom";
import Loader from "../../../../Loader/Spinner";

interface Props extends DispatchToProps {}

const TermCondition = (props: Props) => {
  const navigate = useNavigate();
  const { signupValues } = useSelector((state: RootReducer) => state.auth);
  const { googleSignUp, googleSignupPayload } = useSelector(
    (state: RootReducer) => state.auth
  );
  const [checkboxes, setCheckboxes] = useState({
    checkbox1: false,
    checkbox2: false,
    checkbox3: false,
  });
  const [loader, setLoader] = useState(false);
  const [done, setDone] = useState(false);

  const allChecked =
    checkboxes.checkbox1 && checkboxes.checkbox2 && checkboxes.checkbox3;

  useEffect(() => {
    if (done) {
      if (!localStorage.getItem(ACCESS_TOKEN)) handleSignUp();
      else navigate(ROUTES.MEMBERS);
    }
  }, [signupValues, done]);

  const handleSignUp = async () => {
    if (signupValues && signupValues.heading && signupValues.heading !== "") {
      const newPay = { ...signupValues };
      delete newPay["prefilledEmail"];
      setLoader(true);
      if (googleSignUp) {
        await props.signupGoogle(signupValues, googleSignupPayload, navigate);
      } else {
        await props.signup(newPay);
      }
      setLoader(false);
    }
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setCheckboxes((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  return (
    <div className="modal-body px-0 pb-0">
      {loader && <Loader />}
      <div className="terms_sec">
        <h4>Terms & Conditions</h4>

        <h5> Thank you for being part of the Neu Daddy community</h5>
        <h3>
          As a successful member,please keep the following guidelines in mind:
        </h3>
        <p>
          Note:Please read each section,check each box,and click "I Agree"
          before messaging other members. You will only see this agreement once.
        </p>
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            name="checkbox1"
            checked={checkboxes.checkbox1}
            onChange={handleCheckboxChange}
            id="checkbox1"
          />
          <label className="form-check-label care_text" htmlFor="checkbox1">
          <h6>Be Respectful</h6>
            <p>We hold our community to high standards and reserve the right to
            remove rude members.</p>
          </label>
          
        </div>
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            name="checkbox2"
            checked={checkboxes.checkbox2}
            onChange={handleCheckboxChange}
            id="checkbox2"
          />
          <label className="form-check-label care_text" htmlFor="checkbox2">
            <h6>No pay per meet
              </h6>
              <p>
            This platform is for looking like minded friends and not for
            prostitution.
          </p>
          </label>
         
        </div>
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            name="checkbox3"
            checked={checkboxes.checkbox3}
            onChange={handleCheckboxChange}
            id="checkbox3"
          />
          <label className="form-check-label" htmlFor="checkbox3">
           <p>I agree with terms & conditions</p> 
          </label>
        </div>
        <button
          className="btn btn-public w-100 mt-3 "
          disabled={!allChecked}
          onClick={() => setDone(true)}
        >
          Continue
        </button>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  signup: (payload: SignupValues) => dispatch(AuthAction.signup(payload)),
  signupGoogle: (
    payload: SignupValues,
    googlePayload: any,
    navigate: (path: string) => void
  ) => dispatch(AuthAction.signupWithGoogle(payload, googlePayload, navigate)),
});

interface DispatchToProps {
  signup: (payload: SignupValues) => void;
  signupGoogle: (
    payload: SignupValues,
    googlePayload: any,
    navigate: (path: string) => void
  ) => void;
}

export default connect(null, mapDispatchToProps)(TermCondition);
