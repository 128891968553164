/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import {
  buySubscription,
  confirmSubscription,
} from "../../redux/thunk/subscription";
import { toast } from "react-toastify";
import Loader from "../../components/Loader/Spinner";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const CheckoutForm = ({ item, userData }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [isBusy, setIsBusy] = useState(false);
  const [details, setDetails] = useState();
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    getDetails();
  }, []);

  const getDetails = async () => {
    setLoader(true);
    const response = await buySubscription({
      amount: item?.price,
      subscription_id: item.id,
      subscription_type_id: item.type,
    });
    setLoader(false);
    if (response?.data) {
      setDetails(response?.data);
    }
  };

  useEffect(() => {
    const initializePaymentSheet = async () => {
      setIsBusy(true);
      setIsBusy(false);
    };
    initializePaymentSheet();
  }, []);

  const handleSubmit = async (event) => {
    setLoader(true);
    event.preventDefault();
    setProcessing(true);
    if (!stripe || !elements) {
      setError("Stripe.js has not loaded yet.");
      setProcessing(false);
      setLoader(false);
      return;
    }
    const cardElement = elements.getElement(CardElement);
    if (!cardElement) {
      setError("Card Element is not available.");
      setProcessing(false);
      setLoader(false);
      return;
    }
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
      billing_details: {
        name: userData?.user_name || "",
        email: userData?.email || "",
        address: {
          country: "IN",
        },
      },
    });
    if (error) {
      setError(error.message);
      setProcessing(false);
      setLoader(false);
      return;
    }
    const { error: confirmError, paymentIntent } =
      await stripe.confirmCardPayment(details.client_secret, {
        payment_method: paymentMethod.id,
      });
    if (confirmError) {
      setError(confirmError.message);
      setLoader(false);
      setProcessing(false);
    } else {
      if (paymentIntent.status === "succeeded") {
        const response = await confirmSubscription({
          payment_intent_id: details?.id,
          subscription_id: item.id,
          subscription_type_id: item.type,
        });
        if (response?.data) {
          toast.success("Payment Successful");
        }
        setLoader(false);
        setProcessing(false);
      } else {
        setError("Payment failed.");
        setLoader(false);
        setProcessing(false);
      }
      window.location.reload();
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      {loader && <Loader />}
      <div className="product-info">
        <h6 className="product-title text-uppercase time-holder">
          {item?.title}
        </h6>
        <h6 className="product-price price-place">
          ₹ <span>{item?.price}</span>
        </h6>
      </div>
      <CardElement options={{ hidePostalCode: true }} />
      <button
        type="submit"
        disabled={!stripe || processing || isBusy}
        className="btn btn-verify mt-4"
        style={{ height: "35px", padding: "5px 25px" }}
      >
        {isBusy ? "Initializing..." : "Pay"}
      </button>
      {error && <div className="error">{error}</div>}
    </form>
  );
};

// 4000 0035 6000 0008
const InjectedCheckoutForm = ({ selectedItem, userData, closeModal }) => {
  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm
        item={selectedItem}
        userData={userData}
        onClose={closeModal}
      />
    </Elements>
  );
};

export default InjectedCheckoutForm;
