import { Constants } from "../../utils/constants";

export interface items {
  name: string;
}
export const SidebarItems: items[] = [
  {
    name: Constants.privacyPolicy,
  },
  {
    name: Constants.termsAndCondition,
  },
  {
    name: Constants.contactUs,
  },
  {
    name: Constants.seeHiddenUsers,
  },
  {
    name: Constants.seeBlockUsers,
  },
];
