import { ChangeEvent, Fragment } from 'react';
import styles from './style.module.css';

interface Props {
    type: 'text' | 'password' | 'email' | 'number' | 'date';
    name: string;
    error?: string;
    id?: string
    value: string;
    handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
    placeHolder: string;
    max?: string;
    min?: string;
    className?: string;
    readOnly?:boolean
};

const input = (props: Props) => {
    return (
        <Fragment>
            <input
            id={props.id}
            readOnly={props.readOnly}
            disabled={props.readOnly}
            
                type={props.type}
                name={props.name}
                className={`${styles.input} ${props.className}`}
                onChange={props.handleChange}
                placeholder={props.placeHolder}
                value={props.value}
                max={props.max}
                min={props.min}
            />
            {props.error && props.error.length ? <div className={styles.error}>{props.error}</div> : null}
        </Fragment>

    );
};

export default input;