import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { MyComputer } from "../../../../assets/images";
import Input from "../../../Input";
import "../style.css";
import { SignUpUsernameSchema } from "../../../../schemas";
import { ACTIONS } from "../../../../redux/actions";
import {
  SIGNUP_STEPS,
  GENERAL_DETAILS_STEP,
} from "../../../../utils/constants";
import { useRef, useState } from "react";
import { RootReducer } from "../../../../interfaces/reducer";
import UploadModal from "../../Upload";

const ChooseUsername = () => {
  const dispatch = useDispatch();
  const { displayName } = useSelector(
    (state: RootReducer) => state.auth.signupValues
  );
  const fileUploadRef = useRef<any>(null);
  const [skipProfilePhoto, setProfilePhoto] = useState<boolean>(true);
  const [usernameError, setUsernameError] = useState<boolean>(false);
  const [showUploadModal, setShowUploadModal] = useState<boolean>(false);
  const [imageType, setImageType] = useState("public");

  const usernameFormik = useFormik({
    initialValues: { username: displayName, fromAge: 18, toAge: 60 },
    validationSchema: SignUpUsernameSchema,
    onSubmit: (values) => {
      dispatch({
        type: ACTIONS.SET_SKIPPABLE_PROFILE,
        skipProfile: skipProfilePhoto,
      });
      dispatch({
        type: ACTIONS.SET_SIGNUP_VALUES,
        values: {
          displayName: values.username,
          fromAge: values.fromAge,
          toAge: values.toAge,
        },
      });

      if (skipProfilePhoto) {
        dispatch({
          type: ACTIONS.SET_SIGNUP_STEP,
          step: SIGNUP_STEPS.CROP_PROFILE_PHOTO,
        });
      } else {
        dispatch({
          type: ACTIONS.SET_SIGNUP_STEP,
          step: SIGNUP_STEPS.GALLERY_PHOTOS,
        });
        // dispatch({
        //   type: ACTIONS.SET_SIGNUP_STEP,
        //   step: SIGNUP_STEPS.PREVIEW_CROPPED_PROFILE_PHOTO,
        //   generalStep: GENERAL_DETAILS_STEP.ADD_LOCATION,
        // });
      }
    },
  });

  const insertImage = () => {
    setShowUploadModal(true);
  };

  const handleImageChange = (event: any) => {
    const { files } = event.target;

    const values = {
      image: files[0],
      is_profile_image_public: imageType,
      displayName: usernameFormik.values.username,
      fromAge: usernameFormik.values.fromAge,
      toAge: usernameFormik.values.toAge,
    };

    if (values.displayName !== "" && values.image) {
      dispatch({ type: ACTIONS.SET_SIGNUP_VALUES, values });
      dispatch({
        type: ACTIONS.SET_SIGNUP_STEP,
        step: SIGNUP_STEPS.CROP_PROFILE_PHOTO,
      });
      event.target.value = null;
    } else if (values.displayName === "") {
      setUsernameError(true);
      usernameFormik.setErrors({ username: "Required" });
    }
  };

  const handleOptionSelect = (option: "public" | "private") => {
    setImageType(option);
    setShowUploadModal(false);
    fileUploadRef.current.click(); // Trigger the file upload dialog
  };

  return (
    <div className="modal-body px-0 pb-0">
      <div className="form-sec">
        <form onSubmit={usernameFormik.handleSubmit}>
          <div className="row">
            <div className="Choose_Username about_useredit login-form">
              <div className="row">
                <div className="col-md-12">
                  <div className="mb-3">
                    <label className="mb-1">Display Name</label>
                    <div className="suggest-btn">
                      <Input
                        type="text"
                        name="username"
                        placeHolder="Enter your name"
                        handleChange={usernameFormik.handleChange}
                        value={usernameFormik.values.username}
                        error={
                          (usernameError || usernameFormik.touched.username) &&
                          usernameFormik.errors.username
                            ? usernameFormik.errors.username
                            : ""
                        }
                      />
                      {/* <button>Suggest</button> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="add-photo">
                <h4 className="mb-3">Add a Photo</h4>
                <div className="row">
                  <div className="col-md-12">
                    <div
                      className="add-media cursor-pointer"
                      onClick={insertImage}
                    >
                      <img className="mci" src={MyComputer} alt="" />
                      {/* <span>My Computer</span> */}
                    </div>
                  </div>
                </div>
                <p>
                  Photos need to be larger than 400px x 400 px and you should be
                  in the photo. Please, no nudity or revealing photos.
                  {/* Read our
                  <a href="#"> help and guidelines.</a> */}
                </p>
                <p
                  className="cont-photo mb-0 cursor-pointer"
                  onClick={() => {
                    setProfilePhoto(false);
                    usernameFormik.submitForm();
                  }}
                >
                  Or continue without a photo
                </p>
                <input
                  ref={fileUploadRef}
                  type="file"
                  className="d-none"
                  accept="image/*"
                  onChange={handleImageChange}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
      {showUploadModal && (
        <UploadModal
          onOptionSelect={handleOptionSelect}
          onClose={() => setShowUploadModal(false)}
        />
      )}
    </div>
  );
};

export default ChooseUsername;
