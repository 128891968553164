const SIGNUP_STEPS = {
  PERSONAL_DETAILS: "PERSONAL_DETAILS",
  VERIFY_OTP: "VERIFY_OTP",
  CHOOSE_USERNAME: "CHOOSE_USERNAME",
  LOCATION_DETAILS: "LOCATION_DETAILS",
  CROP_PROFILE_PHOTO: "CROP_PROFILE_PHOTO",
  PREVIEW_CROPPED_PROFILE_PHOTO: "PREVIEW_CROPPED_PROFILE_PHOTO",
  GALLERY_PHOTOS: "GALLERY_PHOTOS",
};

const GENERAL_DETAILS_STEP = {
  ADD_LOCATION: "ADD_LOCATION",
  ADD_BODY_DETAILS: "ADD_BODY_DETAILS",
  ADD_PROFESSIONAL_DETAILS: "ADD_PROFESSIONAL_DETAILS",
  ADD_SEEKING_DETAILS: "ADD_SEEKING_DETAILS",
  ADD_ME_DETAILS: "ADD_ME_DETAILS",
  VERIFY_TERMS: "VERIFY_TERMS",
  // ADD_VERIFY_EMAIL: "ADD_VERIFY_EMAIL",
};

const ACCESS_TOKEN = "token";

const EDIT_PROFILE_MODAL = {
  EDIT_ABOUT_ME: "EDIT_ABOUT_ME",
  EDIT_OTHER_INFO: "EDIT_OTHER_INFO",
  EDIT_PERSONAL_DETAILS: "EDIT_PERSONAL_DETAILS",
  EDIT_INTERESTS: "EDIT_INTERESTS",
};
const SOCKET = {
  WEBSOCKET: "websocket",
  CONNECT: "connect",
  SENDMESSAGE: "sendMessage",
  TEXT: "text",
};
const MESSAGES = {
  INBOX: "inbox",
  SENT: "sent",
  ARCHIVE: "archive",
  FILTER: "filter",
};

const HEADER = {
  SEARCH: "search",
  INTERESTS: "interests",
  MESSAGES: "messages",
};

const THEME = {
  DARK: "dark",
  LIGHT: "light",
  DARKCSS: "darkbody",
  THEME: "theme",
};

export {
  SIGNUP_STEPS,
  GENERAL_DETAILS_STEP,
  ACCESS_TOKEN,
  EDIT_PROFILE_MODAL,
  SOCKET,
  MESSAGES,
  HEADER,
  THEME,
};

export const Constants: Record<string, any> = {
  activeTab: "activeTab",
  changePassword: "Change Password",
  contactUs: "Contact us",
  deleteAccount: "Delete Account",
  email: "email",
  forgotPasswordNumber: "forgotPasswordNumber",
  limitCount: 10,
  newUser: "New User",
  pleaseEnterCorrectOtp: "Please enter correct OTP",
  privacyPolicy: "Privacy policy",
  seeHiddenUsers: "See Hidden users",
  seeBlockUsers: "See Blocked users",
  termsAndCondition: "Terms & Conditions",
  REVERSE_GEOCODE_API: `https://maps.googleapis.com/maps/api/geocode/json?sensor=true&key=`,
};
